;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"d0f1b8ae5be7de3d8f813a615d2c2e63515e2372"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import {excludeGraphQLFetch} from 'apollo-link-sentry';

const SENTRY_DSN: string | undefined = process.env.SENTRY_DSN ?? process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn: SENTRY_DSN,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.05,
    maxValueLength: 1000,
    beforeBreadcrumb: excludeGraphQLFetch,
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
    ],
    ignoreErrors: ["Can't find variable: fbq", 'fbq is not defined', 'AbortError', 'Cancel rendering route'],

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0.01,

    integrations: [
        Sentry.replayIntegration({
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,
            mask: [
                '.messagePreview',
                '.messages',
                'input[type="password"]',
                '.profileInfo',
                'textarea[name="note"]',
                'table[class^="TransactionsTable"]',
                '.personalDataForm input',
            ],
        }),
        ...(process.env.NEXT_PUBLIC_SENTRY_APP_KEY
            ? [
                  Sentry.thirdPartyErrorFilterIntegration({
                      filterKeys: [process.env.NEXT_PUBLIC_SENTRY_APP_KEY],
                      behaviour: 'apply-tag-if-contains-third-party-frames',
                  }),
              ]
            : []),
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
