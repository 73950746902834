import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconHandshake: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="handshake">
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.05 19.873h-.97c-.264 0-.524.056-.764.166l-3.879 1.766"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m12.975 16.432 1.008.915a1.501 1.501 0 0 1 .005 2.218l-2.137 1.956a1.844 1.844 0 0 1-2.484.005l-1.284-1.165a1.986 1.986 0 0 0-1.135-.492M21.004 14.001h-2.501a1.5 1.5 0 0 0-1.501 1.5v4.002a1.5 1.5 0 0 0 1.5 1.5h2.502"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.996 21.004h2.501a1.5 1.5 0 0 0 1.5-1.5v-4.002A1.5 1.5 0 0 0 5.498 14h-2.5M17.51 14.37l-3.16-1.39a1.844 1.844 0 0 0-1.745.14L10.2 14.679a1.388 1.388 0 0 0 1.341 2.423l1.435-.67"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m6.916 15.009 2.227-.803a1.833 1.833 0 0 1 1.545.155"
            />
        </Icon>
    );
};
