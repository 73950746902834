import {
    AdvertImportType,
    Condition,
    Disposition,
    Equipped,
    EstateType,
    GpsPointInput,
    OfferType,
    Ownership,
    Region,
    ResultOrder,
    Watchdog,
    Currency,
    LandType,
    Construction,
} from '.cache/__types__';
import {ParsedUrlQuery} from 'querystring';
import {LocaleType} from 'src/types/general';
import {getLocalizedRoute, Route} from './routes';
import {listDefaultType as defaultOrder} from '@constants/Order';
import {autocomplete, NominatimOsmId} from '@liveComponents/Autocomplete/autocompleteHelper';
import {CountryEnum, defaultCountryByLocale} from '@constants/Country';
import {DistrictEnum, ProvinceEnum, provincesArray} from '@constants/Provinces';
import {getRegionOsmId} from '@pageComponents/HomePage/reducer';
import {defaultCurrencyByLocale} from '@constants/Currency';
import {ConstructionType} from '@constants/Construction';

export const defaultLimit = 15;
export const defaultPage = 1;

export const asArray = <T extends string>(v: T | T[], separator = ',') => (Array.isArray(v) ? v : (v.split(separator) as T[]));

export const asValue = <T>(v: T | T[]) => (Array.isArray(v) ? v[0] : v);

export const asNumber = <T extends string>(v: T | T[]) => parseInt(asValue(v));
export const asFloat = <T extends string>(v: T | T[]) => parseFloat(asValue(v));
const asJson = (value: string) => {
    try {
        return JSON.parse(value) as GpsPointInput[];
    } catch (e) {
        return null;
    }
};

export enum LocationEnum {
    Exact = 'exact',
    FromMap = 'fromMap',
}

export const parseQuery = (query: ParsedUrlQuery, locale?: LocaleType) => {
    const page = query.page ? asNumber(query.page) : defaultPage;

    return {
        offerType: query.offerType ? asArray(query.offerType as OfferType) : [],
        estateType: query.estateType ? asArray(query.estateType as EstateType) : [],
        disposition: query.disposition ? asArray(query.disposition as Disposition) : [],
        ownership: query.ownership ? asArray(query.ownership as Ownership) : [],
        condition: query.condition ? asArray(query.condition as Condition) : [],
        construction: query.construction ? asArray(query.construction as ConstructionType) : [],
        equipped: query.equipped ? asArray(query.equipped as Equipped) : [],
        priceFrom: query.priceFrom ? asNumber(query.priceFrom) : null,
        priceTo: query.priceTo ? asNumber(query.priceTo) : null,
        balconyFrom: query.balconyFrom ? asNumber(query.balconyFrom) : null,
        balconyTo: query.balconyTo ? asNumber(query.balconyTo) : null,
        loggiaFrom: query.loggiaFrom ? asNumber(query.loggiaFrom) : null,
        loggiaTo: query.loggiaTo ? asNumber(query.loggiaTo) : null,
        terraceFrom: query.terraceFrom ? asNumber(query.terraceFrom) : null,
        terraceTo: query.terraceTo ? asNumber(query.terraceTo) : null,
        cellarFrom: query.cellarFrom ? asNumber(query.cellarFrom) : null,
        cellarTo: query.cellarTo ? asNumber(query.cellarTo) : null,
        frontGardenFrom: query.frontGardenFrom ? asNumber(query.frontGardenFrom) : null,
        frontGardenTo: query.frontGardenTo ? asNumber(query.frontGardenTo) : null,
        parking: query.parking === 'true',
        garage: query.garage === 'true',
        petFriendly: query.petFriendly === 'true',
        barrierFree: query.barrierFree === 'true',
        lift: query.lift === 'true',
        region: query.region ? asValue(query.region) : null,
        advertId: query.advertId ? asValue(query.advertId as string) : null,
        surfaceFrom: query.surfaceFrom ? asNumber(query.surfaceFrom) : null,
        surfaceTo: query.surfaceTo ? asNumber(query.surfaceTo) : null,
        surfaceLandFrom: query.surfaceLandFrom ? asNumber(query.surfaceLandFrom) : null,
        surfaceLandTo: query.surfaceLandTo ? asNumber(query.surfaceLandTo) : null,
        order: query.order ? asValue(query.order as ResultOrder) : defaultOrder,
        regionOsmIds: query.regionOsmIds ? asArray(query.regionOsmIds as NominatimOsmId) : [],
        osm_value: query.osm_value ? asValue(query.osm_value as string) : '',
        roommate: query.roommate ? query.roommate === 'true' : null,
        includeImports: query.includeImports === undefined || query.includeImports === 'true',
        includeShortTerm: query.includeShortTerm === undefined || query.includeShortTerm === 'true',
        watchdog: query.watchdog && !Number.isNaN(parseInt(query.watchdog as string)) ? asValue(query.watchdog as string) : null,
        boundaryPoints: typeof query.boundaryPoints === 'string' ? asJson(query.boundaryPoints) : null,
        searchOnMap: query.searchOnMap === 'true',
        location: query.location ? asValue(query.location as LocationEnum) : LocationEnum.FromMap,
        country: query.country ? asValue(query.country as CountryEnum) : locale ? defaultCountryByLocale[locale] : CountryEnum.CR,
        provinces: query.provinces ? asArray(query.provinces as ProvinceEnum) : [],
        districts: query.districts ? asArray(query.districts as DistrictEnum) : [],
        discountedOnly: query.discountedOnly === 'true',
        polygonBuffer: query.polygonBuffer ? asNumber(query.polygonBuffer) : null,
        lng: query.lng ? asFloat(query.lng) : null,
        lat: query.lat ? asFloat(query.lat) : null,
        zoom: query.zoom ? asFloat(query.zoom) : null,
        hideResults: query.hideResults === 'true',
        availableFrom: query.availableFrom ? asNumber(query.availableFrom) : null,
        importType: query.importType ? asValue(query.importType as AdvertImportType) : null,
        currency: query.currency ? asValue(query.currency as Currency) : locale ? defaultCurrencyByLocale[locale] : null,
        landType: query.landType ? asArray(query.landType as LandType) : [],
        searchPriceWithCharges: query.searchPriceWithCharges === 'true',
        lowEnergy: query.lowEnergy === 'true',

        offset: getOffset(defaultLimit, page),
        limit: defaultLimit,
        page: query.page ? asNumber(query.page) : defaultPage,
    };
};

export type Filter = Partial<ReturnType<typeof parseQuery>>;

export type ListFilter = Pick<
    Filter,
    'offerType' | 'estateType' | 'region' | 'disposition' | 'page' | 'lng' | 'lat' | 'zoom' | 'hideResults'
>;

export const isListFilter = (filter?: Filter): filter is ListFilter => {
    const allowedKeys = ['offerType', 'estateType', 'region', 'disposition', 'page', 'lng', 'lat', 'zoom', 'hideResults'];

    if (!filter) {
        return false;
    }

    return !Object.entries(reduceUrlFilter(filter)).some(([key]) => !allowedKeys.includes(key));
};

export const parsePage = (page: string | string[] | undefined): number => {
    return page ? (Array.isArray(page) ? parseInt(page[0]) : parseInt(page)) : 1;
};

export const getOffset = (limit: number, page: number): number => {
    return page < 1 ? limit : (page - 1) * limit;
};

const reduceFilter = (filter: Filter) => {
    const reducedFilter: Filter = {...filter};
    for (const key in filter) {
        switch (key) {
            case 'location':
                if (filter[key] === LocationEnum.FromMap) {
                    delete reducedFilter.regionOsmIds;
                    delete reducedFilter.osm_value;
                    delete reducedFilter.region;

                    const provinces = provincesArray
                        .filter((province) => province.country === filter.country)
                        .filter((province) => filter.provinces?.includes(province.id));

                    reducedFilter.provinces = provinces.map((province) => province.id);

                    const districts = provinces.map((province) => province.districts.map((district) => district.id)).flat();
                    reducedFilter.districts = filter.districts?.filter((district) => districts.includes(district));

                    // only LocationEnum.FromMap can occur
                    const regionOsmIds: NominatimOsmId[] = [];

                    for (const fDistrict of reducedFilter.districts ?? []) {
                        const fProvince = provincesArray.find((province) => {
                            return province.districts.find((district) => {
                                if (district.id === fDistrict) {
                                    regionOsmIds.push(district.osmId);
                                    return true;
                                }
                                return false;
                            });
                        });

                        const index = provinces.findIndex((province) => province.id === fProvince?.id) ?? -1;
                        if (index > -1) {
                            provinces.splice(index, 1);
                        }
                    }

                    for (const fProvince of provinces) {
                        const province = provincesArray.find((province) => fProvince.id === province.id);
                        if (province) {
                            regionOsmIds.push(province.osmId);
                        }
                    }
                    reducedFilter.regionOsmIds = regionOsmIds;

                    if (!reducedFilter.provinces.length) {
                        delete reducedFilter.provinces;
                    }

                    if (!reducedFilter.districts?.length) {
                        delete reducedFilter.districts;
                    }
                } else {
                    delete reducedFilter.country;
                    delete reducedFilter.provinces;
                    delete reducedFilter.districts;
                }
                break;

            case 'estateType':
                const withoutUndefined = filter[key]?.filter((row) => row !== EstateType.Undefined);
                if (!withoutUndefined?.length) {
                    delete reducedFilter[key];
                } else {
                    reducedFilter[key] = withoutUndefined;
                }
                break;
            case 'offerType':
            case 'disposition':
            case 'landType':
            case 'ownership':
            case 'condition':
            case 'construction':
            case 'equipped':
            case 'provinces':
            case 'districts':
            case 'regionOsmIds':
                if (!filter[key]?.length) {
                    delete reducedFilter[key];
                }
                break;
            case 'advertId':
                if (filter[key]) {
                    return {
                        [key]: filter[key],
                    };
                }
            case 'availableFrom':
            case 'surfaceFrom':
            case 'surfaceTo':
            case 'surfaceLandFrom':
            case 'surfaceLandTo':
            case 'priceFrom':
            case 'priceTo':
            case 'region':
            case 'balconyFrom':
            case 'balconyTo':
            case 'loggiaFrom':
            case 'loggiaTo':
            case 'terraceFrom':
            case 'terraceTo':
            case 'cellarFrom':
            case 'cellarTo':
            case 'frontGardenFrom':
            case 'frontGardenTo':
            case 'parking':
            case 'garage':
            case 'petFriendly':
            case 'lowEnergy':
            case 'barrierFree':
            case 'lift':
            case 'order':
            case 'osm_value':
            case 'discountedOnly':
            case 'searchPriceWithCharges':
            case 'polygonBuffer':
            case 'lng':
            case 'lat':
            case 'zoom':
            case 'hideResults':
                if (!filter[key]) {
                    delete reducedFilter[key];
                }
                break;
            case 'importType': {
                //@ts-expect-error: TS1234 Prostě lahůdka
                if (!Object.values(AdvertImportType).includes(filter[key])) {
                    delete reducedFilter[key];
                }
            }
            case 'roommate':
            case 'currency':
            case 'boundaryPoints':
                if (filter[key] === null) {
                    delete reducedFilter[key];
                }
                break;
            case 'includeImports':
            case 'includeShortTerm':
                if (filter[key] || filter[key] === null) {
                    delete reducedFilter[key];
                }
                break;
            case 'searchOnMap':
                if (filter[key] === false) {
                    delete reducedFilter[key];
                }
                break;
            case 'watchdog':
                if (!filter[key]) {
                    delete reducedFilter[key];
                }
                break;

            default:
                //nothing
                break;
        }
    }

    return reducedFilter;
};

export const reduceUrlFilter = (filter: Filter) => {
    const reducedFilter: Record<string, any> = {...reduceFilter(filter)};

    for (const key in filter) {
        switch (key) {
            case 'boundaryPoints':
                if (Array.isArray(reducedFilter[key])) {
                    reducedFilter[key] = JSON.stringify(reducedFilter[key]);
                }
                break;
            case 'page':
                if (!reducedFilter[key] || reducedFilter[key] === 1) {
                    delete reducedFilter[key];
                }
                break;
            case 'offset':
            case 'limit':
                delete reducedFilter[key];
                break;
            case 'order':
                if (filter[key] === ResultOrder.TimeorderDesc) {
                    delete reducedFilter[key];
                }
                break;
            default:
                //nothing
                break;
        }
    }

    return reducedFilter;
};

export const reduceFilterDB = (filter: Filter) => {
    const reducedFilter: Omit<Filter, 'construction'> & {construction?: Construction[]} = {
        ...reduceFilter(filter),
        construction: filter.construction as unknown as Construction[] | undefined,
    };

    for (const key in filter) {
        switch (key) {
            case 'location': {
                break;
            }
            case 'page':
            case 'osm_value':
                delete reducedFilter[key];
                break;

            default:
                //nothing
                break;
        }
    }

    return reducedFilter;
};

export const getReducedUrlFilter = (filter: Filter) => (key: string, value?: string) => {
    const reducedFilter: Filter = {...reduceUrlFilter(filter), page: 1};
    switch (key) {
        case 'offerType':
        case 'estateType':
        case 'advertId':
        case 'surfaceFrom':
        case 'surfaceTo':
        case 'surfaceLandFrom':
        case 'surfaceLandTo':
        case 'priceFrom':
        case 'priceTo':
        case 'region':
        case 'balconyFrom':
        case 'balconyTo':
        case 'loggiaFrom':
        case 'loggiaTo':
        case 'terraceFrom':
        case 'terraceTo':
        case 'cellarFrom':
        case 'cellarTo':
        case 'frontGardenFrom':
        case 'frontGardenTo':
        case 'parking':
        case 'garage':
        case 'petFriendly':
        case 'lowEnergy':
        case 'barrierFree':
        case 'lift':
        case 'order':
        case 'regionOsmIds':
        case 'osm_value':
        case 'currency':
            delete reducedFilter[key];
            break;
        case 'disposition':
        case 'landType':
        case 'ownership':
        case 'condition':
        case 'construction':
        case 'equipped':
            const index = reducedFilter[key]?.findIndex((row) => row === value);
            if (index !== undefined && index !== -1) {
                reducedFilter[key]?.splice(index, 1);
            }
    }

    return reducedFilter;
};

export const getSearchURL = (route: Route, locale: LocaleType) => (filter: Filter, hash?: string) =>
    getLocalizedRoute(route, locale, reduceUrlFilter(filter), hash);

export const getWatchdogFilter = (watchdog: Pick<Watchdog, 'criteria'>): Filter => {
    return {
        offerType: watchdog.criteria?.offerType ? [watchdog.criteria.offerType as OfferType] : [],
        estateType: watchdog.criteria?.estateType ? (watchdog.criteria.estateType as EstateType[]) : [],
        disposition: watchdog.criteria?.disposition ? (watchdog.criteria.disposition as Disposition[]) : [],
        priceFrom: watchdog.criteria?.priceMin!,
        priceTo: watchdog.criteria?.priceMax!,
        surfaceFrom: watchdog.criteria?.surfaceMin!,
        surfaceTo: watchdog.criteria?.surfaceMax!,
        surfaceLandFrom: watchdog.criteria?.surfaceLandMin!,
        surfaceLandTo: watchdog.criteria?.surfaceLandMax!,
        balconyFrom: watchdog.criteria?.balconySurfaceMin!,
        balconyTo: watchdog.criteria?.balconySurfaceMax!,
        terraceFrom: watchdog.criteria?.terraceSurfaceMin!,
        terraceTo: watchdog.criteria?.terraceSurfaceMax!,
        cellarFrom: watchdog.criteria?.cellarSurfaceMin!,
        cellarTo: watchdog.criteria?.cellarSurfaceMax!,
        loggiaFrom: watchdog.criteria?.loggiaSurfaceMin!,
        loggiaTo: watchdog.criteria?.loggiaSurfaceMax!,
        frontGardenFrom: watchdog.criteria?.frontGardenSurfaceMin!,
        frontGardenTo: watchdog.criteria?.frontGardenSurfaceMax!,
        parking: watchdog.criteria?.parking!,
        garage: watchdog.criteria?.garage!,
        lift: watchdog.criteria?.lift!,
        roommate: watchdog.criteria?.roommate!,
        includeImports: watchdog.criteria?.includeImports!,
        includeShortTerm: watchdog.criteria?.includeShortTerm!,
        equipped: watchdog.criteria?.equipped ? (watchdog.criteria.equipped as Equipped[]) : [],
        construction: watchdog.criteria?.construction ? (watchdog.criteria.construction as unknown as ConstructionType[]) : [],
        ownership: watchdog.criteria?.ownership ? (watchdog.criteria.ownership as Ownership[]) : [],
        osm_value: watchdog.criteria?.selectedAddress!,
        petFriendly: watchdog.criteria?.petFriendly!,
        lowEnergy: watchdog.criteria?.lowEnergy!,
        barrierFree: watchdog.criteria?.barrierFree!,
        currency: watchdog.criteria?.currency!,
        polygonBuffer: watchdog.criteria?.polygonBuffer!,
    };
};

export const getWatchdogRegionOsmIds = async (osm_value?: string) => {
    if (osm_value) {
        const regionOsmIds = await autocomplete(process.env.NEXT_PUBLIC_AUTOCOMPLETE_URI ?? '')(osm_value).then((result) => {
            if (result.features.at(0)) {
                const properties = result.features.at(0)?.properties;
                if (properties?.osm_type && properties.osm_id) {
                    return getRegionOsmId(properties.osm_type, properties.osm_id);
                }

                return null;
            }
        });

        if (regionOsmIds) {
            return [regionOsmIds];
        }
    }
};

export const getGeoLayerData = (regions: Region[]) => {
    const region = regions.at(-1);

    if (region) {
        switch (region.lvl) {
            // kraj
            case 2:
                return {
                    geo1: region.name,
                    geo2: null,
                    geo3: null,
                    geo4: 'Kraj',
                };

            // okres
            case 3:
                return {
                    geo1: null,
                    geo2: region.name,
                    geo3: null,
                    geo4: 'Okres',
                };

            default:
                return {
                    geo1: null,
                    geo2: null,
                    geo3: region.name,
                    geo4: 'Město',
                };
        }
    }

    return {};
};

export const saveFilter = (filter: Filter) => {
    const filterToSave = {...filter};

    delete filterToSave.page;
    delete filterToSave.offset;
    delete filterToSave.limit;

    localStorage.setItem('lastSearch', JSON.stringify(reduceFilter(filterToSave)));
};

export const loadFilter = (): Filter => {
    if (typeof window !== 'undefined') {
        const filter = localStorage.getItem('lastSearch');

        if (filter) {
            const parsedFilter = JSON.parse(filter) as Filter;
            return parsedFilter;
        }
    }

    return {};
};
