import NextImage from 'next/image';
import styles from './Logos.module.scss';

import bezrealitky from 'images/ehs/bzr-2.svg';
import bezrealitkysk from 'images/ehs/bzr-sk.svg';
// import cnt from 'images/ehs/cnt.svg';
import nemoreport from 'images/ehs/nemoreport.svg';
import ownest from 'images/ehs/ownest.svg';
// import roomStory from 'images/ehs/room-story.png';
import ts from 'images/ehs/ts.svg';
import ohneMaklerDe from 'images/ehs/ohne-makler-de.svg';
import ohneMaklerAt from 'images/ehs/ohne-makler-at.svg';
import {FormattedMessage} from 'react-intl';
import {Typography} from '@components/Typography';

export const Logos = () => (
    <ul className={styles.logos}>
        <li>
            <Typography>
                <FormattedMessage defaultMessage="Člen realitní skupiny European Housing Services" />
            </Typography>
        </li>
        <li>
            <a href="https://www.bezrealitky.cz" title="Bezrealitky.cz">
                <NextImage
                    alt="Logo Bezrealitky"
                    src={bezrealitky}
                    width={120}
                    height={63}
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </a>
        </li>
        <li>
            <a href="https://www.bezrealitky.sk" title="Bezrealitky.sk">
                <NextImage
                    alt="Logo Bezrealitky"
                    src={bezrealitkysk}
                    width={140}
                    height={73}
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </a>
        </li>
        <li>
            <a href="https://www.ohne-makler.net" title="ohne Makler">
                <NextImage
                    alt="Ohne Makler DE"
                    src={ohneMaklerDe}
                    width={120}
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </a>
        </li>
        <li>
            <a href="https://www.ohne-makler.at" title="ohne Makler">
                <NextImage
                    alt="Ohne Makler AT"
                    src={ohneMaklerAt}
                    width={135}
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </a>
        </li>
        {/* <li>
            <a href="https://www.cntrust.cz" title="Český nemovitostní trust">
                <NextImage
                    alt="Logo ČNT"
                    src={cnt}
                    width={96}
                    height={50}
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </a>
        </li> */}
        <li>
            <a href="https://www.nemoreport.cz" title="Nemo report">
                <NextImage
                    alt="Logo NEMO report"
                    src={nemoreport}
                    width={150}
                    height={50}
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </a>
        </li>
        <li>
            <a href="https://www.ownest.cz" title="Ownest">
                <NextImage
                    alt="Logo Ownest"
                    src={ownest}
                    width={70}
                    height={50}
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </a>
        </li>
        {/* <li>
            <a href="https://roomstory.cz" title="Room Story">
                <NextImage
                    alt="Logo Roomstory"
                    src={roomStory}
                    width={70}
                    height={50}
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </a>
        </li> */}
        <li>
            <a href="https://tvujspravce.cz" title="Tvůj Správce">
                <NextImage
                    alt="Logo Tvůj Správce"
                    src={ts}
                    width={96}
                    height={50}
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </a>
        </li>
    </ul>
);
