import classNames from 'classnames';
import React, {FC} from 'react';
import {Box} from '../Box';
import {Icon, IconProps} from '../Icon';
import {Image} from '../Image';
import styles from './Avatar.module.scss';

type AvatarProps = {
    size?: 'xxs' | 'xs' | 'sm' | 'smd' | 'md' | 'lmd' | 'lg' | 'xl';
    iconName?: IconProps['name'];
    circle?: boolean;
    className?: string;
    onClick?: () => void;
    iconWrapperClassName?: string;
};

export const SIZES = {
    xxs: {
        width: 25,
        height: 25,
        avatarSize: 'xs',
        iconSize: 'sm',
    },
    xs: {
        width: 28,
        height: 28,
        avatarSize: 'xs',
        iconSize: 'sm',
    },
    sm: {
        width: 40,
        height: 40,
        avatarSize: 'sm',
        iconSize: 'sm',
    },
    smd: {
        width: 48,
        height: 48,
        avatarSize: 'smd',
        iconSize: undefined,
    },
    md: {
        width: 52,
        height: 52,
        avatarSize: 'md',
        iconSize: undefined,
    },
    lmd: {
        width: 80,
        height: 80,
        avatarSize: 'lmd',
        iconSize: undefined,
    },
    lg: {
        width: 120,
        height: 120,
        avatarSize: 'lg',
        iconSize: 'xxl',
    },
    xl: {
        width: 180,
        height: 180,
        avatarSize: 'xl',
        iconSize: 'xxxl',
    },
};

export const Avatar: FC<React.PropsWithChildren<AvatarProps>> = ({
    size = 'md',
    iconName,
    circle,
    className,
    children,
    onClick,
    iconWrapperClassName,
}) => {
    const {width, height, avatarSize, iconSize} = SIZES[size];

    return (
        <span
            className={classNames(styles.avatar, 'avatar overflow-hidden', className, {
                [styles[`avatar--${avatarSize}`]]: avatarSize,
                [styles[`avatar--circle`]]: circle,
                [styles[`avatar--cursor`]]: onClick,
            })}
            onClick={onClick}
        >
            {iconName ? (
                <Box
                    as="span"
                    className={classNames(iconWrapperClassName, 'd-flex justify-content-center align-items-center')}
                    style={{width, height}}
                >
                    <Icon name={iconName} size={iconSize as IconProps['size']} />
                </Box>
            ) : (
                <Image>{children}</Image>
            )}
        </span>
    );
};
