import {ReactNode, useCallback, useEffect, useMemo, useState} from 'react';
import {Typography} from '@components/Typography';
import {Box} from '@components/Box';
import {TabsNav} from '@components/TabsNav';
import {Form} from '@components/Form';
import {InfoText} from '@components/InfoText';
import {FormattedMessage, useIntl} from 'react-intl';
import {Formik, FormikHelpers} from 'formik';
import {Input} from '@liveComponents/Field';
import * as Yup from 'yup';
import {PhoneInput} from '../Field/PhoneInput';
import {PasswordInput} from '@liveComponents/Field/PasswordInput';
import {Col} from '@components/Col';
import {Row} from '@components/Row';
import {ToggleButton} from '@components/ToggleButton';
import {translations} from '@constants/UserGroupKeyType';
import {entries, LocaleEnum} from 'src/types/general';
import {SelectInput} from '@liveComponents/Field/SelectInput';
import {useRegisterMutation} from '@gql/mutation/user/register.gql';
import {RegistrationType, PageInfoType, UserGroupKeyType} from '.cache/__types__';
import {getGraphQlLocale} from 'src/types/graphql/general';
import {useLocale} from '@utils/locale';
import Link from 'next/link';
import {getLocalizedRoute, routes} from '@utils/routes';
import {usePageInfoQuery} from '@gql/query/page/info.gql';
import {useToaster} from '@utils/toast';
import {CheckboxInput} from '@liveComponents/Field/CheckboxInput';
import {InfoTooltip} from '@components/InfoTooltip';
import {useMQ} from '@hooks/useMQ';
import {SubmitButton} from '@liveComponents/Field/SubmitButton';
import {LoginRegisterToggle, LoginRegisterToggleEnum} from './LoginRegisterToggle';
import {UspBox} from '@pageComponents/Detail/UspBox';
import {dataLayerPush} from '@utils/gtm';
import {TouchPointEnum, getTouchPoint} from './utils';
import {signIn} from 'next-auth/react';
import {loadEhubVisitId, removeEhubVisitId} from '@utils/ehub';

export enum RegistrationTypeEnum {
    Owner = 'Majitel',
    Customer = 'Zájemce',
}
import {ThirdPartyLogin} from './ThirdPartyLogin';

export type RegistrationFormProps = {
    id: TouchPointEnum;
    onSuccess?: () => void;
    onLogin?: () => void;
    googleIdToken?: string;
    showInsertTips?: boolean;
    lastUrl?: string;
    firstname?: string;
    lastname?: string;
    email?: string;
};

type FormValues = {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    password: string;
    identificationNumber?: number;
    vatNumber?: string;
    companyName?: string;
    companyType?: UserGroupKeyType;
    companyStreet?: string;
    companyStreetNumber?: string;
    companyCity?: string;
    companyZip?: string;
    terms: boolean;
    newsletter: boolean;
};

export const RegistrationForm = ({
    id,
    onSuccess,
    onLogin,
    googleIdToken,
    showInsertTips,
    lastUrl,
    firstname,
    lastname,
    email,
}: RegistrationFormProps) => {
    const intl = useIntl();
    const toaster = useToaster();
    const isMdDown = useMQ('mdDown');
    // const [isOpened, toggleOpened] = useToggle(true);
    const [isCompany, setCompany] = useState<boolean>(false);
    const [isOwner, setOwner] = useState<boolean>(false);
    const locale = useLocale();

    useEffect(() => {
        dataLayerPush({
            event: 'registrace_zobrazeni_formulare',
            touchpoint: getTouchPoint(id),
        });
    }, [id]);

    const [register] = useRegisterMutation();
    const {data: termsData} = usePageInfoQuery({
        variables: {
            locale: getGraphQlLocale(locale),
            type: PageInfoType.TermsAndConditions,
        },
    });
    const {data: privacyPolicyData} = usePageInfoQuery({
        variables: {
            locale: getGraphQlLocale(locale),
            type: PageInfoType.PrivacyPolicy,
        },
    });

    const processSubmit = useCallback(
        (values: FormValues, actions: FormikHelpers<FormValues>) => {
            const ehub = loadEhubVisitId();
            register({
                variables: {
                    registration: {
                        type: isCompany ? RegistrationType.Legal : RegistrationType.Private,
                        isOwner,
                        broker: true,
                        conditions: values.terms,
                        newsletter: values.newsletter,
                        firstname: values.firstname,
                        lastname: values.lastname,
                        email: values.email,
                        phone: values.phone,
                        password: values.password,
                        companyIc: isCompany ? values.identificationNumber : undefined,
                        companyDic: isCompany ? values.vatNumber : undefined,
                        companyName: isCompany ? values.companyName : undefined,
                        companyType: isCompany ? values.companyType : undefined,
                        street: isCompany ? values.companyStreet : undefined,
                        houseNumber: isCompany ? values.companyStreetNumber : undefined,
                        city: isCompany ? values.companyCity : undefined,
                        zip: isCompany ? values.companyZip : undefined,
                        locale: getGraphQlLocale(locale),
                        googleIdToken,
                        ehub,
                    },
                },
            })
                .then(({data}) => {
                    if (!localStorage.getItem('lastUrl')) {
                        localStorage.setItem('lastUrl', lastUrl ?? window.location.href);
                    }

                    if (data?.registration?.status) {
                        if (googleIdToken) {
                            localStorage.removeItem('lastUrl');
                            dataLayerPush({
                                event: 'registrace_google',
                                touchpoint: getTouchPoint(id),
                                subjekt: isCompany ? 'Právnická osoba' : 'Fyzická osoba',
                                typ_registrace: isOwner ? 'Majitel' : 'Zájemce',
                            });
                            void signIn('google', {
                                callbackUrl: getLocalizedRoute(routes.onBoarding, locale),
                            });
                        } else {
                            dataLayerPush({
                                event: 'registrace_neoverena',
                                email: values.email,
                                touchpoint: getTouchPoint(id),
                                subjekt: isCompany ? 'Právnická osoba' : 'Fyzická osoba',
                                typ_registrace: isOwner ? RegistrationTypeEnum.Owner : RegistrationTypeEnum.Customer,
                            });
                            removeEhubVisitId();
                            onSuccess?.();
                        }
                    } else if (data?.registration?.errors && data.registration.errors.length > 0) {
                        const error = data.registration.errors.at(0);
                        toaster.error({
                            header: <FormattedMessage defaultMessage="Chyba" />,
                            message: error?.message,
                            duration: 5000,
                        });
                    }
                })
                .catch((error: Error) => {
                    toaster.error({
                        header: <FormattedMessage defaultMessage="Chyba" />,
                        message: error.message,
                        duration: 5000,
                    });
                })
                .finally(() => {
                    actions.setSubmitting(false);
                });
        },
        [register, isOwner, isCompany, onSuccess, locale, toaster, googleIdToken, id, lastUrl],
    );

    const requiredMessage = useMemo(() => intl.formatMessage({defaultMessage: 'Povinné pole'}), [intl]);
    let passwordValidation = Yup.string().matches(
        /^(?=.*[\d!@#$%^&*()\\\[\]{}\-_+=~`|:;"'<>,./?])(?=(.*[a-zA-Z]))(?=(.*)).{8,}$/,
        intl.formatMessage({defaultMessage: 'Heslo není dostatečně silné'}),
    );

    if (!googleIdToken) {
        passwordValidation = passwordValidation.required(requiredMessage);
    }

    const initialValues: FormValues = {
        firstname: firstname ?? '',
        lastname: lastname ?? '',
        email: email ?? '',
        phone: '',
        password: '',
        identificationNumber: undefined,
        vatNumber: '',
        companyName: '',
        companyType: UserGroupKeyType.Undefined,
        companyStreet: '',
        companyStreetNumber: '',
        companyCity: '',
        companyZip: '',
        terms: false,
        newsletter: false,
    };

    return (
        <>
            <Typography as="h2" variant="h4" className="mb-2" textAlign="center">
                <FormattedMessage defaultMessage="Zaregistrujte se u nás" />
            </Typography>

            {onLogin && <LoginRegisterToggle id={id} onLogin={onLogin} type={LoginRegisterToggleEnum.Register} />}

            {/* <Typography as="p" variant="perex" color="grey-dark" className="mb-4" textAlign="center">
                <FormattedMessage
                    defaultMessage="Zadejte pár údajů a začněte hned využívat všech <toggle>výhod</toggle>."
                    values={{
                        toggle: (chunks: any) => (
                            <a
                                className="link"
                                role="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    toggleOpened();
                                }}
                            >
                                {chunks}
                            </a>
                        ),
                    }}
                />
            </Typography> */}

            <UspBox
                usps={[
                    <FormattedMessage defaultMessage="Ušetřete provizi realitních kanceláří u přímých majitelů" />,
                    <FormattedMessage defaultMessage="Odepisujete na nejnovější inzeráty" />,
                    <FormattedMessage defaultMessage="Nastavte si hlídacího psa" />,
                    <FormattedMessage defaultMessage="Přidávejte si vysněné nabídky do oblíbených" />,
                ]}
            />

            <TabsNav align="center" className="mb-6">
                <TabsNav.Button
                    active={!isCompany}
                    onClick={() => {
                        setCompany(false);
                    }}
                >
                    <FormattedMessage defaultMessage="Fyzická osoba" />
                </TabsNav.Button>
                <TabsNav.Button
                    active={isCompany}
                    onClick={() => {
                        setCompany(true);
                    }}
                >
                    <FormattedMessage defaultMessage="Právnická osoba" />
                </TabsNav.Button>
            </TabsNav>

            <Row className="g-3 mb-8">
                <Col xs={6}>
                    <ToggleButton
                        size="lg"
                        type="radio"
                        name="need-help"
                        id="need-help-no"
                        value="1"
                        checked={!isOwner}
                        onChange={() => {
                            setOwner(false);
                        }}
                    >
                        <Box as="span" className="d-block w-100 text-start">
                            <Typography className="d-block" textTransform="uppercase" textAlign="center">
                                <FormattedMessage defaultMessage="Jsem zájemce" />
                            </Typography>
                            <Typography className="d-block mt-2" variant="perex" color="grey-dark" textAlign="center">
                                <FormattedMessage defaultMessage="Hledám nemovitost" />
                            </Typography>
                        </Box>
                    </ToggleButton>
                </Col>

                <Col xs={6}>
                    <ToggleButton
                        size="lg"
                        type="radio"
                        name="need-help"
                        id="need-help-yes"
                        value="2"
                        checked={isOwner}
                        onChange={() => {
                            setOwner(true);
                        }}
                    >
                        <Box as="span" className="d-block w-100 text-start">
                            <Typography className="d-block" textTransform="uppercase" textAlign="center">
                                <FormattedMessage defaultMessage="Jsem majitel" />
                            </Typography>
                            <Typography className="d-block mt-2" variant="perex" color="grey-dark" textAlign="center">
                                <FormattedMessage defaultMessage="Nabízím nemovitost" />
                            </Typography>
                        </Box>
                    </ToggleButton>
                </Col>
            </Row>

            <Formik
                onSubmit={processSubmit}
                initialValues={initialValues}
                enableReinitialize
                validationSchema={Yup.object({
                    firstname: Yup.string().required(requiredMessage),
                    lastname: Yup.string().required(requiredMessage),
                    phone: Yup.string()
                        .phone('CZ', intl.formatMessage({defaultMessage: 'Neplatný formát'}))
                        .required(requiredMessage),
                    email: Yup.string()
                        .email(intl.formatMessage({defaultMessage: 'Neplatný email'}))
                        .required(requiredMessage),
                    password: passwordValidation,
                    passwordCheck: passwordValidation.oneOf(
                        [Yup.ref('password')],
                        intl.formatMessage({defaultMessage: 'Zadaná hesla se neshodují'}),
                    ),
                    identificationNumber: isCompany ? Yup.number().required(requiredMessage) : Yup.number().optional(),
                    vatNumber: Yup.string(),
                    companyName: isCompany ? Yup.string().required(requiredMessage) : Yup.string(),
                    companyType: isCompany ? Yup.string().required(requiredMessage) : Yup.string(),
                    companyStreet: isCompany ? Yup.string().required(requiredMessage) : Yup.string(),
                    companyStreetNumber: isCompany ? Yup.string().required(requiredMessage) : Yup.string(),
                    companyCity: isCompany ? Yup.string().required(requiredMessage) : Yup.string(),
                    companyZip: isCompany ? Yup.string().required(requiredMessage) : Yup.string(),
                    terms: Yup.boolean().oneOf([true], requiredMessage),
                    newsletter: Yup.boolean(),
                })}
            >
                {({handleSubmit}) => (
                    <Form onSubmit={handleSubmit} noValidate>
                        {isCompany && (
                            <>
                                <Input
                                    className="mb-4"
                                    name="identificationNumber"
                                    type="number"
                                    placeholder={intl.formatMessage({defaultMessage: 'IČ'})}
                                />

                                <Input className="mb-4" name="vatNumber" placeholder={intl.formatMessage({defaultMessage: 'DIČ'})} />

                                <Input
                                    className="mb-4"
                                    name="companyName"
                                    autoComplete="organization"
                                    placeholder={intl.formatMessage({defaultMessage: 'Název právnické osoby'})}
                                />

                                <SelectInput
                                    options={entries(translations)
                                        .filter((v) => v[0] !== UserGroupKeyType.Undefined)
                                        .map(([key, value]) => ({value: key, name: value}))}
                                    className="mb-4"
                                    name="companyType"
                                    placeholder=" "
                                    label={intl.formatMessage({defaultMessage: 'Typ právnické osoby'})}
                                />

                                <Input className="mb-4" name="companyStreet" placeholder={intl.formatMessage({defaultMessage: 'Ulice'})} />

                                <Input
                                    className="mb-4"
                                    name="companyStreetNumber"
                                    placeholder={intl.formatMessage({defaultMessage: 'Číslo popisné'})}
                                />

                                <Input className="mb-4" name="companyCity" placeholder={intl.formatMessage({defaultMessage: 'Město'})} />

                                <Input className="mb-4" name="companyZip" placeholder={intl.formatMessage({defaultMessage: 'PSČ'})} />
                            </>
                        )}

                        <Input
                            className="mb-4"
                            name="firstname"
                            autoComplete="given-name"
                            placeholder={intl.formatMessage({defaultMessage: 'Jméno'})}
                        />

                        <Input
                            className="mb-4"
                            name="lastname"
                            autoComplete="family-name"
                            placeholder={intl.formatMessage({defaultMessage: 'Příjmení'})}
                        />

                        <PhoneInput
                            className="mb-4"
                            name="phone"
                            placeholder={intl.formatMessage({defaultMessage: 'Telefon'})}
                            label={intl.formatMessage({defaultMessage: 'Telefon'})}
                            useWhitelistedCountriesOnly
                        />

                        <Input
                            readOnly={!!googleIdToken}
                            className="mb-4"
                            name="email"
                            autoComplete="email"
                            trimOnBlur
                            placeholder={intl.formatMessage({defaultMessage: 'Vaše e-mailová adresa'})}
                        />

                        {showInsertTips && (
                            <InfoText className="mb-4">
                                <FormattedMessage defaultMessage="Tyto údaje zájemci neuvidí a budou vás kontaktovat prostřednictvím komunikační platformy" />
                            </InfoText>
                        )}

                        {!googleIdToken && (
                            <>
                                <PasswordInput
                                    className="mb-4"
                                    name="password"
                                    autoComplete="new-password"
                                    placeholder={intl.formatMessage({defaultMessage: 'Vaše heslo'})}
                                    label={intl.formatMessage({defaultMessage: 'Heslo'})}
                                />

                                <PasswordInput
                                    className="mb-4"
                                    name="passwordCheck"
                                    autoComplete="new-password"
                                    placeholder={intl.formatMessage({defaultMessage: 'Zopakujte heslo'})}
                                    label={intl.formatMessage({defaultMessage: 'Potvrdit heslo'})}
                                />
                            </>
                        )}

                        {showInsertTips && (
                            <InfoText className="mb-4">
                                <FormattedMessage defaultMessage="Heslo potřebujete pro uložení inzerátu" />
                            </InfoText>
                        )}

                        <InfoText className="mb-4">
                            <FormattedMessage defaultMessage="Zadejte silné heslo (alespoň 8 znaků, speciální symbol nebo číslo)" />
                        </InfoText>

                        {googleIdToken && (
                            <InfoText className="mb-4">
                                <FormattedMessage defaultMessage="Váš účet bude automaticky propojen s vaším Google účtem. Heslo v tomto případě není povinné." />
                            </InfoText>
                        )}

                        {termsData && privacyPolicyData && (
                            <CheckboxInput
                                label={
                                    <FormattedMessage
                                        defaultMessage="Souhlasím se <terms>Smluvními podmínkami</terms> a <privacy>Zásadami pro ochranu osobních údajů</privacy>, zároveň potvrzuji, že nejsem realitní makléř."
                                        values={{
                                            terms: (chunks: any) => (
                                                <Link
                                                    href={getLocalizedRoute(routes.page, locale, {
                                                        uri: [termsData.pageInfo?.uri, termsData.pageInfo?.suburi]
                                                            .filter((d) => !!d)
                                                            .join('/'),
                                                    })}
                                                    target="_blank"
                                                >
                                                    {chunks}
                                                </Link>
                                            ),
                                            privacy: (chunks: any) => (
                                                <Link
                                                    href={getLocalizedRoute(routes.page, locale, {
                                                        uri: [privacyPolicyData.pageInfo?.uri, privacyPolicyData.pageInfo?.suburi]
                                                            .filter((d) => !!d)
                                                            .join('/'),
                                                    })}
                                                    target="_blank"
                                                >
                                                    {chunks}
                                                </Link>
                                            ),
                                        }}
                                    />
                                }
                                name="terms"
                                className="mb-3"
                            />
                        )}

                        {locale === LocaleEnum.cs && (
                            <InfoTooltip
                                trigger={isMdDown ? ['hover', 'focus', 'click'] : 'click'}
                                className="mb-3"
                                content={
                                    <FormattedMessage
                                        defaultMessage="Odesláním souhlasíte se zpracováním osobních údajů za účelem nabízení a zpracováním marketingových nabídek společnosti Bezrealitky s.r.o. Kdykoliv máte právo svůj souhlas odvolat. Více informací v <link>zásadách zpracování osobních údajů</link>."
                                        values={{
                                            link: (chunks: ReactNode) => (
                                                <Link
                                                    href={getLocalizedRoute(routes.page, locale, {
                                                        uri: 'smluvni-podminky/zasady-zpracovani-osobnich-udaju-marketing',
                                                    })}
                                                    passHref
                                                >
                                                    {chunks}
                                                </Link>
                                            ),
                                        }}
                                    />
                                }
                            >
                                <CheckboxInput
                                    label={<FormattedMessage defaultMessage="Odebírat novinky z realitního trhu" />}
                                    name="newsletter"
                                />
                            </InfoTooltip>
                        )}

                        {termsData && privacyPolicyData && (
                            <SubmitButton className="w-100">
                                <FormattedMessage defaultMessage="Pokračovat" />
                            </SubmitButton>
                        )}
                    </Form>
                )}
            </Formik>

            <ThirdPartyLogin callbackUrl={getLocalizedRoute(routes.homepage, locale)} />
        </>
    );
};
