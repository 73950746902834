import classNames from 'classnames';
import {CSSProperties, forwardRef, PropsWithChildren, ReactNode} from 'react';
import {Color} from 'src/types/colors';
import styles from './Icon.module.scss';
import {IconArchive} from './IconArchive';
import {IconArchiveArrow} from './IconArchiveArrow';
import {IconArrowDown} from './IconArrowDown';
import {IconArrowDownCircle} from './IconArrowDownCircle';
import {IconArrowDownLeft} from './IconArrowDownLeft';
import {IconArrowLeft} from './IconArrowLeft';
import {IconArrowRight} from './IconArrowRight';
import {IconArrowsLeftRight} from './IconArrowsLeftRight';
import {IconArrowUp} from './IconArrowUp';
import {IconArrowUpDownCircle} from './IconArrowUpDownCircle';
import {IconBalcony} from './IconBalcony';
import {IconBell} from './IconBell';
import {IconBank} from './IconBank';
import {IconBlock} from './IconBlock';
import {IconBook} from './IconBook';
import {IconBrush} from './IconBrush';
import {IconBuilding} from './IconBuilding';
import {IconCake} from './IconCake';
import {IconCalendar} from './IconCalendar';
import {IconCalendarViewDay} from './IconCalendarViewDay';
import {IconCalendarViewMonth} from './IconCalendarViewMonth';
import {IconCalendarViewWeek} from './IconCalendarViewWeek';
import {IconCamera} from './IconCamera';
import {IconCameraRotate} from './IconCameraRotate';
import {IconCertificate} from './IconCertificate';
import {IconChart} from './IconChart';
import {IconChat} from './IconChat';
import {IconCheck} from './IconCheck';
import {IconCheckCircle} from './IconCheckCircle';
import {IconCheckCloud} from './IconCheckCloud';
import {IconCheckPlus} from './IconCheckPlus';
import {IconCheckSquare} from './IconCheckSquare';
import {IconChevronBigDown} from './IconChevronBigDown';
import {IconChevronBigLeft} from './IconChevronBigLeft';
import {IconChevronBigRight} from './IconChevronBigRight';
import {IconChevronBigUp} from './IconChevronBigUp';
import {IconChevronDoubleRight} from './IconChevronDoubleRight';
import {IconChevronDown} from './IconChevronDown';
import {IconChevronLeft} from './IconChevronLeft';
import {IconChevronRight} from './IconChevronRight';
import {IconChevronUp} from './IconChevronUp';
import {IconCard} from './IconCard';
import {IconClipboard} from './IconClipboard';
import {IconClock} from './IconClock';
import {IconClose} from './IconClose';
import {IconCloseCircle} from './IconCloseCircle';
import {IconCog} from './IconCog';
import {IconCopy} from './IconCopy';
import {IconCopyAddPlus} from './IconCopyAddPlus';
import {IconCopyright} from './IconCopyright';
import {IconCross} from './IconCross';
import {IconDashboard} from './IconDashboard';
import {IconDeactivate} from './IconDeactivate';
import {IconDelivery} from './IconDelivery';
import {IconDeviceComputer} from './IconDeviceComputer';
import {IconDeviceMobile} from './IconDeviceMobile';
import {IconDeviceUnclear} from './IconDeviceUnclear';
import {IconDocument} from './IconDocument';
import {IconDocumentBan} from './IconDocumentBan';
import {IconDocumentCalculator} from './IconDocumentCalculator';
import {IconDocumentContract} from './IconDocumentContract';
import {IconDocumentCheck} from './IconDocumentCheck';
import {IconDocumentEnergy} from './IconDocumentEnergy';
import {IconDocumentHouse} from './IconDocumentHouse';
import {IconDocumentHouseCheck} from './IconDocumentHouseCheck';
import {IconDocumentSearch} from './IconDocumentSearch';
import {IconDotsHorizontal} from './IconDotsHorizontal';
import {IconDotsVertical} from './IconDotsVertical';
import {IconDownload} from './IconDownload';
import {IconDrugstore} from './IconDrugstore';
import {IconEdit} from './IconEdit';
import {IconEditNote} from './IconEditNote';
import {IconEducation} from './IconEducation';
import {IconElectricSocket} from './IconElectricSocket';
import {IconElevator} from './IconElevator';
import {IconEnergy} from './IconEnergy';
import {IconEnvelope} from './IconEnvelope';
import {IconEnvelopeCheck} from './IconEnvelopeCheck';
import {IconEnvelopes} from './IconEnvelopes';
import {IconEquipment} from './IconEquipment';
import {IconEvaluate} from './IconEvaluate';
import {IconEye} from './IconEye';
import {IconEyeCheck} from './IconEyeCheck';
import {IconEyeCross} from './IconEyeCross';
import {IconFacebook} from './IconFacebook';
import {IconFactCheck} from './IconFactCheck';
import {IconFamily} from './IconFamily';
import {IconFilter} from './IconFilter';
import {IconFloorArea} from './IconFloorArea';
import {IconFloorAreaLand} from './IconFloorAreaLand';
import {IconFloorPlan} from './IconFloorPlan';
import {IconGarage} from './IconGarage';
import {IconGift} from './IconGift';
import {IconGlobe} from './IconGlobe';
import {IconGroup} from './IconGroup';
import {IconGym} from './IconGym';
import {IconHandArrow} from './IconHandArrow';
import {IconHandButton} from './IconHandButton';
import {IconHandCheck} from './IconHandCheck';
import {IconHandClick} from './IconHandClick';
import {IconHandDoubleClick} from './IconHandDoubleClick';
import {IconHandRight} from './IconHandRight';
import {IconHandUp} from './IconHandUp';
import {IconHeart} from './IconHeart';
import {IconHeartFilled} from './IconHeartFilled';
import {IconHome} from './IconHome';
import {IconHomeGenerate} from './IconHomeGenerate';
import {IconHospital} from './IconHospital';
import {IconHouseGarage} from './IconHouseGarage';
import {IconHouseSale} from './IconHouseSale';
import {IconInfoCircle} from './IconInfoCircle';
import {IconInstagram} from './IconInstagram';
import {IconInternet} from './IconInternet';
import {IconKey} from './IconKey';
import {IconLegalScale} from './IconLegalScale';
import {IconLightbulb} from './IconLightbulb';
import {IconLink} from './IconLink';
import {IconLinkedIn} from './IconLinkedIn';
import {IconList} from './IconList';
import {IconLocation} from './IconLocation';
import {IconLock} from './IconLock';
import {IconLogoSymbol} from './IconLogoSymbol';
import {IconLogout} from './IconLogout';
import {IconMagic} from './IconMagic';
import {IconMarket} from './IconMarket';
import {IconMenu} from './IconMenu';
import {IconMessage} from './IconMessage';
import {IconMessageMove} from './IconMessageMove';
import {IconMessages} from './IconMessages';
import {IconMessageSend} from './IconMessageSend';
import {IconMessageText} from './IconMessageText';
import {IconMessageUnread} from './IconMessageUnread';
import {IconMinus} from './IconMinus';
import {IconMobileCheck} from './IconMobileCheck';
import {IconMoney} from './IconMoney';
import {IconMountain} from './IconMountain';
import {IconNursery} from './IconNursery';
import {IconOffice} from './IconOffice';
import {IconOneCircle} from './IconOneCircle';
import {IconParking} from './IconParking';
import {IconPercentage} from './IconPercentage';
import {IconPets} from './IconPets';
import {IconPhone} from './IconPhone';
import {IconPicture} from './IconPicture';
import {IconPictureAdd} from './IconPictureAdd';
import {IconPin} from './IconPin';
import {IconPlayCircle} from './IconPlayCircle';
import {IconPlus} from './IconPlus';
import {IconPortrait} from './IconPortrait';
import {IconProfile} from './IconProfile';
import {IconProfileSquare} from '@components/Icon/IconProfileSquare';
import {IconPublicTransport} from './IconPublicTransport';
import {IconQuestionMarkCircle} from './IconQuestionMarkCircle';
import {IconQuickReply} from './IconQuickReply';
import {IconQuotationMark} from './IconQuotationMark';
import {IconReload} from './IconReload';
import {IconRestaurant} from './IconRestaurant';
import {IconRotateLeft} from './IconRotateLeft';
import {IconRotateRight} from './IconRotateRight';
import {IconSchool} from './IconSchool';
import {IconSearch} from './IconSearch';
import {IconSelectAll} from './IconSelectAll';
import {IconSettings} from './IconSettings';
import {IconShare} from './IconShare';
import {IconShareArrow} from './IconShareArrow';
import {IconShield} from './IconShield';
import {IconSmoking} from './IconSmoking';
import {IconSport} from './IconSport';
import {IconStack} from './IconStack';
import {IconStar} from './IconStar';
import {IconStarSolid} from './IconStarSolid';
import {IconStarSquare} from './IconStarSquare';
import {IconStore} from './IconStore';
import {IconSubway} from './IconSubway';
import {IconTag} from './IconTag';
import {IconTagColor} from './IconTagColor';
import {IconThreeCircle} from './IconThreeCircle';
import {IconTrash} from './IconTrash';
import {IconTreeHouse} from './IconTreeHouse';
import {IconTriangleDown} from './IconTriangleDown';
import {IconTriangleUp} from './IconTriangleUp';
import {IconTwitter} from './IconTwitter';
import {IconTwoCircle} from './IconTwoCircle';
import {IconUnarchive} from './IconUnarchive';
import {IconUser} from './IconUser';
import {IconUserCheck} from './IconUserCheck';
import {IconUserCircle} from './IconUserCircle';
import {IconUserCheckSquare} from './IconUserCheckSquare';
import {IconWallCalendar} from './IconWallCalendar';
import {IconWallet} from './IconWallet';
import {IconWalletAdd} from './IconWalletAdd';
import {IconWarning} from './IconWarning';
import {IconWarningTriangle} from './IconWarningTriangle';
import {IconWatchdog} from './IconWatchdog';
import {IconWheelchair} from './IconWheelchair';
import {IconWork} from './IconWork';
import {IconTarget} from '@components/Icon/IconTarget';
import {IconInsurance} from '@components/Icon/IconInsurance';
import {IconHealthSuitcase} from '@components/Icon/IconHealthSuitcase';
import {IconPortraitHealth} from '@components/Icon/IconPortraitHealth';
import {IconBroke} from '@components/Icon/IconBroke';
import {IconWalletMoney} from '@components/Icon/IconWalletMoney';
import {IconHandshake} from '@components/Icon/IconHandshake';
import {IconNewspaper} from '@components/Icon/IconNewspaper';

export type IconType = keyof typeof icons;

export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type IconProps = {
    id?: string;
    name?: IconType;
    width?: number;
    height?: number;
    position?: 'start' | 'end' | 'middle';
    size?: IconSize;
    color?: Color;
    className?: string;
    style?: CSSProperties;
};

type IconWithIdProps = {
    id: string;
    children: ReactNode;
};

type IconWithNameProps = {
    name: IconType;
};

const Icon = forwardRef<HTMLElement, PropsWithChildren<IconProps & (IconWithIdProps | IconWithNameProps)>>((props, ref) => {
    const {id, name, width = 24, height = 24, position, size, color, style, className, children} = props;

    const NamedIcon = name ? icons[name] : null;

    return id ? (
        <span
            ref={ref}
            className={classNames(styles.icon, 'icon', className, {
                [styles[`icon--${size}`]]: size,
                [styles[`icon--${position}`]]: position,
                [`text-${color}`]: color,
            })}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} fill="none" style={style}>
                {children}
            </svg>
        </span>
    ) : (
        NamedIcon && <NamedIcon {...props} />
    );
});

export const icons = {
    Archive: IconArchive,
    ArchiveArrow: IconArchiveArrow,
    ArrowDown: IconArrowDown,
    ArrowDownCircle: IconArrowDownCircle,
    ArrowDownLeft: IconArrowDownLeft,
    ArrowLeft: IconArrowLeft,
    ArrowRight: IconArrowRight,
    ArrowUp: IconArrowUp,
    ArrowUpDownCircle: IconArrowUpDownCircle,
    ArrowsLeftRight: IconArrowsLeftRight,
    Balcony: IconBalcony,
    Bell: IconBell,
    Bank: IconBank,
    Block: IconBlock,
    Book: IconBook,
    Broke: IconBroke,
    Brush: IconBrush,
    Building: IconBuilding,
    Cake: IconCake,
    Calendar: IconCalendar,
    CalendarViewDay: IconCalendarViewDay,
    CalendarViewMonth: IconCalendarViewMonth,
    CalendarViewWeek: IconCalendarViewWeek,
    Camera: IconCamera,
    CameraRotate: IconCameraRotate,
    Certificate: IconCertificate,
    Chart: IconChart,
    Chat: IconChat,
    Check: IconCheck,
    CheckCircle: IconCheckCircle,
    CheckCloud: IconCheckCloud,
    CheckPlus: IconCheckPlus,
    CheckSquare: IconCheckSquare,
    ChevronBigDown: IconChevronBigDown,
    ChevronBigLeft: IconChevronBigLeft,
    ChevronBigRight: IconChevronBigRight,
    ChevronBigUp: IconChevronBigUp,
    ChevronDoubleRight: IconChevronDoubleRight,
    ChevronDown: IconChevronDown,
    ChevronLeft: IconChevronLeft,
    ChevronRight: IconChevronRight,
    ChevronUp: IconChevronUp,
    Card: IconCard,
    Clipboard: IconClipboard,
    Clock: IconClock,
    Close: IconClose,
    CloseCircle: IconCloseCircle,
    Cog: IconCog,
    Copy: IconCopy,
    CopyAddPlus: IconCopyAddPlus,
    Copyright: IconCopyright,
    Cross: IconCross,
    Dashboard: IconDashboard,
    Deactivate: IconDeactivate,
    Delivery: IconDelivery,
    DeviceComputer: IconDeviceComputer,
    DeviceMobile: IconDeviceMobile,
    DeviceUnclear: IconDeviceUnclear,
    Document: IconDocument,
    DocumentBan: IconDocumentBan,
    DocumentCalculator: IconDocumentCalculator,
    DocumentCheck: IconDocumentCheck,
    DocumentContract: IconDocumentContract,
    DocumentEnergy: IconDocumentEnergy,
    DocumentHouse: IconDocumentHouse,
    DocumentHouseCheck: IconDocumentHouseCheck,
    DocumentSearch: IconDocumentSearch,
    DotsHorizontal: IconDotsHorizontal,
    DotsVertical: IconDotsVertical,
    Download: IconDownload,
    Drugstore: IconDrugstore,
    Edit: IconEdit,
    EditNote: IconEditNote,
    Education: IconEducation,
    ElectricSocket: IconElectricSocket,
    Elevator: IconElevator,
    Energy: IconEnergy,
    Envelope: IconEnvelope,
    EnvelopeCheck: IconEnvelopeCheck,
    Envelopes: IconEnvelopes,
    Equipment: IconEquipment,
    Evaluate: IconEvaluate,
    Eye: IconEye,
    EyeCheck: IconEyeCheck,
    EyeCross: IconEyeCross,
    Facebook: IconFacebook,
    FactCheck: IconFactCheck,
    Family: IconFamily,
    Filter: IconFilter,
    FloorArea: IconFloorArea,
    FloorAreaLand: IconFloorAreaLand,
    FloorPlan: IconFloorPlan,
    Garage: IconGarage,
    Gift: IconGift,
    Globe: IconGlobe,
    Group: IconGroup,
    Gym: IconGym,
    HandArrow: IconHandArrow,
    HandButton: IconHandButton,
    HandCheck: IconHandCheck,
    HandClick: IconHandClick,
    HandDoubleClick: IconHandDoubleClick,
    HandRight: IconHandRight,
    HandShake: IconHandshake,
    HandUp: IconHandUp,
    HealthSuitcase: IconHealthSuitcase,
    Heart: IconHeart,
    HeartFilled: IconHeartFilled,
    Home: IconHome,
    HomeGenerate: IconHomeGenerate,
    Hospital: IconHospital,
    HouseGarage: IconHouseGarage,
    HouseSale: IconHouseSale,
    InfoCircle: IconInfoCircle,
    Instagram: IconInstagram,
    Insurance: IconInsurance,
    Internet: IconInternet,
    Key: IconKey,
    LegalScale: IconLegalScale,
    Lightbulb: IconLightbulb,
    Link: IconLink,
    LinkedIn: IconLinkedIn,
    List: IconList,
    Location: IconLocation,
    Lock: IconLock,
    LogoSymbol: IconLogoSymbol,
    Logout: IconLogout,
    Magic: IconMagic,
    Market: IconMarket,
    Menu: IconMenu,
    Message: IconMessage,
    MessageMove: IconMessageMove,
    MessageSend: IconMessageSend,
    MessageText: IconMessageText,
    MessageUnread: IconMessageUnread,
    Messages: IconMessages,
    Minus: IconMinus,
    MobileCheck: IconMobileCheck,
    Money: IconMoney,
    Mountain: IconMountain,
    Newspaper: IconNewspaper,
    Nursery: IconNursery,
    Office: IconOffice,
    OneCircle: IconOneCircle,
    Parking: IconParking,
    Percentage: IconPercentage,
    Pets: IconPets,
    Phone: IconPhone,
    Picture: IconPicture,
    PictureAdd: IconPictureAdd,
    Pin: IconPin,
    PlayCircle: IconPlayCircle,
    Plus: IconPlus,
    Portrait: IconPortrait,
    PortraitHealth: IconPortraitHealth,
    Profile: IconProfile,
    ProfileSquare: IconProfileSquare,
    PublicTransport: IconPublicTransport,
    QuestionMarkCircle: IconQuestionMarkCircle,
    QuickReply: IconQuickReply,
    QuotationMark: IconQuotationMark,
    Reload: IconReload,
    Restaurant: IconRestaurant,
    RotateLeft: IconRotateLeft,
    RotateRight: IconRotateRight,
    School: IconSchool,
    Search: IconSearch,
    SelectAll: IconSelectAll,
    Settings: IconSettings,
    Share: IconShare,
    ShareArrow: IconShareArrow,
    Shield: IconShield,
    Smoking: IconSmoking,
    Sport: IconSport,
    Stack: IconStack,
    Star: IconStar,
    StarSolid: IconStarSolid,
    StarSquare: IconStarSquare,
    Store: IconStore,
    Subway: IconSubway,
    Tag: IconTag,
    TagColor: IconTagColor,
    ThreeCircle: IconThreeCircle,
    Target: IconTarget,
    Trash: IconTrash,
    TreeHouse: IconTreeHouse,
    TriangleDown: IconTriangleDown,
    TriangleUp: IconTriangleUp,
    Twitter: IconTwitter,
    TwoCircle: IconTwoCircle,
    Unarchive: IconUnarchive,
    User: IconUser,
    UserCircle: IconUserCircle,
    UserCheck: IconUserCheck,
    UserCheckSquare: IconUserCheckSquare,
    WallCalendar: IconWallCalendar,
    Wallet: IconWallet,
    WalletAdd: IconWalletAdd,
    WalletMoney: IconWalletMoney,
    Warning: IconWarning,
    WarningTriangle: IconWarningTriangle,
    Watchdog: IconWatchdog,
    Wheelchair: IconWheelchair,
    Work: IconWork,
};

export default Object.assign(Icon, icons);
