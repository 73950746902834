import {Button} from '@components/Button';
import {Divider} from '@components/Divider';
import {signIn} from 'next-auth/react';
import {FormattedMessage} from 'react-intl';

type ThirdPartyLoginProps = {
    callbackUrl: string;
};

export const ThirdPartyLogin = ({callbackUrl}: ThirdPartyLoginProps) => (
    <>
        <Divider className="my-10" color="grey-light">
            <FormattedMessage defaultMessage="Nebo" />
        </Divider>

        <Button className="w-100" variant="outline-primary" onClick={() => void signIn('google', {redirect: true, callbackUrl})}>
            <FormattedMessage defaultMessage="Pokračovat pomocí Google" />
        </Button>
    </>
);
