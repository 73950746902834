export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: number; output: number; }
  GPSMultipolygon: { input: {lng: string, lat: string}[][][]; output: {lng: string, lat: string}[][][]; }
  ImageUpload: { input: any; output: any; }
  MessageAttachmentUpload: { input: any; output: any; }
  VideoUpload: { input: any; output: any; }
};

export enum AccountType {
  /** Základní účet */
  Basic = 'BASIC',
  /** Bezplatný účet */
  Free = 'FREE',
  /** Premium profil */
  Premium = 'PREMIUM'
}

export enum ActivationAction {
  /** Aktivace */
  Activate = 'ACTIVATE',
  /** Prodloužení */
  Extend = 'EXTEND',
  /** Reaktivace zdarma */
  FreeReactivate = 'FREE_REACTIVATE',
  /** Zvýraznění */
  Highlight = 'HIGHLIGHT'
}

/** Advert object including basic information about advertisement */
export type Advert = DocumentReference & GalleryReference & NotificationReference & {
  __typename?: 'Advert';
  /** Action needed to activate advert */
  activationAction?: Maybe<ActivationAction>;
  /** Check if an order for activation has been created in the last 10 minutes. */
  activationPending?: Maybe<Scalars['Boolean']['output']>;
  /** Contains information about active state */
  active?: Maybe<Scalars['Boolean']['output']>;
  /** Generated address from region data */
  address?: Maybe<Scalars['String']['output']>;
  /** Address inserted by user */
  addressInput?: Maybe<Scalars['String']['output']>;
  /**
   * ID adresního bodu
   * @deprecated To be removed
   */
  addressPointId?: Maybe<Scalars['String']['output']>;
  /** Address as user typed it */
  addressUserInput?: Maybe<Scalars['String']['output']>;
  advertStatsDaily?: Maybe<Array<Maybe<AdvertStatsDaily>>>;
  advertUserstate?: Maybe<AdvertUserstate>;
  age?: Maybe<Age>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  availableFrom?: Maybe<Scalars['DateTime']['output']>;
  /** Advert badge/label */
  badge?: Maybe<AdvertBadge>;
  /** @deprecated use balconySurface instead */
  balcony?: Maybe<Scalars['Boolean']['output']>;
  balconySurface?: Maybe<Scalars['Float']['output']>;
  barrierFree?: Maybe<Scalars['Boolean']['output']>;
  breadcrumbs?: Maybe<Array<Maybe<BreadcrumbItem>>>;
  brizCount: Scalars['Int']['output'];
  /** Broker (formerly called Comfort specialist) */
  broker?: Maybe<Broker>;
  calendarEntries?: Maybe<CalendarEntryList>;
  /** @deprecated use cellarSurface instead */
  cellar?: Maybe<Scalars['Boolean']['output']>;
  cellarSurface?: Maybe<Scalars['Float']['output']>;
  /**
   * Sum of service and utility charges
   *
   */
  charges?: Maybe<Scalars['Int']['output']>;
  charity?: Maybe<Scalars['Boolean']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cityDistrict?: Maybe<Scalars['String']['output']>;
  /** Developers code */
  code?: Maybe<Scalars['String']['output']>;
  condition?: Maybe<Condition>;
  construction?: Maybe<Construction>;
  /** Number of conversations */
  conversationCount?: Maybe<Scalars['Int']['output']>;
  /** List of conversations related to advert */
  conversations?: Maybe<ConversationList>;
  currency?: Maybe<Currency>;
  currentRentContract?: Maybe<RentContract>;
  dataJson?: Maybe<Scalars['String']['output']>;
  /** Number of days advert since last payment */
  daysActive?: Maybe<Scalars['String']['output']>;
  deposit?: Maybe<Scalars['Int']['output']>;
  /** Description */
  description?: Maybe<Scalars['String']['output']>;
  /** Description by given locale, default CS */
  descriptionByLocale?: Maybe<Scalars['String']['output']>;
  /** Description in english */
  descriptionEnglish?: Maybe<Scalars['String']['output']>;
  /** Description in slovak */
  descriptionSk?: Maybe<Scalars['String']['output']>;
  disposition?: Maybe<Disposition>;
  equipped?: Maybe<Equipped>;
  estateType?: Maybe<EstateType>;
  estimationWatch: AdvertEstimationWatch;
  etage?: Maybe<Scalars['Int']['output']>;
  execution?: Maybe<Execution>;
  /** External ID from import */
  externalId?: Maybe<Scalars['ID']['output']>;
  fee?: Maybe<Scalars['Int']['output']>;
  /** Check if Advert has 5 or more reactions */
  fivesome?: Maybe<Scalars['Boolean']['output']>;
  floor?: Maybe<Floor>;
  formattedAds?: Maybe<Array<Maybe<FormattedParameter>>>;
  formattedBoolParameters?: Maybe<Array<Maybe<FormattedBoolParameter>>>;
  formattedParameters?: Maybe<Array<Maybe<FormattedParameter>>>;
  frontGarden?: Maybe<Scalars['Float']['output']>;
  galleries?: Maybe<Array<Maybe<Gallery>>>;
  garage?: Maybe<Scalars['Boolean']['output']>;
  gps?: Maybe<GpsPoint>;
  hasContractRent?: Maybe<Scalars['Boolean']['output']>;
  hash?: Maybe<Scalars['String']['output']>;
  heating?: Maybe<Heating>;
  highlighted?: Maybe<Scalars['Boolean']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  houseType?: Maybe<HouseType>;
  /** House unit */
  houseUnit?: Maybe<Scalars['String']['output']>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  imageAltText?: Maybe<Scalars['String']['output']>;
  /** Check if Advert has empty optional attributes */
  incomplete?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated To be removed */
  isBrno?: Maybe<Scalars['Boolean']['output']>;
  isCityWithDistricts?: Maybe<Scalars['Boolean']['output']>;
  isDiscounted?: Maybe<Scalars['Boolean']['output']>;
  /** Identifies if the advert is editable */
  isEditable?: Maybe<Scalars['Boolean']['output']>;
  /** Identifies new adverts */
  isNew?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Check if advert is currently paused by system
   *
   */
  isPausedBySystem?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Check if advert is currently paused by user
   *
   */
  isPausedByUser?: Maybe<Scalars['Boolean']['output']>;
  isPrague?: Maybe<Scalars['Boolean']['output']>;
  isPragueEast?: Maybe<Scalars['Boolean']['output']>;
  isPragueWest?: Maybe<Scalars['Boolean']['output']>;
  isTSRegion?: Maybe<Scalars['Boolean']['output']>;
  landType?: Maybe<LandType>;
  lastMonthHighlights?: Maybe<Array<Maybe<AdvertEvent>>>;
  lastUserVisit?: Maybe<AdvertUserVisit>;
  lift?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Advert links
   *
   */
  links?: Maybe<Array<Maybe<AdvertLink>>>;
  locale?: Maybe<Locale>;
  /** @deprecated use loggiaSurface instead */
  loggia?: Maybe<Scalars['Boolean']['output']>;
  loggiaSurface?: Maybe<Scalars['Float']['output']>;
  lowEnergy?: Maybe<Scalars['Boolean']['output']>;
  /** Default image */
  mainImage?: Maybe<Image>;
  /** @deprecated To be removed, use shortTerm */
  maxRentDays?: Maybe<Scalars['Int']['output']>;
  messageData?: Maybe<AdvertMessageData>;
  /** @deprecated To be removed, use shortTerm */
  minRentDays?: Maybe<Scalars['Int']['output']>;
  mortgageData?: Maybe<MortgageData>;
  nemoreport?: Maybe<Nemoreport>;
  /** Check if Advert is new, i.e. not activated yet */
  new?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated To be removed */
  newBuilding?: Maybe<Scalars['Boolean']['output']>;
  offerType?: Maybe<OfferType>;
  originalPrice?: Maybe<Scalars['Int']['output']>;
  ownership?: Maybe<Ownership>;
  parking?: Maybe<Scalars['Boolean']['output']>;
  penb?: Maybe<Penb>;
  petFriendly?: Maybe<Scalars['Boolean']['output']>;
  poiData?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Position>;
  /** Position in city */
  positionCity?: Maybe<Scalars['String']['output']>;
  /** Position in county */
  positionCounty?: Maybe<Scalars['String']['output']>;
  /** Position in region */
  positionRegion?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  /** Price estimation history - rent */
  priceEstimationsRent?: Maybe<Array<Maybe<AdvertPriceEstimationHistory>>>;
  /** Price estimation history - sale */
  priceEstimationsSale?: Maybe<Array<Maybe<AdvertPriceEstimationHistory>>>;
  /** Formatted price */
  priceFormatted?: Maybe<Scalars['String']['output']>;
  /** Price proposal allowed */
  priceProposalAllowed?: Maybe<Scalars['Boolean']['output']>;
  /** Project this advert is part of */
  project?: Maybe<Project>;
  publicGalleries?: Maybe<Array<Maybe<Gallery>>>;
  /** Array of public images */
  publicImages?: Maybe<Array<Maybe<Image>>>;
  realmanExportEnabled: Scalars['Boolean']['output'];
  reconstruction?: Maybe<Reconstruction>;
  region?: Maybe<Region>;
  regionTree?: Maybe<Array<Maybe<Region>>>;
  /** Parameters used for searching related adverts */
  relatedAdvertParameters?: Maybe<RelatedAdvertParameters>;
  /** List of related adverts */
  relatedAdverts?: Maybe<AdvertList>;
  rentContracts?: Maybe<Array<Maybe<RentContract>>>;
  rentCosts?: Maybe<Array<Maybe<RentCost>>>;
  rentDocuments?: Maybe<Array<Maybe<RentDocument>>>;
  rentEquipments?: Maybe<Array<Maybe<RentEquipment>>>;
  rentNotes?: Maybe<Array<Maybe<RentNote>>>;
  rentPlatform?: Maybe<Scalars['Boolean']['output']>;
  rentPlatformOrder?: Maybe<Scalars['Int']['output']>;
  rentPlatformStatus?: Maybe<RentPlatformAdvertStatus>;
  rentPlatformTimeExpiration?: Maybe<Scalars['DateTime']['output']>;
  rentRepairs?: Maybe<Array<Maybe<RentRepair>>>;
  rentServices?: Maybe<Array<Maybe<RentService>>>;
  /** Require creditcheck */
  requireCreditcheck?: Maybe<Scalars['Boolean']['output']>;
  /** Require insurance */
  requireInsurance?: Maybe<Scalars['Boolean']['output']>;
  /** Contains information about reservation state */
  reserved?: Maybe<Scalars['Boolean']['output']>;
  /** Contains information about reservation user */
  reservedUser?: Maybe<User>;
  roommate?: Maybe<Scalars['Boolean']['output']>;
  /** RUIAN ID */
  ruianId?: Maybe<Scalars['String']['output']>;
  serviceCharges?: Maybe<Scalars['Int']['output']>;
  /**
   * Information about service charges
   *
   */
  serviceChargesNote?: Maybe<Scalars['String']['output']>;
  sewage?: Maybe<Sewage>;
  shortTerm: Scalars['Boolean']['output'];
  showOwnest?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated To be removed */
  showPriceSuggestionButton?: Maybe<Scalars['Boolean']['output']>;
  situation?: Maybe<Situation>;
  street?: Maybe<Scalars['String']['output']>;
  surface?: Maybe<Scalars['Int']['output']>;
  surfaceLand?: Maybe<Scalars['Int']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** @deprecated use terraceSurface instead */
  terrace?: Maybe<Scalars['Boolean']['output']>;
  terraceSurface?: Maybe<Scalars['Float']['output']>;
  /** Check if Advert has 3 or more reactions */
  threesome?: Maybe<Scalars['Boolean']['output']>;
  /** Time of activation */
  timeActivated?: Maybe<Scalars['DateTime']['output']>;
  /** Time of deactivation */
  timeDeactivated?: Maybe<Scalars['DateTime']['output']>;
  /** Time of expiration */
  timeExpiration?: Maybe<Scalars['DateTime']['output']>;
  /** Time of last top */
  timeOrder?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Time of pausing by system
   *
   */
  timePauseBySystem?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Time of pausing by user
   *
   */
  timePauseByUser?: Maybe<Scalars['DateTime']['output']>;
  /** Title */
  title: Scalars['String']['output'];
  /** Title in english */
  titleEnglish?: Maybe<Scalars['String']['output']>;
  topHistoryLog?: Maybe<Array<Maybe<Scalars['DateTime']['output']>>>;
  totalFloors?: Maybe<Scalars['Int']['output']>;
  /** 360° tour link */
  tour360?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AdvertImportType>;
  /** URI where advert is available */
  uri?: Maybe<Scalars['String']['output']>;
  /** Owner */
  user?: Maybe<User>;
  utilityCharges?: Maybe<Scalars['Int']['output']>;
  /**
   * Information about utility charges
   *
   */
  utilityChargesNote?: Maybe<Scalars['String']['output']>;
  /** Advert videos */
  videos?: Maybe<Array<Maybe<AdvertVideo>>>;
  /** Number of visits */
  visitCount?: Maybe<Scalars['Int']['output']>;
  water?: Maybe<Water>;
  zip?: Maybe<Scalars['String']['output']>;
};


/** Advert object including basic information about advertisement */
export type AdvertAddressArgs = {
  locale: Locale;
  withHouseNumber?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Advert object including basic information about advertisement */
export type AdvertAdvertUserstateArgs = {
  state: AdvertUserstateState;
};


/** Advert object including basic information about advertisement */
export type AdvertBreadcrumbsArgs = {
  locale: Locale;
};


/** Advert object including basic information about advertisement */
export type AdvertCalendarEntriesArgs = {
  free?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  timeFrom?: InputMaybe<Scalars['DateTime']['input']>;
  timeTo?: InputMaybe<Scalars['DateTime']['input']>;
};


/** Advert object including basic information about advertisement */
export type AdvertCityArgs = {
  locale: Locale;
};


/** Advert object including basic information about advertisement */
export type AdvertCityDistrictArgs = {
  locale: Locale;
};


/** Advert object including basic information about advertisement */
export type AdvertConversationsArgs = {
  activeCustomersOnly?: InputMaybe<Scalars['Boolean']['input']>;
  checkedCustomersOnly?: InputMaybe<Scalars['Boolean']['input']>;
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  fromLastActivation?: InputMaybe<Scalars['Boolean']['input']>;
  higherBidsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ConversationOrder>;
  recentOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Advert object including basic information about advertisement */
export type AdvertDescriptionByLocaleArgs = {
  locale: Locale;
};


/** Advert object including basic information about advertisement */
export type AdvertFormattedAdsArgs = {
  locale: Locale;
};


/** Advert object including basic information about advertisement */
export type AdvertFormattedBoolParametersArgs = {
  locale: Locale;
};


/** Advert object including basic information about advertisement */
export type AdvertFormattedParametersArgs = {
  locale: Locale;
};


/** Advert object including basic information about advertisement */
export type AdvertImageAltTextArgs = {
  locale: Locale;
};


/** Advert object including basic information about advertisement */
export type AdvertMessageDataArgs = {
  locale: Locale;
};


/** Advert object including basic information about advertisement */
export type AdvertMortgageDataArgs = {
  locale: Locale;
};


/** Advert object including basic information about advertisement */
export type AdvertPriceEstimationsRentArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** Advert object including basic information about advertisement */
export type AdvertPriceEstimationsSaleArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** Advert object including basic information about advertisement */
export type AdvertPriceFormattedArgs = {
  locale: Locale;
};


/** Advert object including basic information about advertisement */
export type AdvertPublicImagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** Advert object including basic information about advertisement */
export type AdvertRegionTreeArgs = {
  locale: Locale;
};


/** Advert object including basic information about advertisement */
export type AdvertRelatedAdvertsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** Advert object including basic information about advertisement */
export type AdvertTagsArgs = {
  locale: Locale;
};

export type AdvertBadge = {
  __typename?: 'AdvertBadge';
  class?: Maybe<AdvertBadgeClass>;
  text?: Maybe<Scalars['String']['output']>;
};

export enum AdvertBadgeClass {
  /** Dark green background */
  Highlight = 'HIGHLIGHT',
  /** Blue background */
  Info = 'INFO',
  /** Green background */
  Success = 'SUCCESS',
  /** Red background */
  Warning = 'WARNING'
}

export type AdvertDeactivateInput = {
  /** Action to perform */
  action: DeactivationAction;
  /** Advert ID */
  advertId: Scalars['ID']['input'];
  /**
   * Number of days for customer alerts. All customers that have written a message involving this advertisement will receive email message about deactivation.
   *
   */
  alertTime?: InputMaybe<Scalars['Int']['input']>;
  /** Textual experience */
  experience?: InputMaybe<Scalars['String']['input']>;
  /** Locale */
  locale?: InputMaybe<Locale>;
  /** Rating of experience with portal */
  rating?: InputMaybe<Scalars['Int']['input']>;
  /** Reason of deactivation */
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type AdvertDescriptionInput = {
  /** User defined address */
  address?: InputMaybe<Scalars['String']['input']>;
  /** Age */
  age?: InputMaybe<Age>;
  /** Time of possible move in */
  availableFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** Balcony surface */
  balconySurface?: InputMaybe<Scalars['Float']['input']>;
  /** Barrier free (wheelchair access) */
  barrierFree?: InputMaybe<Scalars['Boolean']['input']>;
  /** Cellar surface */
  cellarSurface?: InputMaybe<Scalars['Float']['input']>;
  /** City name */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Condition */
  condition?: InputMaybe<Condition>;
  /** Construction type */
  construction?: InputMaybe<Construction>;
  /** Disposition */
  disposition?: InputMaybe<Disposition>;
  /** State of equipment */
  equipped?: InputMaybe<Equipped>;
  /** Type of estate */
  estateType: EstateType;
  /** Floor */
  etage?: InputMaybe<Scalars['Int']['input']>;
  /** Execution */
  execution?: InputMaybe<Execution>;
  /** Front garden */
  frontGarden?: InputMaybe<Scalars['Float']['input']>;
  /** Garage */
  garage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Heating */
  heating?: InputMaybe<Heating>;
  /** House number */
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  houseUnit?: InputMaybe<Scalars['String']['input']>;
  /** Land type */
  landType?: InputMaybe<LandType>;
  /** Lift */
  lift?: InputMaybe<Scalars['Boolean']['input']>;
  /** Locale */
  locale?: InputMaybe<Locale>;
  /** Loggia surface */
  loggiaSurface?: InputMaybe<Scalars['Float']['input']>;
  /** Low energy */
  lowEnergy?: InputMaybe<Scalars['Boolean']['input']>;
  /** New building - To be removed */
  newBuilding?: InputMaybe<Scalars['Boolean']['input']>;
  /** Offer type */
  offerType: OfferType;
  /** Ownership */
  ownership?: InputMaybe<Ownership>;
  /** Parking */
  parking?: InputMaybe<Scalars['Boolean']['input']>;
  /** PENB class */
  penb?: InputMaybe<Penb>;
  /** Pet friendly */
  petFriendly?: InputMaybe<Scalars['Boolean']['input']>;
  /** Position */
  position?: InputMaybe<Position>;
  /** Reconstruction */
  reconstruction?: InputMaybe<Reconstruction>;
  /** Roommate */
  roommate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Sewage */
  sewage?: InputMaybe<Sewage>;
  /** Situation */
  situation?: InputMaybe<Situation>;
  /** Street name */
  street?: InputMaybe<Scalars['String']['input']>;
  /** Surface */
  surface?: InputMaybe<Scalars['Int']['input']>;
  /** Land surface */
  surfaceLand?: InputMaybe<Scalars['Int']['input']>;
  /** Terrace surface */
  terraceSurface?: InputMaybe<Scalars['Float']['input']>;
  /** Total number of floors */
  totalFloors?: InputMaybe<Scalars['Int']['input']>;
  /** Water */
  water?: InputMaybe<Water>;
  /** Zip code */
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum AdvertEstimationWatch {
  /** Nesledován */
  None = 'NONE',
  /** Sledován */
  Watch = 'WATCH',
  /** Sledován emailem */
  WatchEmail = 'WATCH_EMAIL'
}

export type AdvertEvent = {
  __typename?: 'AdvertEvent';
  id?: Maybe<Scalars['ID']['output']>;
  orderPackage?: Maybe<OrderPackage>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Time the event is planned to occur
   *
   */
  timeDue?: Maybe<Scalars['DateTime']['output']>;
  timeProcessed?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<AdvertEventType>;
  user?: Maybe<User>;
};

export type AdvertEventList = {
  __typename?: 'AdvertEventList';
  list?: Maybe<Array<Maybe<AdvertEvent>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum AdvertEventType {
  /** Aktivace */
  TypeActivate = 'TYPE_ACTIVATE',
  /** Aktivace projektu */
  TypeActivateProject = 'TYPE_ACTIVATE_PROJECT',
  /** Základní účet */
  TypeBasicAccount = 'TYPE_BASIC_ACCOUNT',
  /** Prodloužení */
  TypeExtend = 'TYPE_EXTEND',
  /** Reaktivace zdarma */
  TypeFreeReactivate = 'TYPE_FREE_REACTIVATE',
  /** Zvýraznění */
  TypeHighlight = 'TYPE_HIGHLIGHT',
  /** Konec - Zvýraznění */
  TypeHighlightEnd = 'TYPE_HIGHLIGHT_END',
  /** Zvýraznění ikony na mapě */
  TypeHighlightIcon = 'TYPE_HIGHLIGHT_ICON',
  /** Konec - Zvýraznění ikony na mapě */
  TypeHighlightIconEnd = 'TYPE_HIGHLIGHT_ICON_END',
  /** Homepage */
  TypeHomepage = 'TYPE_HOMEPAGE',
  /** Konec - Homepage */
  TypeHomepageEnd = 'TYPE_HOMEPAGE_END',
  /** Vrácení priority na homepage */
  TypeHomepagePriorityDecrease = 'TYPE_HOMEPAGE_PRIORITY_DECREASE',
  /** Vyšší priorita na homepage */
  TypeHomepagePriorityIncrease = 'TYPE_HOMEPAGE_PRIORITY_INCREASE',
  /** Topování */
  TypeOrder = 'TYPE_ORDER',
  /** Vrácení priority řazení */
  TypeOrderPriorityDecrease = 'TYPE_ORDER_PRIORITY_DECREASE',
  /** Vyšší priorita v řazení */
  TypeOrderPriorityIncrease = 'TYPE_ORDER_PRIORITY_INCREASE',
  /** Vrácení priority v podobných inzerátech */
  TypeOrderRelatedPriorityDecrease = 'TYPE_ORDER_RELATED_PRIORITY_DECREASE',
  /** Vyšší priorita v podobných inzerátech */
  TypeOrderRelatedPriorityIncrease = 'TYPE_ORDER_RELATED_PRIORITY_INCREASE',
  /** Premium účet */
  TypePremiumAccount = 'TYPE_PREMIUM_ACCOUNT',
  /** Správa nájmu - aktivace */
  TypeRentPlatform = 'TYPE_RENT_PLATFORM',
  /** Trial */
  TypeTrial = 'TYPE_TRIAL'
}

export type AdvertFilterOptions = {
  __typename?: 'AdvertFilterOptions';
  estateType?: Maybe<Array<Maybe<EstateType>>>;
  offerType?: Maybe<Array<Maybe<OfferType>>>;
  visible?: Maybe<Array<Maybe<Scalars['Boolean']['output']>>>;
};

export enum AdvertImportType {
  /** Beyvák */
  Beyvak = 'BEYVAK',
  /** Bureš */
  Bures = 'BURES',
  /** Comfort */
  Comfort = 'COMFORT',
  /** Coolpeople */
  Coolpeople = 'COOLPEOPLE',
  /** CS Chalupy */
  CsChalupy = 'CS_CHALUPY',
  /** Dokonalý Nájemník */
  DokonalyNajemnik = 'DOKONALY_NAJEMNIK',
  /** Doma je doma */
  DomaJeDoma = 'DOMA_JE_DOMA',
  /** E-Chalupy */
  EChalupy = 'E_CHALUPY',
  /** Flatio */
  Flatio = 'FLATIO',
  /** Heimstaden */
  Heimstaden = 'HEIMSTADEN',
  /** iDeveloper */
  Ideveloper = 'IDEVELOPER',
  /** investujDoPole */
  InvestujDoPole = 'INVESTUJ_DO_POLE',
  /** Manual Bezrealitky - majitel - prodej */
  ManualBzrMajitelProdej = 'MANUAL_BZR_MAJITEL_PRODEJ',
  /** Manual Bezrealitky - majitel - pronájem */
  ManualBzrMajitelPronajem = 'MANUAL_BZR_MAJITEL_PRONAJEM',
  /** Manual Bezrealitky - RK - prodej */
  ManualBzrRkProdej = 'MANUAL_BZR_RK_PRODEJ',
  /** Manual Bezrealitky - RK - pronájem */
  ManualBzrRkPronajem = 'MANUAL_BZR_RK_PRONAJEM',
  /** Manual Bezrealitky - správce - prodej */
  ManualBzrSpravceProdej = 'MANUAL_BZR_SPRAVCE_PRODEJ',
  /** Manual Bezrealitky - správce - pronájem */
  ManualBzrSpravcePronajem = 'MANUAL_BZR_SPRAVCE_PRONAJEM',
  /** Maxima */
  Maxima = 'MAXIMA',
  /**
   * MM Rality
   * @deprecated To be removed
   */
  Mm = 'MM',
  /** Ohnemakler */
  Ohnemakler = 'OHNEMAKLER',
  /** Prague office */
  PragueOffice = 'PRAGUE_OFFICE',
  /** Stálý nájem */
  StalyNajem = 'STALY_NAJEM',
  /** TvůjSprávce */
  TvujSpravce = 'TVUJ_SPRAVCE',
  /** Not imported */
  Undefined = 'UNDEFINED'
}

export type AdvertLead = {
  __typename?: 'AdvertLead';
  /** Advert ID */
  advertId?: Maybe<Scalars['ID']['output']>;
  /** Email */
  email?: Maybe<Scalars['String']['output']>;
  /** Name */
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  /** Name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Message */
  message?: Maybe<Scalars['String']['output']>;
  /** Phone */
  phone?: Maybe<Scalars['String']['output']>;
};

export type AdvertLeadInput = {
  /** Advert ID */
  advertId: Scalars['ID']['input'];
  /** Email */
  email: Scalars['String']['input'];
  /** Name */
  firstName: Scalars['String']['input'];
  /** Name */
  lastName: Scalars['String']['input'];
  /** Message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Request Ownest pricing */
  ownest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Phone */
  phone: Scalars['String']['input'];
  /** Proposed price */
  price?: InputMaybe<Scalars['Int']['input']>;
  /** Recaptcha token */
  recaptcha: Scalars['String']['input'];
};

export type AdvertLink = {
  __typename?: 'AdvertLink';
  advert?: Maybe<Advert>;
  id?: Maybe<Scalars['ID']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<AdvertLinkStatus>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<AdvertLinkType>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum AdvertLinkStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum AdvertLinkType {
  /** 360 prohlídka */
  Tour_360 = 'TOUR_360',
  /** Video */
  Video = 'VIDEO'
}

export type AdvertList = {
  __typename?: 'AdvertList';
  envelopePolygon?: Maybe<GpsPolygon>;
  list?: Maybe<Array<Maybe<Advert>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** Advert object to be used as map marker */
export type AdvertMarker = {
  __typename?: 'AdvertMarker';
  /** Generated address from region data */
  address?: Maybe<Scalars['String']['output']>;
  balconySurface?: Maybe<Scalars['Float']['output']>;
  barrierFree?: Maybe<Scalars['Boolean']['output']>;
  cellarSurface?: Maybe<Scalars['Float']['output']>;
  /**
   * Sum of service and utility charges
   *
   */
  charges?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Currency>;
  deposit?: Maybe<Scalars['Int']['output']>;
  disposition?: Maybe<Disposition>;
  estateType?: Maybe<EstateType>;
  fee?: Maybe<Scalars['Int']['output']>;
  gps?: Maybe<GpsPoint>;
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
  loggiaSurface?: Maybe<Scalars['Float']['output']>;
  lowEnergy?: Maybe<Scalars['Boolean']['output']>;
  /** Main image */
  mainImage?: Maybe<Scalars['String']['output']>;
  offerType?: Maybe<OfferType>;
  petFriendly?: Maybe<Scalars['Boolean']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  roommate?: Maybe<Scalars['Boolean']['output']>;
  serviceCharges?: Maybe<Scalars['Int']['output']>;
  surface?: Maybe<Scalars['Int']['output']>;
  surfaceLand?: Maybe<Scalars['Int']['output']>;
  terraceSurface?: Maybe<Scalars['Float']['output']>;
  /** Time of last top */
  timeOrder?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<AdvertImportType>;
  /** URI where advert is available */
  uri?: Maybe<Scalars['String']['output']>;
  utilityCharges?: Maybe<Scalars['Int']['output']>;
};

export type AdvertMessageData = {
  __typename?: 'AdvertMessageData';
  messageType?: Maybe<AdvertMessageType>;
  requireLogin?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum AdvertMessageType {
  /** Advert is not active */
  Inactive = 'INACTIVE',
  /** Send reaction as service lead */
  Lead = 'LEAD',
  /** Lead has already been sent */
  LeadSent = 'LEAD_SENT',
  /** Instead of sending a message, redirect user to given link */
  Link = 'LINK',
  /** Normal message */
  Message = 'MESSAGE',
  /** Message has already been sent */
  MessageSent = 'MESSAGE_SENT',
  /** Logged user is the owner of this advert */
  Owner = 'OWNER',
  /** Login with premium account is required */
  RequirePremium = 'REQUIRE_PREMIUM'
}

export type AdvertParameter = {
  __typename?: 'AdvertParameter';
  key?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum AdvertParameterName {
  /** Age */
  Age = 'AGE',
  /** Condition */
  Condition = 'CONDITION',
  /** Construction */
  Construction = 'CONSTRUCTION',
  /** Disposition */
  Disposition = 'DISPOSITION',
  /** Education */
  Education = 'EDUCATION',
  /** Equipped */
  Equipped = 'EQUIPPED',
  /** Estate type */
  EstateType = 'ESTATE_TYPE',
  /** Execution */
  Execution = 'EXECUTION',
  /** Floor */
  Floor = 'FLOOR',
  /** Heating */
  Heating = 'HEATING',
  /** Land type */
  LandType = 'LAND_TYPE',
  /** Advert offer type */
  OfferType = 'OFFER_TYPE',
  /** Ownership */
  Ownership = 'OWNERSHIP',
  /** PENB */
  Penb = 'PENB',
  /** Reconstruction */
  Reconstruction = 'RECONSTRUCTION',
  /** Rent platform advert status */
  RentPlatformAdvertStatus = 'RENT_PLATFORM_ADVERT_STATUS',
  /** Rent platform contract status */
  RentPlatformContractStatus = 'RENT_PLATFORM_CONTRACT_STATUS',
  /** Rent platform cost frequency */
  RentPlatformCostFrequency = 'RENT_PLATFORM_COST_FREQUENCY',
  /** Rent platform payment method */
  RentPlatformPaymentMethod = 'RENT_PLATFORM_PAYMENT_METHOD',
  /** Rent platform payment status */
  RentPlatformPaymentStatus = 'RENT_PLATFORM_PAYMENT_STATUS',
  /** Rent platform payment type */
  RentPlatformPaymentType = 'RENT_PLATFORM_PAYMENT_TYPE',
  /** Rent platform repair payer */
  RentPlatformRepairPayer = 'RENT_PLATFORM_REPAIR_PAYER',
  /** Rent platform service payer */
  RentPlatformServicePayer = 'RENT_PLATFORM_SERVICE_PAYER',
  /** Rent platform service type */
  RentPlatformServiceType = 'RENT_PLATFORM_SERVICE_TYPE',
  /** Rent platform tenant status */
  RentPlatformTenantStatus = 'RENT_PLATFORM_TENANT_STATUS',
  /** Sewage */
  Sewage = 'SEWAGE',
  /** Water */
  Water = 'WATER'
}

export type AdvertPriceBid = {
  __typename?: 'AdvertPriceBid';
  conversation?: Maybe<Conversation>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['ID']['output']>;
  offeredPrice?: Maybe<Scalars['Int']['output']>;
  originalAdvertPrice?: Maybe<Scalars['Int']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
};

export type AdvertPriceBidList = {
  __typename?: 'AdvertPriceBidList';
  list: Array<AdvertPriceBid>;
  totalCount: Scalars['Int']['output'];
};

export type AdvertPriceEstimation = {
  __typename?: 'AdvertPriceEstimation';
  currency?: Maybe<Currency>;
  price?: Maybe<Scalars['Int']['output']>;
  priceMax?: Maybe<Scalars['Int']['output']>;
  priceMin?: Maybe<Scalars['Int']['output']>;
  relatedAdverts?: Maybe<AdvertList>;
  relatedAdvertsExpired?: Maybe<AdvertList>;
};

export type AdvertPriceEstimationHistory = {
  __typename?: 'AdvertPriceEstimationHistory';
  currency?: Maybe<Currency>;
  id: Scalars['ID']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
};

export type AdvertPriceRange = {
  __typename?: 'AdvertPriceRange';
  estateType?: Maybe<EstateType>;
  max?: Maybe<Scalars['Int']['output']>;
  min?: Maybe<Scalars['Int']['output']>;
  offerType?: Maybe<OfferType>;
};

export type AdvertResponseInput = {
  advertId: Scalars['ID']['input'];
  /** Offered price */
  offeredPrice?: InputMaybe<Scalars['Int']['input']>;
  /** Preferred inspection date interval */
  preferredInspectionTime?: InputMaybe<CalendarEntryInterval>;
};

export type AdvertStatsDaily = {
  __typename?: 'AdvertStatsDaily';
  contactCounter?: Maybe<Scalars['Int']['output']>;
  day?: Maybe<Scalars['DateTime']['output']>;
  viewCounter?: Maybe<Scalars['Int']['output']>;
};

export enum AdvertType {
  Offer = 'OFFER',
  Request = 'REQUEST'
}

export type AdvertUserVisit = {
  __typename?: 'AdvertUserVisit';
  advert?: Maybe<Advert>;
  date?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  user?: Maybe<User>;
};

/**
 * Object containing user's relation to advert. P.e.: Favourite
 *
 */
export type AdvertUserstate = {
  __typename?: 'AdvertUserstate';
  advert?: Maybe<Advert>;
  id?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  state?: Maybe<AdvertUserstateState>;
  user?: Maybe<User>;
};

export type AdvertUserstateList = {
  __typename?: 'AdvertUserstateList';
  list?: Maybe<Array<Maybe<AdvertUserstate>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum AdvertUserstateState {
  Favourite = 'FAVOURITE'
}

export type AdvertVideo = {
  __typename?: 'AdvertVideo';
  advert?: Maybe<Advert>;
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  previewFilename?: Maybe<Scalars['String']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AdvertVideoStatus>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum AdvertVideoStatus {
  Failed = 'FAILED',
  New = 'NEW',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING'
}

export enum Age {
  /** 1 - 10 */
  Between_1_10 = 'BETWEEN_1_10',
  /** 10 - 30 */
  Between_10_30 = 'BETWEEN_10_30',
  /** 30 - 50 */
  Between_30_50 = 'BETWEEN_30_50',
  /** více než 50 */
  Over_50 = 'OVER_50',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export type ApplePaySession = {
  __typename?: 'ApplePaySession';
  displayName?: Maybe<Scalars['String']['output']>;
  domainName?: Maybe<Scalars['String']['output']>;
  epochTimestamp?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['String']['output']>;
  merchantIdentifier?: Maybe<Scalars['String']['output']>;
  merchantSessionIdentifier?: Maybe<Scalars['String']['output']>;
  nonce?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Scalars['String']['output']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  originalFilename?: Maybe<Scalars['String']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<MessageAttachmentType>;
  url?: Maybe<Scalars['String']['output']>;
};

export type AuthData = {
  __typename?: 'AuthData';
  accountType?: Maybe<AccountType>;
  errors?: Maybe<Array<Maybe<FormError>>>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type AvailablePaymentMethods = {
  __typename?: 'AvailablePaymentMethods';
  cardTokens?: Maybe<Array<Maybe<PayuCardToken>>>;
  merchantId?: Maybe<Scalars['ID']['output']>;
  payByLinks?: Maybe<Array<Maybe<PayuPayByLink>>>;
  posIdRest?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<PayUResponseStatus>;
};

export type BlogArticle = {
  __typename?: 'BlogArticle';
  author: BlogAuthor;
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  indexed: Scalars['Boolean']['output'];
  localeSlugs: Array<LocaleSlug>;
  mainImage?: Maybe<SimpleImage>;
  metaTags?: Maybe<BlogArticleMetaTags>;
  perex?: Maybe<Scalars['String']['output']>;
  published: Scalars['Boolean']['output'];
  readingTime: Scalars['Int']['output'];
  slug: Scalars['String']['output'];
  tags: Array<BlogTag>;
  timeCreated: Scalars['DateTime']['output'];
  timePublished?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated: Scalars['DateTime']['output'];
  title: Scalars['String']['output'];
  visitCount: Scalars['Int']['output'];
};


export type BlogArticleContentArgs = {
  locale: Locale;
};


export type BlogArticleIndexedArgs = {
  locale: Locale;
};


export type BlogArticleMainImageArgs = {
  locale: Locale;
};


export type BlogArticleMetaTagsArgs = {
  locale: Locale;
};


export type BlogArticlePerexArgs = {
  locale: Locale;
};


export type BlogArticleReadingTimeArgs = {
  locale: Locale;
};


export type BlogArticleSlugArgs = {
  locale: Locale;
};


export type BlogArticleTitleArgs = {
  locale: Locale;
};


export type BlogArticleVisitCountArgs = {
  locale: Locale;
};

export type BlogArticleList = {
  __typename?: 'BlogArticleList';
  list: Array<BlogArticle>;
  totalCount: Scalars['Int']['output'];
};

export type BlogArticleMetaTags = {
  __typename?: 'BlogArticleMetaTags';
  metaDescription?: Maybe<Scalars['String']['output']>;
  /** @deprecated To be removed */
  metaKeywords?: Maybe<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  ogDescription?: Maybe<Scalars['String']['output']>;
  ogImage?: Maybe<Scalars['String']['output']>;
  ogTitle?: Maybe<Scalars['String']['output']>;
};

export enum BlogArticleOrder {
  /** Order by time_published ASC */
  TimePublishedAsc = 'TIME_PUBLISHED_ASC',
  /** Order by time_published DESC */
  TimePublishedDesc = 'TIME_PUBLISHED_DESC',
  /** Order by visit_count ASC */
  VisitCountAsc = 'VISIT_COUNT_ASC',
  /** Order by visit_count DESC */
  VisitCountDesc = 'VISIT_COUNT_DESC'
}

export type BlogAuthor = {
  __typename?: 'BlogAuthor';
  articles: BlogArticleList;
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  linkedIn?: Maybe<Scalars['String']['output']>;
  localeSlugs: Array<LocaleSlug>;
  perex?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<SimpleImage>;
  published: Scalars['Boolean']['output'];
  role: BlogAuthorRole;
  slug: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
};


export type BlogAuthorArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: Locale;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BlogArticleOrder>;
};


export type BlogAuthorPerexArgs = {
  locale: Locale;
};


export type BlogAuthorPhotoArgs = {
  filter?: InputMaybe<ImageFilter>;
  locale: Locale;
};


export type BlogAuthorTextArgs = {
  locale: Locale;
};

export type BlogAuthorList = {
  __typename?: 'BlogAuthorList';
  list: Array<BlogAuthor>;
  totalCount: Scalars['Int']['output'];
};

export enum BlogAuthorRole {
  /** Administrátor */
  Admin = 'ADMIN',
  /** Autor */
  Author = 'AUTHOR',
  /** Redaktor */
  Redactor = 'REDACTOR'
}

export type BlogTag = {
  __typename?: 'BlogTag';
  active: Scalars['Boolean']['output'];
  articles: BlogArticleList;
  color?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  localeSlugs: Array<LocaleSlug>;
  mainImageDesktop?: Maybe<SimpleImage>;
  mainImageMobile?: Maybe<SimpleImage>;
  mainImageTablet?: Maybe<SimpleImage>;
  metaTags?: Maybe<BlogTagMetaTags>;
  order?: Maybe<Scalars['Int']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type?: Maybe<BlogTagType>;
};


export type BlogTagArticlesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: Locale;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BlogArticleOrder>;
};


export type BlogTagContentArgs = {
  locale: Locale;
};


export type BlogTagDescriptionArgs = {
  locale: Locale;
};


export type BlogTagMainImageDesktopArgs = {
  locale: Locale;
};


export type BlogTagMainImageMobileArgs = {
  locale: Locale;
};


export type BlogTagMainImageTabletArgs = {
  locale: Locale;
};


export type BlogTagMetaTagsArgs = {
  locale: Locale;
};


export type BlogTagSlugArgs = {
  locale: Locale;
};


export type BlogTagTitleArgs = {
  locale: Locale;
};

export type BlogTagList = {
  __typename?: 'BlogTagList';
  list: Array<BlogTag>;
  totalCount: Scalars['Int']['output'];
};

export type BlogTagMetaTags = {
  __typename?: 'BlogTagMetaTags';
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
};

export enum BlogTagType {
  /** Kategorie */
  Category = 'CATEGORY',
  /** Štítek */
  Tag = 'TAG'
}

export type BreadcrumbItem = {
  __typename?: 'BreadcrumbItem';
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum BrizRoomType {
  /** Koupelna */
  Bathroom = 'BATHROOM',
  /** Ložnice */
  Bedroom = 'BEDROOM',
  /** Dětský pokoj */
  ChildrenRoom = 'CHILDREN_ROOM',
  /** Zahrada */
  Garden = 'GARDEN',
  /** Chodba */
  Hallway = 'HALLWAY',
  /** Dům - exteriér */
  HouseExterior = 'HOUSE_EXTERIOR',
  /** Kuchyně */
  Kitchen = 'KITCHEN',
  /** Kuchyně a jídelna */
  KitchenDiningRoom = 'KITCHEN_DINING_ROOM',
  /** Kuchyně a obývací pokoj */
  KitchenLivingRoom = 'KITCHEN_LIVING_ROOM',
  /** Obývací pokoj */
  LivingRoom = 'LIVING_ROOM',
  /** - Bez výběru prostoru - */
  None = 'NONE',
  /** Kancelář */
  Office = 'OFFICE',
  /** Vinný sklep */
  WineCellar = 'WINE_CELLAR'
}

export enum BrizStyle {
  /** Boho styl */
  BohoStyle = 'BOHO_STYLE',
  /** Vánoce */
  Christmas = 'CHRISTMAS',
  /** Současný */
  Current = 'CURRENT',
  /** Velikonoce */
  Easter = 'EASTER',
  /** Francouzská vesnice */
  FrenchCountryside = 'FRENCH_COUNTRYSIDE',
  /** Halloween */
  Halloween = 'HALLOWEEN',
  /** Industriální */
  Industrial = 'INDUSTRIAL',
  /** Japonský design */
  JapaneseDesign = 'JAPANESE_DESIGN',
  /** Středomořský */
  Mediterranean = 'MEDITERRANEAN',
  /** Minimalistický */
  Minimalistic = 'MINIMALISTIC',
  /** Moderní */
  Modern = 'MODERN',
  /** - Bez výběru stylu - */
  None = 'NONE',
  /** Rustikální */
  Rustic = 'RUSTIC',
  /** Skandinávský */
  Scandinavian = 'SCANDINAVIAN',
  /** Tropický */
  Tropical = 'TROPICAL',
  /** Urban Jungle */
  UrbanJungle = 'URBAN_JUNGLE',
  /** Valentýn */
  Valentine = 'VALENTINE'
}

export type Broker = {
  __typename?: 'Broker';
  email?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['ID']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  photoFilename?: Maybe<Scalars['String']['output']>;
};


export type BrokerPhotoArgs = {
  filter: ImageFilter;
};

export type BrokerList = {
  __typename?: 'BrokerList';
  list?: Maybe<Array<Maybe<Broker>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CalendarEntry = {
  __typename?: 'CalendarEntry';
  advert?: Maybe<Advert>;
  calendarEvents: Array<CalendarEvent>;
  id?: Maybe<Scalars['ID']['output']>;
  sellerNote?: Maybe<Scalars['String']['output']>;
  timeFrom?: Maybe<Scalars['DateTime']['output']>;
  timeTo?: Maybe<Scalars['DateTime']['output']>;
};

export type CalendarEntryCustomer = {
  __typename?: 'CalendarEntryCustomer';
  conversation: Conversation;
  id: Scalars['ID']['output'];
  timeFrom: Scalars['DateTime']['output'];
  timeTo: Scalars['DateTime']['output'];
};

export type CalendarEntryCustomerList = {
  __typename?: 'CalendarEntryCustomerList';
  list: Array<CalendarEntryCustomer>;
  totalCount: Scalars['Int']['output'];
};

export type CalendarEntryInterval = {
  timeFrom: Scalars['DateTime']['input'];
  timeTo: Scalars['DateTime']['input'];
};

export type CalendarEntryList = {
  __typename?: 'CalendarEntryList';
  list: Array<CalendarEntry>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  calendarEntry?: Maybe<CalendarEntry>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  conversation?: Maybe<Conversation>;
  customerNote?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  sellerNote?: Maybe<Scalars['String']['output']>;
  visitor?: Maybe<User>;
};

export type CalendarEventList = {
  __typename?: 'CalendarEventList';
  list?: Maybe<Array<Maybe<CalendarEvent>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum CalendarEventRole {
  Owner = 'OWNER',
  Visitor = 'VISITOR'
}

export enum Condition {
  /** Po rekonstrukci */
  AfterReconstruction = 'AFTER_RECONSTRUCTION',
  /** Špatný */
  Bad = 'BAD',
  /** Před rekonstrukcí */
  BeforeReconstruction = 'BEFORE_RECONSTRUCTION',
  /** Ve výstavbě */
  Construction = 'CONSTRUCTION',
  /** K demolici */
  Demolition = 'DEMOLITION',
  /** Dobrý */
  Good = 'GOOD',
  /** V rekonstrukci */
  InReconstruction = 'IN_RECONSTRUCTION',
  /** Novostavba */
  New = 'NEW',
  /** Projekt */
  Project = 'PROJECT',
  /** Nevyplněno */
  Undefined = 'UNDEFINED',
  /** Velmi dobrý */
  VeryGood = 'VERY_GOOD'
}

export enum Construction {
  /** Cihlová */
  Brick = 'BRICK',
  /**
   * Nízkoenergetická
   * @deprecated To be removed, MIXED used instead
   */
  LowEnergy = 'LOW_ENERGY',
  /** Smíšená */
  Mixed = 'MIXED',
  /**
   * Ostatní
   * @deprecated To be removed, MIXED used instead
   */
  Other = 'OTHER',
  /** Panel */
  Panel = 'PANEL',
  /** Montovaná */
  Prefab = 'PREFAB',
  /** Skeletová */
  Skelet = 'SKELET',
  /** Kamenná */
  Stone = 'STONE',
  /** Nevyplněno */
  Undefined = 'UNDEFINED',
  /** Dřevostavba */
  Wood = 'WOOD'
}

export type Conversation = {
  __typename?: 'Conversation';
  advert?: Maybe<Advert>;
  advertPriceBids: Array<AdvertPriceBid>;
  bidStatus: ConversationBidStatus;
  calendarEntriesCustomer: Array<CalendarEntryCustomer>;
  /** @deprecated Will be removed, use calendarEvents instead */
  calendarEvent?: Maybe<CalendarEvent>;
  calendarEvents: Array<CalendarEvent>;
  calendarShared?: Maybe<Scalars['Boolean']['output']>;
  customer?: Maybe<User>;
  customerCheckStatus?: Maybe<ConversationCheckStatus>;
  customerInsuranceStatus?: Maybe<ConversationInsuranceStatus>;
  customerNote?: Maybe<Scalars['String']['output']>;
  customerStatus?: Maybe<ConversationStatus>;
  firstAdvertPriceBid?: Maybe<AdvertPriceBid>;
  id?: Maybe<Scalars['ID']['output']>;
  important?: Maybe<Scalars['Boolean']['output']>;
  importantCustomer?: Maybe<Scalars['Boolean']['output']>;
  importantSeller?: Maybe<Scalars['Boolean']['output']>;
  lastAdvertPriceBid?: Maybe<AdvertPriceBid>;
  lockedReason?: Maybe<ConversationLockedReason>;
  messages?: Maybe<MessageList>;
  ownerAnswered?: Maybe<Scalars['Boolean']['output']>;
  pinned: Scalars['Boolean']['output'];
  rank: Scalars['Int']['output'];
  seller?: Maybe<User>;
  sellerNote?: Maybe<Scalars['String']['output']>;
  sellerStatus?: Maybe<ConversationStatus>;
  tagsAssigned?: Maybe<Array<Maybe<ConversationTag>>>;
  tagsAvailable?: Maybe<Array<Maybe<ConversationTag>>>;
  userCheckStatus?: Maybe<ConversationCheckStatus>;
};


export type ConversationMessagesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum ConversationBidStatus {
  /**
   * Bidding is active
   * @deprecated To be removed
   */
  Active = 'ACTIVE',
  /** Calendar event exists */
  CalendarEventActive = 'CALENDAR_EVENT_ACTIVE',
  /** New calendar entry is to be confirmed by seller */
  CalendarEventToBeConfirmed = 'CALENDAR_EVENT_TO_BE_CONFIRMED',
  /** Seller shared the calendar */
  CalendarShared = 'CALENDAR_SHARED',
  /** Conversation is not active anymore */
  Inactive = 'INACTIVE',
  /** New bid */
  New = 'NEW',
  /** No bidding */
  None = 'NONE',
  /**
   * Bidding was refused
   * @deprecated To be removed. Use REFUSED_BY_OWNER instead
   */
  Refused = 'REFUSED',
  /** Conversation was refused by customer */
  RefusedByCustomer = 'REFUSED_BY_CUSTOMER',
  /** Conversation was refused by owner */
  RefusedByOwner = 'REFUSED_BY_OWNER',
  /** Last message is from seller */
  WaitingForCustomerResponse = 'WAITING_FOR_CUSTOMER_RESPONSE',
  /** Customer has sent at least 2 messages in total */
  WaitingForSellerResponse = 'WAITING_FOR_SELLER_RESPONSE'
}

export enum ConversationCheckStatus {
  /** Customer accepted the request */
  Accepted = 'ACCEPTED',
  /** Customer is checked */
  Checked = 'CHECKED',
  /** Customer declined the request */
  Declined = 'DECLINED',
  /** Customer is not checked and no request by seller was sent (default) */
  Initial = 'INITIAL',
  /** The seller has requested the customer to be checked */
  Requested = 'REQUESTED'
}

export enum ConversationInsuranceStatus {
  /** Customer accepted the request */
  Accepted = 'ACCEPTED',
  /** Customer declined the request */
  Declined = 'DECLINED',
  /** Customer is not insured and no request by seller was sent (default) */
  Initial = 'INITIAL',
  /** Customer is insured */
  Insured = 'INSURED',
  /** The seller has requested the customer to take out insurance */
  Requested = 'REQUESTED'
}

export type ConversationList = {
  __typename?: 'ConversationList';
  list?: Maybe<Array<Maybe<Conversation>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum ConversationLockedReason {
  /** Inzerát byl deaktivován */
  AdvertDeactivated = 'ADVERT_DEACTIVATED',
  /** Uzavřeno uživatelem */
  ClosedByUser = 'CLOSED_BY_USER',
  /** Uživatel již nepoužívá Bezrealitky */
  UserRemoved = 'USER_REMOVED'
}

export enum ConversationOrder {
  /** Order by calendar event */
  CalendarEvent = 'CALENDAR_EVENT',
  /** Order by creditCheck */
  Creditcheck = 'CREDITCHECK',
  /** Order by highest bid */
  HighestBid = 'HIGHEST_BID',
  /** Order by newest message */
  NewestMessage = 'NEWEST_MESSAGE',
  /** Order by rank */
  Rank = 'RANK'
}

export enum ConversationRole {
  Customer = 'CUSTOMER',
  Seller = 'SELLER'
}

export enum ConversationStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Deleted = 'DELETED',
  Locked = 'LOCKED'
}

export type ConversationTag = {
  __typename?: 'ConversationTag';
  id?: Maybe<Scalars['ID']['output']>;
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  style?: Maybe<ConversationTagStyle>;
  title?: Maybe<Scalars['ID']['output']>;
  user?: Maybe<User>;
};

export enum ConversationTagStyle {
  /** Barva 1 */
  Color_1 = 'COLOR_1',
  /** Barva 2 */
  Color_2 = 'COLOR_2',
  /** Barva 3 */
  Color_3 = 'COLOR_3',
  /** Barva 4 */
  Color_4 = 'COLOR_4',
  /** Barva 5 */
  Color_5 = 'COLOR_5',
  /** Zelená */
  Green = 'GREEN',
  /** Oranžová */
  Orange = 'ORANGE',
  /** Fialová */
  Purple = 'PURPLE',
  /** Červená */
  Red = 'RED',
  /** Bílá */
  White = 'WHITE'
}

export enum Country {
  /** Austria */
  Aut = 'AUT',
  /** Czechia */
  Cze = 'CZE',
  /** Germany */
  Deu = 'DEU',
  /** Slovakia */
  Svk = 'SVK'
}

export type CreateAdvertPriceBidInput = {
  /** Advert ID */
  advertId: Scalars['ID']['input'];
  /** Offered price */
  offeredPrice: Scalars['Int']['input'];
};

export type CreditCheckAsServiceInput = {
  /** Date of birth */
  birthNumber?: InputMaybe<Scalars['String']['input']>;
  /** City name */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Date of birth */
  dateBirth?: InputMaybe<Scalars['String']['input']>;
  /** DIČ */
  dic?: InputMaybe<Scalars['String']['input']>;
  /** First name */
  firstName: Scalars['String']['input'];
  /** House number */
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  /** IČ */
  ic?: InputMaybe<Scalars['String']['input']>;
  /** Číslo dokladu */
  idNumber?: InputMaybe<Scalars['String']['input']>;
  /** Typ dokladu */
  idType?: InputMaybe<CreditcheckIdType>;
  /** Last name */
  lastName: Scalars['String']['input'];
  /** Locale */
  locale: Locale;
  /** OrderPackage ID containing creditcheck service */
  orderPackageId: Scalars['ID']['input'];
  /** Ruian ID */
  ruianId?: InputMaybe<Scalars['String']['input']>;
  /** Street name */
  street?: InputMaybe<Scalars['String']['input']>;
  /** Street number */
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreditCheckForUserInput = {
  /** Birth number */
  birthNumber?: InputMaybe<Scalars['String']['input']>;
  /** City name */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Date of birth */
  dateBirth?: InputMaybe<Scalars['String']['input']>;
  /** DIČ */
  dic?: InputMaybe<Scalars['String']['input']>;
  /** First name */
  firstName: Scalars['String']['input'];
  /** House number */
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  /** IČ */
  ic?: InputMaybe<Scalars['String']['input']>;
  /** Číslo dokladu */
  idNumber?: InputMaybe<Scalars['String']['input']>;
  /** Typ dokladu */
  idType?: InputMaybe<CreditcheckIdType>;
  /** Last name */
  lastName: Scalars['String']['input'];
  /** Locale */
  locale: Locale;
  /** Ruian ID */
  ruianId?: InputMaybe<Scalars['String']['input']>;
  /** Street name */
  street?: InputMaybe<Scalars['String']['input']>;
  /** Street number */
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

export type Creditcheck = {
  __typename?: 'Creditcheck';
  addressMatch?: Maybe<CreditcheckStatus>;
  bankruptcy?: Maybe<CreditcheckStatus>;
  checkStatus?: Maybe<CreditcheckCheckStatus>;
  debtSp?: Maybe<CreditcheckStatus>;
  debtUnion?: Maybe<CreditcheckStatus>;
  debtVzp?: Maybe<CreditcheckStatus>;
  dph?: Maybe<CreditcheckStatus>;
  executionEvidence?: Maybe<CreditcheckStatus>;
  executionSkov?: Maybe<CreditcheckStatus>;
  hledaniPcr?: Maybe<CreditcheckStatus>;
  id?: Maybe<Scalars['ID']['output']>;
  idValid?: Maybe<CreditcheckStatus>;
  insolvencyRegister?: Maybe<CreditcheckStatus>;
  locale?: Maybe<Locale>;
  reportUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated To be removed */
  requestBirthNumber?: Maybe<Scalars['String']['output']>;
  requestCity?: Maybe<Scalars['String']['output']>;
  requestDateBirth?: Maybe<Scalars['String']['output']>;
  requestDic?: Maybe<Scalars['String']['output']>;
  requestFirstName?: Maybe<Scalars['String']['output']>;
  requestHouseNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated To be removed */
  requestIdNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated To be removed */
  requestIdType?: Maybe<CreditcheckIdType>;
  requestLastName?: Maybe<Scalars['String']['output']>;
  requestLocale: Locale;
  requestStreet?: Maybe<Scalars['String']['output']>;
  requestStreetNumber?: Maybe<Scalars['String']['output']>;
  requestZip?: Maybe<Scalars['String']['output']>;
  rnDb?: Maybe<CreditcheckStatus>;
  skDovera?: Maybe<CreditcheckStatus>;
  skUrad?: Maybe<CreditcheckStatus>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  vzpDebtors?: Maybe<CreditcheckStatus>;
  zpmvcrDebtors?: Maybe<CreditcheckStatus>;
};

export enum CreditcheckCheckStatus {
  /** Ověřený se záznamem */
  CheckedNotOk = 'CHECKED_NOT_OK',
  /** Ověřený bez záznamu */
  CheckedOk = 'CHECKED_OK',
  /** Částečně prověřen (chybějící data) */
  CheckedPartly = 'CHECKED_PARTLY',
  /** Částečně prověřen (chybějící data) + nalezen záznam */
  CheckedPartlyNotOk = 'CHECKED_PARTLY_NOT_OK',
  /** Neověřený */
  NotChecked = 'NOT_CHECKED'
}

export enum CreditcheckIdType {
  /** Občanský průkaz */
  Id = 'ID',
  /** Pas */
  Pass = 'PASS',
  /** Nezadáno */
  Undefined = 'UNDEFINED'
}

export enum CreditcheckStatus {
  /** Fail */
  Fail = 'FAIL',
  /** OK */
  Ok = 'OK'
}

export enum Currency {
  /** CZK */
  Czk = 'CZK',
  /** EUR */
  Eur = 'EUR'
}

export enum DateIntervalEnum {
  /** 1 hour */
  Interval_1Day = 'INTERVAL_1_DAY',
  /** 1 hour */
  Interval_1Hour = 'INTERVAL_1_HOUR',
  /** None */
  None = 'NONE'
}

export enum DeactivationAction {
  /** Deaktivace */
  Deactivate = 'DEACTIVATE',
  /** Smazání */
  Delete = 'DELETE',
  /** Smazání neaktivního inzerátu */
  DeleteInactive = 'DELETE_INACTIVE'
}

export type DescriptionCheckResult = {
  __typename?: 'DescriptionCheckResult';
  containsEmail: Scalars['Boolean']['output'];
  containsPhone: Scalars['Boolean']['output'];
  newDescription: Scalars['String']['output'];
};

export enum Disposition {
  /** 1+1 */
  Disp_1_1 = 'DISP_1_1',
  /** 1-izbový */
  Disp_1Izb = 'DISP_1_IZB',
  /** 1+kk */
  Disp_1Kk = 'DISP_1_KK',
  /** 2+1 */
  Disp_2_1 = 'DISP_2_1',
  /** 2-izbový */
  Disp_2Izb = 'DISP_2_IZB',
  /** 2+kk */
  Disp_2Kk = 'DISP_2_KK',
  /** 3+1 */
  Disp_3_1 = 'DISP_3_1',
  /** 3-izbový */
  Disp_3Izb = 'DISP_3_IZB',
  /** 3+kk */
  Disp_3Kk = 'DISP_3_KK',
  /** 4+1 */
  Disp_4_1 = 'DISP_4_1',
  /** 4-izbový */
  Disp_4Izb = 'DISP_4_IZB',
  /** 4+kk */
  Disp_4Kk = 'DISP_4_KK',
  /** 5+1 */
  Disp_5_1 = 'DISP_5_1',
  /** 5-izbový */
  Disp_5Izb = 'DISP_5_IZB',
  /** 5+kk */
  Disp_5Kk = 'DISP_5_KK',
  /** 6+1 */
  Disp_6_1 = 'DISP_6_1',
  /** 6-izbový */
  Disp_6Izb = 'DISP_6_IZB',
  /** 6+kk */
  Disp_6Kk = 'DISP_6_KK',
  /** 7+1 */
  Disp_7_1 = 'DISP_7_1',
  /** 7-izbový */
  Disp_7Izb = 'DISP_7_IZB',
  /** 7+kk */
  Disp_7Kk = 'DISP_7_KK',
  /** Garsoniéra */
  Garsoniera = 'GARSONIERA',
  /** Ostatní */
  Ostatni = 'OSTATNI',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export type Document = {
  __typename?: 'Document';
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  reference?: Maybe<DocumentReference>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
};

export type DocumentList = {
  __typename?: 'DocumentList';
  list?: Maybe<Array<Maybe<Document>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** Interface for types that can be used as document reference */
export type DocumentReference = {
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
};

export type EditUserInput = {
  /** City */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Education */
  education?: InputMaybe<Education>;
  enabledPushNotificationTypes?: InputMaybe<Array<InputMaybe<PushNotificationType>>>;
  /** External insurance contract number */
  externalInsuranceContractNumber?: InputMaybe<Scalars['String']['input']>;
  /** Number of family members */
  familyMembers?: InputMaybe<Scalars['Int']['input']>;
  /** First name */
  firstname?: InputMaybe<Scalars['String']['input']>;
  /** ID of user */
  id: Scalars['ID']['input'];
  /** Insurance status */
  insuranceStatus?: InputMaybe<InsuranceStatus>;
  /** User interest */
  interest?: InputMaybe<UserInterest>;
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  /** Last name */
  lastname?: InputMaybe<Scalars['String']['input']>;
  /** Register newsletters */
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  /** Note (max 500 characters) */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Has pets */
  pets?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Is smoker */
  smoker?: InputMaybe<Scalars['Boolean']['input']>;
  /** Street */
  street?: InputMaybe<Scalars['String']['input']>;
  /** Street number */
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserGroupType>;
  userGroupDic?: InputMaybe<Scalars['String']['input']>;
  userGroupIc?: InputMaybe<Scalars['String']['input']>;
  userGroupName?: InputMaybe<Scalars['String']['input']>;
  userGroupType?: InputMaybe<UserGroupKeyType>;
  /** Workplace */
  workplace?: InputMaybe<Scalars['String']['input']>;
  /** Year of birth */
  yearBorn?: InputMaybe<Scalars['Int']['input']>;
  /** ZIP code */
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum Education {
  /** Výuční list s maturitou */
  CertGrad = 'CERT_GRAD',
  /** Výuční list bez maturity */
  CertNoGrad = 'CERT_NO_GRAD',
  /** Základní škola */
  Elementary = 'ELEMENTARY',
  /** Střední škola */
  HighSchool = 'HIGH_SCHOOL',
  /** Vyberte */
  Undefined = 'UNDEFINED',
  /** Vysoká škola nebo VOŠ */
  University = 'UNIVERSITY'
}

export enum EntityType {
  /** Inzerát */
  Advert = 'ADVERT',
  /** Galerie */
  Gallery = 'GALLERY',
  /** Smlouva */
  RentContract = 'RENT_CONTRACT',
  /** Vybavení */
  RentEquipment = 'RENT_EQUIPMENT',
  /** Oprava */
  RentRepair = 'RENT_REPAIR',
  /** Nájemník */
  RentTenant = 'RENT_TENANT'
}

export enum Equipped {
  /** Částečně */
  Castecne = 'CASTECNE',
  /** Nevybavený */
  Nevybaveny = 'NEVYBAVENY',
  /** Nevyplněno */
  Undefined = 'UNDEFINED',
  /** Vybavený */
  Vybaveny = 'VYBAVENY'
}

export enum EstateType {
  /** Byt */
  Byt = 'BYT',
  /** Dům */
  Dum = 'DUM',
  /** Garáž */
  Garaz = 'GARAZ',
  /** Kancelář */
  Kancelar = 'KANCELAR',
  /** Nebytový prostor */
  NebytovyProstor = 'NEBYTOVY_PROSTOR',
  /** Pozemek */
  Pozemek = 'POZEMEK',
  /** Rekreační objekt */
  RekreacniObjekt = 'REKREACNI_OBJEKT',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export enum Execution {
  /** nadstandardní: nadstandardní použité materiály a technologie */
  Premium = 'PREMIUM',
  /** standardní: běžné materiály a technologie */
  Standard = 'STANDARD',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export enum Floor {
  /** podkroví */
  Attic = 'ATTIC',
  /** přízemí */
  Ground = 'GROUND',
  /** vyvýšené přízemí */
  HighGround = 'HIGH_GROUND',
  /** poslední podlaží */
  Last = 'LAST',
  /** první až předposlední podlaží */
  Middle = 'MIDDLE',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export type FormError = {
  __typename?: 'FormError';
  fieldName?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type FormattedBoolParameter = {
  __typename?: 'FormattedBoolParameter';
  name?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type FormattedParameter = {
  __typename?: 'FormattedParameter';
  name?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  tooltip?: Maybe<Scalars['String']['output']>;
  trackPixelSrc?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  valueHref?: Maybe<Scalars['String']['output']>;
};

export type GpsPoint = {
  __typename?: 'GPSPoint';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type GpsPointInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type GpsPolygon = {
  __typename?: 'GPSPolygon';
  points?: Maybe<Array<Maybe<GpsPoint>>>;
};

export type GpsPolygonInput = {
  points?: InputMaybe<Array<InputMaybe<GpsPointInput>>>;
};

export type Gallery = {
  __typename?: 'Gallery';
  id?: Maybe<Scalars['ID']['output']>;
  images?: Maybe<Array<Maybe<Image>>>;
  mainImageUrl?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  public?: Maybe<Scalars['Boolean']['output']>;
  reference?: Maybe<GalleryReference>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};


export type GalleryMainImageUrlArgs = {
  filter: ImageFilter;
};

/** Interface for types that can be used as gallery reference */
export type GalleryReference = {
  /** ID */
  id?: Maybe<Scalars['ID']['output']>;
};

export enum Heating {
  /** centrální */
  Central = 'CENTRAL',
  /** elektrický kotel */
  Electric = 'ELECTRIC',
  /** plynový kotel */
  Gas = 'GAS',
  /** tepelné čerpadlo (geotermální) */
  Geothermal = 'GEOTHERMAL',
  /** jiné */
  Other = 'OTHER',
  /** ústřední dálkové */
  Remote = 'REMOTE',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export enum HouseType {
  /** Dvojdům */
  Double = 'DOUBLE',
  /** Bytový dům */
  Flat = 'FLAT',
  /** Řadový dům */
  Row = 'ROW',
  /** Samostatný dům */
  Single = 'SINGLE',
  Undefined = 'UNDEFINED'
}

export type HrefLang = {
  __typename?: 'HrefLang';
  locale?: Maybe<Locale>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Image = {
  __typename?: 'Image';
  /** @deprecated To be removed */
  description?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  main?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  originalImage?: Maybe<Image>;
  reference?: Maybe<Gallery>;
  size?: Maybe<Size>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<ImageType>;
  url?: Maybe<Scalars['String']['output']>;
};


export type ImageSizeArgs = {
  filter: ImageFilter;
};


export type ImageUrlArgs = {
  filter: ImageFilter;
};

export enum ImageAction {
  /** Delete advert image */
  Delete = 'DELETE',
  /** Rotate advert image left */
  RotateLeft = 'ROTATE_LEFT',
  /** Rotate advert image right */
  RotateRight = 'ROTATE_RIGHT'
}

export enum ImageFilter {
  /** 4096 x 4096 */
  BrizInput = 'BRIZ_INPUT',
  /** 1800 x 1200, with watermark */
  RecordMain = 'RECORD_MAIN',
  /** 600 x 400, with watermark */
  RecordThumb = 'RECORD_THUMB',
  /** 120 x 120 */
  UserPhoto = 'USER_PHOTO',
  /** 1100 x 800 */
  UserPhotoBig = 'USER_PHOTO_BIG'
}

export enum ImageType {
  /** Briz - AI generated image */
  Briz = 'BRIZ'
}

export enum ImageUsage {
  /** Staženo - zájemce */
  Download = 'DOWNLOAD',
  /** Uloženo do galerie - majitel */
  Gallery = 'GALLERY'
}

export type ImageVariant = {
  __typename?: 'ImageVariant';
  base64: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
};

export type Insurance = {
  __typename?: 'Insurance';
  apiResponse?: Maybe<Scalars['String']['output']>;
  birthNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cityDistrict?: Maybe<Scalars['String']['output']>;
  continueUrl?: Maybe<Scalars['String']['output']>;
  contractNumber?: Maybe<Scalars['String']['output']>;
  dateBirth?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstname: Scalars['String']['output'];
  hash?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
  locale?: Maybe<Locale>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  status: InsuranceFlowStatus;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeEnd?: Maybe<Scalars['DateTime']['output']>;
  timePaidManual?: Maybe<Scalars['DateTime']['output']>;
  timePaidSystem?: Maybe<Scalars['DateTime']['output']>;
  timeStart?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  type: InsuranceType;
  user: User;
  vs?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type InsuranceContract = {
  __typename?: 'InsuranceContract';
  number: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  timeEnd?: Maybe<Scalars['DateTime']['output']>;
  timePaid?: Maybe<Scalars['DateTime']['output']>;
  timeStart?: Maybe<Scalars['DateTime']['output']>;
};

export type InsuranceDocument = {
  __typename?: 'InsuranceDocument';
  name: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum InsuranceFlowStatus {
  /** Zrušeno čestné prohlášení */
  CanceledExternal = 'CANCELED_EXTERNAL',
  /** Čestné prohlášení */
  ConfirmedExternal = 'CONFIRMED_EXTERNAL',
  /** Smazáno administrátorem */
  DeletedAdmin = 'DELETED_ADMIN',
  /** Smazáno uživatelem */
  DeletedUser = 'DELETED_USER',
  /** Odeslán formulář */
  FormSubmitted = 'FORM_SUBMITTED',
  /** Zaplaceno */
  PaymentCompleted = 'PAYMENT_COMPLETED',
  /** Přechod do platební brány */
  PaymentStarted = 'PAYMENT_STARTED',
  /** Potvrzena kalkulace */
  QuoteConfirmed = 'QUOTE_CONFIRMED'
}

export enum InsuranceStatus {
  /** Pojištění od Bezrealitky */
  Bzr = 'BZR',
  /** Pojištění od externího poskytovatele - čestné prohlášení */
  External = 'EXTERNAL',
  /** Bez pojištění */
  None = 'NONE'
}

export enum InsuranceType {
  /** Pojištění od Bezrealitky */
  Bzr = 'BZR',
  /** Pojištění od externího poskytovatele - čestné prohlášení */
  External = 'EXTERNAL'
}

export type InsuranceUpsell = {
  __typename?: 'InsuranceUpsell';
  discount: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
};

export enum LandType {
  /** Komerční */
  Komercni = 'KOMERCNI',
  /** Les */
  Les = 'LES',
  /** Louka */
  Louka = 'LOUKA',
  /** Ostatní */
  Ostatni = 'OSTATNI',
  /** Pole */
  Pole = 'POLE',
  /** Rybník */
  Rybnik = 'RYBNIK',
  /** Stavební */
  Stavebni = 'STAVEBNI',
  /** Nevyplněno */
  Undefined = 'UNDEFINED',
  /** Zahrada */
  Zahrada = 'ZAHRADA'
}

export type LayoutStats = {
  __typename?: 'LayoutStats';
  countActive?: Maybe<Scalars['Int']['output']>;
  countRealizedTrades?: Maybe<Scalars['Int']['output']>;
  priceOfRealizedTrades?: Maybe<Scalars['Float']['output']>;
};

export type Link = {
  __typename?: 'Link';
  href?: Maybe<Scalars['String']['output']>;
  /** Image ratio (width / height) */
  imageRatio?: Maybe<Scalars['Float']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export enum Locale {
  /** Austria */
  At = 'AT',
  /** Czech */
  Cs = 'CS',
  /** Germany */
  De = 'DE',
  /** English */
  En = 'EN',
  /** Slovak */
  Sk = 'SK'
}

export type LocaleSlug = {
  __typename?: 'LocaleSlug';
  locale: Locale;
  slug: Scalars['String']['output'];
};

export type MallPayResponse = {
  __typename?: 'MallPayResponse';
  gatewayRedirectUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  state?: Maybe<MallPayResponseState>;
};

export enum MallPayResponseState {
  /** CANCELLED */
  Cancelled = 'CANCELLED',
  /** PROCESSING */
  Processing = 'PROCESSING',
  /** READY */
  Ready = 'READY',
  /** REJECTED */
  Rejected = 'REJECTED'
}

export enum MarketingConsentOrigin {
  /** Admin */
  Admin = 'ADMIN',
  /** Bezrealitky blog */
  Blog = 'BLOG',
  /** Comfort service page */
  Comfort = 'COMFORT',
  /** Consent landing-page */
  Consent = 'CONSENT',
  /** Contract rent service page */
  ContractRent = 'CONTRACT_RENT',
  /** Contract sale service page */
  ContractSale = 'CONTRACT_SALE',
  /** Advert detail */
  Detail = 'DETAIL',
  /** Estimation */
  Estimation = 'ESTIMATION',
  /** Estimation watch */
  EstimationWatch = 'ESTIMATION_WATCH',
  /** Express service page */
  Express = 'EXPRESS',
  /** Guide */
  Guide = 'GUIDE',
  /** Homepage */
  Hp = 'HP',
  /** Advert list */
  List = 'LIST',
  /** Login page */
  Login = 'LOGIN',
  /** Mortgage */
  Mortgage = 'MORTGAGE',
  /** NEMO report */
  Nemoreport = 'NEMOREPORT',
  /** Onboarding */
  Onboarding = 'ONBOARDING',
  /** PENB service page */
  Penb = 'PENB',
  /** Popup NWL */
  Popup = 'POPUP',
  /** User profile */
  Profile = 'PROFILE',
  /** Registration page */
  Registration = 'REGISTRATION',
  /** Roomstory */
  Roomstory = 'ROOMSTORY',
  /** Services index */
  Services = 'SERVICES',
  /** Smart Emailing */
  SmartEmailing = 'SMART_EMAILING',
  /** Garance Najmu service page */
  TvujSpravce = 'TVUJ_SPRAVCE'
}

export enum MarketingConsentStatus {
  /** Expired */
  StatExpired = 'STAT_EXPIRED',
  /** False */
  StatFalse = 'STAT_FALSE',
  /** Removed */
  StatRemoved = 'STAT_REMOVED',
  /** True */
  StatTrue = 'STAT_TRUE'
}

export type MenuCounts = {
  __typename?: 'MenuCounts';
  conversations?: Maybe<Scalars['Int']['output']>;
  favourites?: Maybe<Scalars['Int']['output']>;
  notifications?: Maybe<Scalars['Int']['output']>;
  services?: Maybe<Scalars['Int']['output']>;
  watchdogs?: Maybe<Scalars['Int']['output']>;
};

export type Message = {
  __typename?: 'Message';
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  author?: Maybe<User>;
  conversation?: Maybe<Conversation>;
  data?: Maybe<MessageDataInterface>;
  id?: Maybe<Scalars['ID']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeRead?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<MessageType>;
  unread?: Maybe<Scalars['Boolean']['output']>;
};

export enum MessageAttachmentType {
  Document = 'DOCUMENT',
  Image = 'IMAGE'
}

export type MessageDataCalendarEntryCustomer = MessageDataInterface & {
  __typename?: 'MessageDataCalendarEntryCustomer';
  calendarEntryCustomerId?: Maybe<Scalars['ID']['output']>;
  calendarEntryCustomerTimeFrom?: Maybe<Scalars['DateTime']['output']>;
  calendarEntryCustomerTimeTo?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<MessageType>;
};

export type MessageDataCalendarEntryCustomerAccepted = MessageDataInterface & {
  __typename?: 'MessageDataCalendarEntryCustomerAccepted';
  calendarEventId?: Maybe<Scalars['ID']['output']>;
  calendarEventTimeFrom?: Maybe<Scalars['DateTime']['output']>;
  calendarEventTimeTo?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<MessageType>;
};

export type MessageDataCalendarEntryCustomerRemoved = MessageDataInterface & {
  __typename?: 'MessageDataCalendarEntryCustomerRemoved';
  calendarEntryCustomerId?: Maybe<Scalars['ID']['output']>;
  calendarEntryCustomerTimeFrom?: Maybe<Scalars['DateTime']['output']>;
  calendarEntryCustomerTimeTo?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<MessageType>;
};

export type MessageDataCalendarEventConfirmed = MessageDataInterface & {
  __typename?: 'MessageDataCalendarEventConfirmed';
  calendarEventId?: Maybe<Scalars['ID']['output']>;
  calendarEventTimeFrom?: Maybe<Scalars['DateTime']['output']>;
  calendarEventTimeTo?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<MessageType>;
};

export type MessageDataCalendarEventCreated = MessageDataInterface & {
  __typename?: 'MessageDataCalendarEventCreated';
  calendarEventId?: Maybe<Scalars['ID']['output']>;
  calendarEventTimeFrom?: Maybe<Scalars['DateTime']['output']>;
  calendarEventTimeTo?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<MessageType>;
};

export type MessageDataCalendarEventRemoved = MessageDataInterface & {
  __typename?: 'MessageDataCalendarEventRemoved';
  calendarEventHideCalendar?: Maybe<Scalars['Boolean']['output']>;
  calendarEventId?: Maybe<Scalars['ID']['output']>;
  calendarEventTimeFrom?: Maybe<Scalars['DateTime']['output']>;
  calendarEventTimeTo?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<MessageType>;
};

export type MessageDataCalendarEventUpcoming = MessageDataInterface & {
  __typename?: 'MessageDataCalendarEventUpcoming';
  calendarEventAddress?: Maybe<Scalars['String']['output']>;
  calendarEventId?: Maybe<Scalars['ID']['output']>;
  calendarEventTimeFrom?: Maybe<Scalars['DateTime']['output']>;
  calendarEventTimeTo?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<MessageType>;
};

export type MessageDataInitialMessage = MessageDataInterface & {
  __typename?: 'MessageDataInitialMessage';
  preferredInspectionTimeFrom?: Maybe<Scalars['DateTime']['output']>;
  preferredInspectionTimeTo?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<MessageType>;
};

/** Interface for message data used by different types of messages */
export type MessageDataInterface = {
  type?: Maybe<MessageType>;
};

export type MessageDataNewAdvertPriceBid = MessageDataInterface & {
  __typename?: 'MessageDataNewAdvertPriceBid';
  bidOfferedPrice?: Maybe<Scalars['Int']['output']>;
  bidOfferedPriceCurrency?: Maybe<Currency>;
  preferredInspectionTimeFrom?: Maybe<Scalars['DateTime']['output']>;
  preferredInspectionTimeTo?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<MessageType>;
};

export type MessageDataUpdatedAdvertPriceBid = MessageDataInterface & {
  __typename?: 'MessageDataUpdatedAdvertPriceBid';
  bidOfferedPrice?: Maybe<Scalars['Int']['output']>;
  bidOfferedPriceCurrency?: Maybe<Currency>;
  type?: Maybe<MessageType>;
};

export type MessageInput = {
  /**
   * Advert ID (Required if conversation argument is not used) Usage with advert ID can create new conversation if none exists
   *
   */
  advertId?: InputMaybe<Scalars['ID']['input']>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['MessageAttachmentUpload']['input']>>>;
  /**
   * Conversation ID (Required if advert argument is not used)
   *
   */
  conversationId?: InputMaybe<Scalars['ID']['input']>;
  /** Request Ownest pricing */
  ownest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Text of message */
  text: Scalars['String']['input'];
};

export type MessageList = {
  __typename?: 'MessageList';
  list?: Maybe<Array<Maybe<Message>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum MessageType {
  /** Ask for creditcheck */
  AskForCreditcheck = 'ASK_FOR_CREDITCHECK',
  /** Ask for insurance */
  AskForInsurance = 'ASK_FOR_INSURANCE',
  /** Calendar entry customer */
  CalendarEntryCustomer = 'CALENDAR_ENTRY_CUSTOMER',
  /** Calendar entry customer accept */
  CalendarEntryCustomerAccept = 'CALENDAR_ENTRY_CUSTOMER_ACCEPT',
  /** Calendar entry customer remove */
  CalendarEntryCustomerRemove = 'CALENDAR_ENTRY_CUSTOMER_REMOVE',
  /** New calendar entry */
  CalendarEntryNew = 'CALENDAR_ENTRY_NEW',
  /** Calendar event confirmed */
  CalendarEventConfirmed = 'CALENDAR_EVENT_CONFIRMED',
  /** Calendar event created */
  CalendarEventCreated = 'CALENDAR_EVENT_CREATED',
  /** Calendar event removed */
  CalendarEventRemoved = 'CALENDAR_EVENT_REMOVED',
  /** Calendar event upcoming */
  CalendarEventUpcoming = 'CALENDAR_EVENT_UPCOMING',
  /** Calendar hidden */
  CalendarHidden = 'CALENDAR_HIDDEN',
  /** Calendar shared */
  CalendarShared = 'CALENDAR_SHARED',
  /** First message sent */
  InitialMessage = 'INITIAL_MESSAGE',
  /** New advert price bid */
  NewAdvertPriceBid = 'NEW_ADVERT_PRICE_BID',
  /**
   * Refused advert price bid
   * @deprecated To be removed, use REFUSED_BY_OWNER instead
   */
  RefusedAdvertPriceBid = 'REFUSED_ADVERT_PRICE_BID',
  /** Refused by customer */
  RefusedByCustomer = 'REFUSED_BY_CUSTOMER',
  /** Refused by owner */
  RefusedByOwner = 'REFUSED_BY_OWNER',
  /**
   * Renewed advert price bid
   * @deprecated To be removed, use RENEWED_BY_OWNER instead
   */
  RenewedAdvertPriceBid = 'RENEWED_ADVERT_PRICE_BID',
  /** Renewed by customer */
  RenewedByCustomer = 'RENEWED_BY_CUSTOMER',
  /** Renewed by owner */
  RenewedByOwner = 'RENEWED_BY_OWNER',
  /** Creditcheck done */
  SuccessCreditcheck = 'SUCCESS_CREDITCHECK',
  /** Insurance done */
  SuccessInsurance = 'SUCCESS_INSURANCE',
  /** Updated advert price bid */
  UpdatedAdvertPriceBid = 'UPDATED_ADVERT_PRICE_BID'
}

export type MessagesInput = {
  /**
   * Advert IDs (Required if conversationIds argument is not used) Usage with advert ID can create new conversation if none exists
   *
   */
  advertIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /**
   * Conversation IDs (Required if advert argument is not used)
   *
   */
  conversationIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Text of message */
  text: Scalars['String']['input'];
};

export type MortgageData = {
  __typename?: 'MortgageData';
  loan?: Maybe<Scalars['Int']['output']>;
  payment?: Maybe<Scalars['Int']['output']>;
  paymentString?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  rateHigh?: Maybe<Scalars['Float']['output']>;
  rateLow?: Maybe<Scalars['Float']['output']>;
  years?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accepts calendar entry from customer */
  acceptCalendarEntryCustomer?: Maybe<CalendarEntry>;
  /**
   * Activates advert
   *
   */
  activateAdvert?: Maybe<Advert>;
  /** Add calendar entries */
  addCalendarEntries?: Maybe<Array<Maybe<CalendarEntry>>>;
  /** Adds calendar entry from customer */
  addCalendarEntryCustomer?: Maybe<CalendarEntryCustomer>;
  /** Creates calendar event */
  addCalendarEvent?: Maybe<CalendarEvent>;
  /**
   * Creates order and processed payment in case of an iOS In App Purchase
   *
   */
  addPaidOrder?: Maybe<Scalars['Boolean']['output']>;
  applePayValidateMerchant?: Maybe<ApplePaySession>;
  /**
   * Gets user info from ARES
   *
   */
  aresInfo?: Maybe<UserAresInfo>;
  /** Sends request to customer to perform creditcheck */
  askForCreditCheck?: Maybe<Scalars['Boolean']['output']>;
  /** Sends a request to customer to take out insurance */
  askForInsurance?: Maybe<Scalars['Boolean']['output']>;
  /** Sends request to advert owner to update it's information for Nemoreport */
  askForNemoreportAdvertUpdate?: Maybe<NemoreportUpdateRequest>;
  changeImage?: Maybe<Image>;
  /**
   * Change password
   *
   */
  changePassword?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Requests password change for logged user
   *
   */
  changePasswordEmail?: Maybe<User>;
  /** Basic operations on photo */
  changeTmpImage?: Maybe<UploadTmpImageResponse>;
  /**
   * Check discount code validity
   *
   */
  checkDiscount?: Maybe<Scalars['Boolean']['output']>;
  /** Closes all conversations */
  closeAllConversations?: Maybe<Scalars['Int']['output']>;
  /** Close conversation */
  closeConversation?: Maybe<Conversation>;
  /** Close conversations */
  closeConversations?: Maybe<ConversationList>;
  /**
   * Owner confirmation of calendar event
   * @deprecated To be removed
   */
  confirmCalendarEvent?: Maybe<CalendarEvent>;
  /**
   * Connects google account with user
   *
   */
  connectGoogle?: Maybe<User>;
  /**
   * Creates new advert request from customer with price bid
   * @deprecated Use respondToAdvert instead
   */
  createAdvertPriceBid?: Maybe<Conversation>;
  /** Creates a new order */
  createOrder?: Maybe<OrderResponse>;
  /** Create new share URL */
  createShare?: Maybe<Share>;
  /** Request creditcheck on other person */
  creditCheckAsService?: Maybe<Service>;
  /** Requests creditcheck on self */
  creditCheckForUser?: Maybe<User>;
  /**
   * Deactivates advert
   *
   */
  deactivateAdvert?: Maybe<Advert>;
  /** Deactivates project */
  deactivateProject?: Maybe<Project>;
  deleteAdvertLink?: Maybe<Advert>;
  deleteAdvertVideo?: Maybe<Scalars['ID']['output']>;
  /** Deletes all closed conversations */
  deleteAllConversations?: Maybe<Scalars['Boolean']['output']>;
  /** Delete Broker */
  deleteBroker?: Maybe<Scalars['ID']['output']>;
  /** Deletes Broker photo */
  deleteBrokerPhoto?: Maybe<Broker>;
  /** Deletes closed conversation */
  deleteConversation?: Maybe<Conversation>;
  /** Deletes closed conversations */
  deleteConversations?: Maybe<ConversationList>;
  /** Deletes rent platform document */
  deleteDocument?: Maybe<Scalars['ID']['output']>;
  /** Deletes image gallery */
  deleteGallery?: Maybe<Scalars['ID']['output']>;
  /** Deletes notification */
  deleteNotification?: Maybe<Scalars['ID']['output']>;
  /** Deletes project photo */
  deleteProjectPhoto?: Maybe<Project>;
  /** Deletes rent platform bank account */
  deleteRentBankAccount?: Maybe<Scalars['ID']['output']>;
  /** Deletes rent platform cost */
  deleteRentCost?: Maybe<Scalars['ID']['output']>;
  /** Deletes rent platform equipment */
  deleteRentEquipment?: Maybe<Scalars['ID']['output']>;
  /** Deletes rent platform note */
  deleteRentNote?: Maybe<Scalars['ID']['output']>;
  /** Deletes rent platform bank account */
  deleteRentPayment?: Maybe<Scalars['ID']['output']>;
  /** Deletes rent platform repair */
  deleteRentRepair?: Maybe<Scalars['ID']['output']>;
  /** Deletes rent platform service */
  deleteRentService?: Maybe<Scalars['ID']['output']>;
  /** Deletes rent platform tenant */
  deleteRentTenant?: Maybe<Scalars['ID']['output']>;
  /** Delete user photo */
  deleteUserPhoto?: Maybe<User>;
  /** Deletes watchdog */
  deleteWatchdog?: Maybe<Scalars['ID']['output']>;
  /** Deletes user watchdogs by hash */
  deleteWatchdogsByHash?: Maybe<Scalars['Boolean']['output']>;
  /** Duplicate advert */
  duplicateAdvert?: Maybe<Advert>;
  /**
   * Edit image gallery
   *
   */
  editGallery?: Maybe<Gallery>;
  /**
   * Edit rent platform bank account
   *
   */
  editRentBankAccount?: Maybe<RentBankAccount>;
  /**
   * Edit rent platform contract
   *
   */
  editRentContract?: Maybe<RentContract>;
  /**
   * Edit rent platform cost
   *
   */
  editRentCost?: Maybe<RentCost>;
  /**
   * Edit rent platform equipment
   *
   */
  editRentEquipment?: Maybe<RentEquipment>;
  /**
   * Edit rent platform note
   *
   */
  editRentNote?: Maybe<RentNote>;
  /**
   * Edit rent platform bank account
   *
   */
  editRentPayment?: Maybe<RentPayment>;
  /**
   * Edit rent platform repair
   *
   */
  editRentRepair?: Maybe<RentRepair>;
  /**
   * Edit rent platform service
   *
   */
  editRentService?: Maybe<RentService>;
  /**
   * Edit rent platform tenant
   *
   */
  editRentTenant?: Maybe<RentTenant>;
  /** Edits user */
  editUser?: Maybe<User>;
  endRentContract?: Maybe<Scalars['Boolean']['output']>;
  extendRentContract?: Maybe<Scalars['Boolean']['output']>;
  finishInsurancePayment?: Maybe<Scalars['Boolean']['output']>;
  /** Send forgotten password email */
  forgotPasswordEmail?: Maybe<Scalars['Boolean']['output']>;
  freeActivateProperty?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Generates calendar token
   *
   */
  generateCalendarToken?: Maybe<User>;
  /** Updates advert price estimation */
  getCurrentAdvertPriceEstimation?: Maybe<Advert>;
  /** Removes advert calendar from this conversation */
  hideCalendar?: Maybe<Conversation>;
  /** Log advert visit */
  logAdvertVisit?: Maybe<Advert>;
  /** Log blog article visit */
  logBlogArticleVisit?: Maybe<BlogArticle>;
  /** Log user login info */
  logUserLogin?: Maybe<Scalars['Boolean']['output']>;
  /** Marks conversation as important */
  markImportantConversation?: Maybe<Conversation>;
  /** Marks conversation as read */
  markReadConversation?: Maybe<Conversation>;
  /** Marks notification as read */
  markReadNotification?: Maybe<Notification>;
  /** Marks notifications as read */
  markReadNotifications?: Maybe<NotificationList>;
  /** Marks conversation as unread */
  markUnreadConversation?: Maybe<Conversation>;
  markUsedImage?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Marks watchdog as read
   *
   */
  markWatchdogSeen?: Maybe<Watchdog>;
  /** Confirm newsletter DOI */
  newsletterConfirm?: Maybe<Scalars['Boolean']['output']>;
  /** Subscribe to newsletter by email */
  newsletterSubscribe?: Maybe<Scalars['Boolean']['output']>;
  /** Unsubscribe from newsletter */
  newsletterUnsubscribe?: Maybe<Scalars['Boolean']['output']>;
  openNotification?: Maybe<Scalars['Boolean']['output']>;
  orderService?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Pause advert by user
   *
   */
  pauseAdvert?: Maybe<Advert>;
  /** Pin conversation */
  pinConversation?: Maybe<Conversation>;
  /** Advert action */
  projectAdvertAction?: Maybe<Project>;
  /** Re-opens closed conversation */
  reactivateConversation?: Maybe<Conversation>;
  /** Reactivates project */
  reactivateProject?: Maybe<Project>;
  /** Activate Realman export via upsell notification */
  realmanActivateExport?: Maybe<Advert>;
  /** Refuse conversation */
  refuseConversation?: Maybe<Conversation>;
  /** User registration */
  registration?: Maybe<AuthData>;
  /** Remove calendar entries */
  removeCalendarEntries?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  /** Removes calendar entry from customer */
  removeCalendarEntryCustomer?: Maybe<Scalars['ID']['output']>;
  /** Removes calendar event */
  removeCalendarEvent?: Maybe<Scalars['ID']['output']>;
  /**
   * Remove contact information from advert description
   *
   */
  removeContacts?: Maybe<Scalars['Boolean']['output']>;
  /** Remove conversation tag */
  removeConversationTag?: Maybe<Scalars['ID']['output']>;
  /**
   * Remove logged user
   *
   */
  removeUser?: Maybe<Scalars['Boolean']['output']>;
  /** Renew conversation bidding */
  renewConversation?: Maybe<Conversation>;
  /** Reports conversation */
  reportConversation?: Maybe<Conversation>;
  requestInsuranceContract?: Maybe<Scalars['Boolean']['output']>;
  /** Requests password change */
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
  /** Responds to advert based on its type */
  respondToAdvert?: Maybe<Advert>;
  /** Rotate user photo */
  rotateUserPhoto?: Maybe<User>;
  /** Saves advert */
  saveAdvert?: Maybe<Advert>;
  /** Saves advert images in given order */
  saveAdvertImageOrder?: Maybe<Advert>;
  saveAdvertLink?: Maybe<AdvertLink>;
  /** Log user login info */
  saveAuthUserJson?: Maybe<Scalars['Boolean']['output']>;
  /** Add/Edit a Broker */
  saveBroker?: Maybe<Broker>;
  /** Save note for conversation */
  saveConversationNote?: Maybe<Conversation>;
  /** Save tag for conversation */
  saveConversationTag?: Maybe<ConversationTag>;
  /** Save tags associated with conversation */
  saveConversationTags?: Maybe<Conversation>;
  saveEhub?: Maybe<User>;
  saveImageDescription?: Maybe<Image>;
  saveInsuranceLead?: Maybe<ServiceLead>;
  /** Edit project */
  saveProject?: Maybe<Project>;
  /** Save tele-sale item */
  saveTeleSale?: Maybe<TeleSale>;
  /**
   * Edits watchdog
   *
   */
  saveWatchdog?: Maybe<Watchdog>;
  /**
   * Send advert lead instead of message
   * @deprecated Use respondToAdvert instead
   */
  sendAdvertLead?: Maybe<AdvertLead>;
  /** Sends entire conversation to user defined email */
  sendConversationByEmail?: Maybe<Scalars['Boolean']['output']>;
  /** Sends new message */
  sendMessage?: Maybe<Message>;
  /** Sends new messages */
  sendMessages?: Maybe<MessageList>;
  /** Requests SMS for phone verification */
  sendPhoneVerificationSms?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Send rent payment reminder
   *
   */
  sendRentPaymentReminder?: Maybe<Scalars['Boolean']['output']>;
  /** Send service lead from form */
  sendServiceLead?: Maybe<ServiceLead>;
  /** Advert set project */
  setAdvertProject?: Maybe<Advert>;
  /**
   * Marks advert for rent platform
   *
   */
  setAdvertRentPlatform?: Maybe<Advert>;
  /**
   * Set advert platform status
   *
   */
  setAdvertRentPlatformStatus?: Maybe<Advert>;
  /** Sets advert reservation state */
  setAdvertReserved?: Maybe<Advert>;
  /** Set a comfort specialist for advert */
  setBroker?: Maybe<Broker>;
  /** Save calendar entry note */
  setCalendarEntryNote?: Maybe<CalendarEntry>;
  /** Save calendar event note */
  setCalendarEventNote?: Maybe<CalendarEvent>;
  /**
   * Sets advert favorite state
   *
   */
  setFavoriteAdvert?: Maybe<AdvertUserstate>;
  /**
   * Set new time due for Top AdvertEvent
   *
   */
  setTopTimeDue?: Maybe<AdvertEvent>;
  /**
   * Shares advert calendar for this conversation
   * @deprecated To be removed in favor of shareCalendarBulk
   */
  shareCalendar?: Maybe<Conversation>;
  /** Shares advert calendar for this conversation */
  shareCalendarBulk?: Maybe<ConversationList>;
  startInsurancePayment?: Maybe<Scalars['String']['output']>;
  submitInsuranceForm?: Maybe<Insurance>;
  subscribe?: Maybe<PushSubscription>;
  /**
   * Unpause advert by user
   *
   */
  unpauseAdvert?: Maybe<Advert>;
  /** Pin conversation */
  unpinConversation?: Maybe<Conversation>;
  unsubscribe?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Updates account settings
   *
   */
  updateAccountSettings?: Maybe<User>;
  /**
   * Updates user additional info
   *
   */
  updateAdditionalInfo?: Maybe<User>;
  /** Update price bid */
  updateAdvertPriceBid?: Maybe<Conversation>;
  /** Updates basic user info */
  updateBasicInfo?: Maybe<User>;
  /** Update user check status */
  updateUserCheckStatus?: Maybe<Conversation>;
  /** Update user insurance status */
  updateUserInsuranceStatus?: Maybe<Conversation>;
  updateUserProfileSettings?: Maybe<UserProfileSettings>;
  /**
   * Uploads Broker photo
   *
   */
  uploadBrokerPhoto?: Maybe<Broker>;
  /**
   * Upload and save a document
   *
   */
  uploadDocument?: Maybe<Document>;
  /** Upload and save image */
  uploadImage?: Maybe<Image>;
  /** Uploads project photo */
  uploadProjectPhoto?: Maybe<Project>;
  /** Uploads temporary image */
  uploadTmpImage?: Maybe<UploadTmpImageResponse>;
  /** Upload user photo */
  uploadUserPhoto?: Maybe<User>;
  /** Upload and save video */
  uploadVideo?: Maybe<AdvertVideo>;
  verifyPhone?: Maybe<User>;
  /**
   * Request return of remaining wallet credit
   *
   */
  walletWithdraw?: Maybe<User>;
};


export type MutationAcceptCalendarEntryCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationActivateAdvertArgs = {
  advertId: Scalars['ID']['input'];
};


export type MutationAddCalendarEntriesArgs = {
  advertId: Scalars['ID']['input'];
  intervals: Array<InputMaybe<CalendarEntryInterval>>;
  userLimit: Scalars['Int']['input'];
};


export type MutationAddCalendarEntryCustomerArgs = {
  conversationId: Scalars['ID']['input'];
  interval: CalendarEntryInterval;
};


export type MutationAddCalendarEventArgs = {
  calendarEntryId: Scalars['ID']['input'];
};


export type MutationAddPaidOrderArgs = {
  packages: Array<InputMaybe<SelectedPackageInput>>;
};


export type MutationApplePayValidateMerchantArgs = {
  hostname: Scalars['String']['input'];
  merchantId: Scalars['String']['input'];
  merchantName: Scalars['String']['input'];
  validationUrl: Scalars['String']['input'];
};


export type MutationAresInfoArgs = {
  ic: Scalars['String']['input'];
};


export type MutationAskForCreditCheckArgs = {
  conversationId: Scalars['ID']['input'];
};


export type MutationAskForInsuranceArgs = {
  conversationId: Scalars['ID']['input'];
};


export type MutationAskForNemoreportAdvertUpdateArgs = {
  advertId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
};


export type MutationChangeImageArgs = {
  action: ImageAction;
  id: Scalars['ID']['input'];
};


export type MutationChangePasswordArgs = {
  email: Scalars['String']['input'];
  hash: Scalars['String']['input'];
  password1: Scalars['String']['input'];
  password2: Scalars['String']['input'];
};


export type MutationChangeTmpImageArgs = {
  action: ImageAction;
  filter: ImageFilter;
  url: Scalars['String']['input'];
};


export type MutationCheckDiscountArgs = {
  code: Scalars['String']['input'];
  firstname: Scalars['String']['input'];
  lastname: Scalars['String']['input'];
  package: Scalars['String']['input'];
  price?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationCloseAllConversationsArgs = {
  advertId?: InputMaybe<Scalars['ID']['input']>;
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  fulltext?: InputMaybe<Scalars['String']['input']>;
  important?: InputMaybe<Scalars['Boolean']['input']>;
  premium?: InputMaybe<Scalars['Boolean']['input']>;
  role: ConversationRole;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCloseConversationArgs = {
  conversationId: Scalars['ID']['input'];
};


export type MutationCloseConversationsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationConfirmCalendarEventArgs = {
  id: Scalars['ID']['input'];
};


export type MutationConnectGoogleArgs = {
  googleIdToken: Scalars['String']['input'];
};


export type MutationCreateAdvertPriceBidArgs = {
  createAdvertPriceBid: CreateAdvertPriceBidInput;
};


export type MutationCreateOrderArgs = {
  orderInput: OrderInput;
};


export type MutationCreateShareArgs = {
  type: ShareType;
};


export type MutationCreditCheckAsServiceArgs = {
  creditCheckAsService: CreditCheckAsServiceInput;
};


export type MutationCreditCheckForUserArgs = {
  creditCheckForUser: CreditCheckForUserInput;
};


export type MutationDeactivateAdvertArgs = {
  advertDeactivate: AdvertDeactivateInput;
};


export type MutationDeactivateProjectArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAdvertLinkArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAdvertVideoArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteBrokerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteBrokerPhotoArgs = {
  brokerId: Scalars['ID']['input'];
};


export type MutationDeleteConversationArgs = {
  conversationId: Scalars['ID']['input'];
};


export type MutationDeleteConversationsArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteGalleryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProjectPhotoArgs = {
  projectId: Scalars['ID']['input'];
};


export type MutationDeleteRentBankAccountArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRentCostArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRentEquipmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRentNoteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRentPaymentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRentRepairArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRentServiceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteRentTenantArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteWatchdogArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteWatchdogsByHashArgs = {
  hash: Scalars['String']['input'];
};


export type MutationDuplicateAdvertArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEditGalleryArgs = {
  advertId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  images?: InputMaybe<Array<InputMaybe<UploadedImageInput>>>;
  note?: InputMaybe<Scalars['String']['input']>;
  public: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};


export type MutationEditRentBankAccountArgs = {
  bankName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  number: Scalars['String']['input'];
};


export type MutationEditRentContractArgs = {
  cotenants?: InputMaybe<Array<InputMaybe<RentCotenantInput>>>;
  currency: Currency;
  dayPaymentDue: Scalars['Int']['input'];
  deposit: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  number: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  priceServices?: InputMaybe<Scalars['Int']['input']>;
  property: Scalars['ID']['input'];
  tenant: Scalars['ID']['input'];
  timeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  timeSigned: Scalars['DateTime']['input'];
  timeStart: Scalars['DateTime']['input'];
};


export type MutationEditRentCostArgs = {
  advertId?: InputMaybe<Scalars['ID']['input']>;
  currency: Currency;
  frequency: RentPlatformCostFrequency;
  id?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Int']['input'];
  timeDue?: InputMaybe<Scalars['DateTime']['input']>;
  timeFirstPayment?: InputMaybe<Scalars['DateTime']['input']>;
  timePaid?: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['String']['input'];
};


export type MutationEditRentEquipmentArgs = {
  advertId?: InputMaybe<Scalars['ID']['input']>;
  currency: Currency;
  discarded: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  images?: InputMaybe<Array<InputMaybe<UploadedImageInput>>>;
  price: Scalars['Int']['input'];
  timeDiscarded?: InputMaybe<Scalars['DateTime']['input']>;
  timePurchased?: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['String']['input'];
};


export type MutationEditRentNoteArgs = {
  advertId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  text: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationEditRentPaymentArgs = {
  amount: Scalars['Float']['input'];
  bankAccountId?: InputMaybe<Scalars['ID']['input']>;
  currency: Currency;
  id?: InputMaybe<Scalars['ID']['input']>;
  identifier: Scalars['String']['input'];
  method: RentPlatformPaymentMethod;
  propertyId: Scalars['ID']['input'];
  tenantId: Scalars['ID']['input'];
  timeDue?: InputMaybe<Scalars['DateTime']['input']>;
  timePaid?: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['String']['input'];
  type: RentPlatformPaymentType;
};


export type MutationEditRentRepairArgs = {
  advertId?: InputMaybe<Scalars['ID']['input']>;
  currency: Currency;
  id?: InputMaybe<Scalars['ID']['input']>;
  images?: InputMaybe<Array<InputMaybe<UploadedImageInput>>>;
  note?: InputMaybe<Scalars['String']['input']>;
  payer?: InputMaybe<RentPlatformRepairPayer>;
  price: Scalars['Int']['input'];
  timePaid?: InputMaybe<Scalars['DateTime']['input']>;
  title: Scalars['String']['input'];
};


export type MutationEditRentServiceArgs = {
  advertId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  payer?: InputMaybe<RentPlatformServicePayer>;
  status: Scalars['Int']['input'];
  timeSubscribed?: InputMaybe<Scalars['DateTime']['input']>;
  type: RentPlatformServiceType;
};


export type MutationEditRentTenantArgs = {
  birthNumber?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  dateBirth?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstname: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lastname: Scalars['String']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  paymentIdentifier?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RentPlatformTenantStatus>;
  street: Scalars['String']['input'];
  streetNumber: Scalars['String']['input'];
  tenantCount?: InputMaybe<Scalars['Int']['input']>;
  zip: Scalars['String']['input'];
};


export type MutationEditUserArgs = {
  editUser: EditUserInput;
};


export type MutationEndRentContractArgs = {
  contractId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};


export type MutationExtendRentContractArgs = {
  contractId: Scalars['ID']['input'];
  timeEnd: Scalars['DateTime']['input'];
};


export type MutationFinishInsurancePaymentArgs = {
  hash: Scalars['String']['input'];
  insuranceId?: InputMaybe<Scalars['ID']['input']>;
  leadId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationForgotPasswordEmailArgs = {
  email: Scalars['String']['input'];
  recaptcha: Scalars['String']['input'];
};


export type MutationFreeActivatePropertyArgs = {
  advertId: Scalars['ID']['input'];
};


export type MutationGenerateCalendarTokenArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationGetCurrentAdvertPriceEstimationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHideCalendarArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLogAdvertVisitArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLogBlogArticleVisitArgs = {
  locale: Locale;
  slug: Scalars['String']['input'];
};


export type MutationLogUserLoginArgs = {
  ip: Scalars['String']['input'];
};


export type MutationMarkImportantConversationArgs = {
  conversationId: Scalars['ID']['input'];
  important: Scalars['Boolean']['input'];
};


export type MutationMarkReadConversationArgs = {
  conversationId: Scalars['ID']['input'];
};


export type MutationMarkReadNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarkUnreadConversationArgs = {
  conversationId: Scalars['ID']['input'];
};


export type MutationMarkUsedImageArgs = {
  externalId: Scalars['ID']['input'];
  usage?: InputMaybe<ImageUsage>;
};


export type MutationMarkWatchdogSeenArgs = {
  id: Scalars['ID']['input'];
};


export type MutationNewsletterConfirmArgs = {
  token: Scalars['String']['input'];
};


export type MutationNewsletterSubscribeArgs = {
  email: Scalars['String']['input'];
  origin: MarketingConsentOrigin;
  recaptcha: Scalars['String']['input'];
};


export type MutationNewsletterUnsubscribeArgs = {
  email: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationOpenNotificationArgs = {
  notificationId: Scalars['ID']['input'];
  subscriptionId: Scalars['ID']['input'];
};


export type MutationOrderServiceArgs = {
  form: Scalars['String']['input'];
  serviceType: ServiceType;
};


export type MutationPauseAdvertArgs = {
  advertId: Scalars['ID']['input'];
};


export type MutationPinConversationArgs = {
  conversationId: Scalars['ID']['input'];
};


export type MutationProjectAdvertActionArgs = {
  action: ProjectAdvertAction;
  id: Scalars['ID']['input'];
};


export type MutationReactivateConversationArgs = {
  conversationId: Scalars['ID']['input'];
};


export type MutationReactivateProjectArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRealmanActivateExportArgs = {
  advertId: Scalars['ID']['input'];
};


export type MutationRefuseConversationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRegistrationArgs = {
  registrationInput: RegistrationInput;
};


export type MutationRemoveCalendarEntriesArgs = {
  ids: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationRemoveCalendarEntryCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveCalendarEventArgs = {
  hideCalendar?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationRemoveContactsArgs = {
  recordId: Scalars['ID']['input'];
};


export type MutationRemoveConversationTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRenewConversationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationReportConversationArgs = {
  conversationId: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  type: UserReportType;
};


export type MutationRequestInsuranceContractArgs = {
  continueUrl?: InputMaybe<Scalars['String']['input']>;
  insuranceId?: InputMaybe<Scalars['ID']['input']>;
  leadId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationResetPasswordArgs = {
  login: Scalars['String']['input'];
};


export type MutationRespondToAdvertArgs = {
  advertResponse: AdvertResponseInput;
};


export type MutationRotateUserPhotoArgs = {
  action: ImageAction;
};


export type MutationSaveAdvertArgs = {
  advertSave: SaveAdvertInput;
};


export type MutationSaveAdvertImageOrderArgs = {
  advertSave: SaveAdvertInput;
};


export type MutationSaveAdvertLinkArgs = {
  advertId: Scalars['ID']['input'];
  type: AdvertLinkType;
  url: Scalars['String']['input'];
};


export type MutationSaveAuthUserJsonArgs = {
  json?: InputMaybe<Scalars['String']['input']>;
  type: UserJsonType;
};


export type MutationSaveBrokerArgs = {
  email: Scalars['String']['input'];
  externalId?: InputMaybe<Scalars['ID']['input']>;
  firstName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};


export type MutationSaveConversationNoteArgs = {
  conversationId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSaveConversationTagArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  style: ConversationTagStyle;
  title: Scalars['String']['input'];
};


export type MutationSaveConversationTagsArgs = {
  id: Scalars['ID']['input'];
  tagIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationSaveEhubArgs = {
  ehub: Scalars['String']['input'];
};


export type MutationSaveImageDescriptionArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationSaveInsuranceLeadArgs = {
  birthNumber?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cityDistrict?: InputMaybe<Scalars['String']['input']>;
  csResident: Scalars['Boolean']['input'];
  dateBirth?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSaveProjectArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSaveTeleSaleArgs = {
  advertId: Scalars['ID']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  status: TeleSaleStatus;
  subStatus?: InputMaybe<TeleSaleSubStatus>;
};


export type MutationSaveWatchdogArgs = {
  saveWatchdog: SaveWatchdogInput;
};


export type MutationSendAdvertLeadArgs = {
  advertLead: AdvertLeadInput;
};


export type MutationSendConversationByEmailArgs = {
  conversationId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
};


export type MutationSendMessageArgs = {
  message: MessageInput;
};


export type MutationSendMessagesArgs = {
  messages: MessagesInput;
};


export type MutationSendPhoneVerificationSmsArgs = {
  locale: Locale;
  phone: Scalars['String']['input'];
  phoneCountry: Scalars['String']['input'];
  phonePrefix: Scalars['Int']['input'];
};


export type MutationSendRentPaymentReminderArgs = {
  paymentId: Scalars['ID']['input'];
  text: Scalars['String']['input'];
};


export type MutationSendServiceLeadArgs = {
  serviceLead: ServiceLeadInput;
};


export type MutationSetAdvertProjectArgs = {
  advertId: Scalars['ID']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSetAdvertRentPlatformArgs = {
  advert: Scalars['ID']['input'];
  platform: Scalars['Boolean']['input'];
};


export type MutationSetAdvertRentPlatformStatusArgs = {
  advert: Scalars['ID']['input'];
  status: RentPlatformAdvertStatus;
};


export type MutationSetAdvertReservedArgs = {
  advertId: Scalars['ID']['input'];
  alertTime?: InputMaybe<Scalars['Int']['input']>;
  reserved: Scalars['Boolean']['input'];
  reservedUserId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSetBrokerArgs = {
  advertId: Scalars['ID']['input'];
  brokerId: Scalars['ID']['input'];
};


export type MutationSetCalendarEntryNoteArgs = {
  id: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetCalendarEventNoteArgs = {
  id: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetFavoriteAdvertArgs = {
  advertId: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  state: Scalars['Boolean']['input'];
};


export type MutationSetTopTimeDueArgs = {
  advertEventId: Scalars['ID']['input'];
  timeDue: Scalars['DateTime']['input'];
};


export type MutationShareCalendarArgs = {
  conversation: Scalars['ID']['input'];
};


export type MutationShareCalendarBulkArgs = {
  conversationIds: Array<InputMaybe<Scalars['ID']['input']>>;
};


export type MutationStartInsurancePaymentArgs = {
  continueUrl?: InputMaybe<Scalars['String']['input']>;
  insuranceId?: InputMaybe<Scalars['ID']['input']>;
  leadId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSubmitInsuranceFormArgs = {
  birthNumber?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  cityDistrict?: InputMaybe<Scalars['String']['input']>;
  csResident: Scalars['Boolean']['input'];
  dateBirth?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSubscribeArgs = {
  deviceName?: InputMaybe<Scalars['String']['input']>;
  pushSubscriptionJSON: Scalars['String']['input'];
};


export type MutationUnpauseAdvertArgs = {
  advertId: Scalars['ID']['input'];
};


export type MutationUnpinConversationArgs = {
  conversationId: Scalars['ID']['input'];
};


export type MutationUnsubscribeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateAccountSettingsArgs = {
  locale: Locale;
  newsletter: Scalars['Boolean']['input'];
  user: Scalars['ID']['input'];
};


export type MutationUpdateAdditionalInfoArgs = {
  education?: InputMaybe<Education>;
  familyMembers?: InputMaybe<Scalars['Int']['input']>;
  interest?: InputMaybe<UserInterest>;
  note?: InputMaybe<Scalars['String']['input']>;
  pets?: InputMaybe<Scalars['String']['input']>;
  smoker?: InputMaybe<Scalars['Boolean']['input']>;
  user: Scalars['ID']['input'];
  workplace?: InputMaybe<Scalars['String']['input']>;
  yearBorn?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationUpdateAdvertPriceBidArgs = {
  updateAdvertPriceBid: UpdateAdvertPriceBidInput;
};


export type MutationUpdateBasicInfoArgs = {
  city?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneCountry?: InputMaybe<Scalars['String']['input']>;
  phonePrefix?: InputMaybe<Scalars['Int']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<UserGroupType>;
  user: Scalars['ID']['input'];
  userGroupDic?: InputMaybe<Scalars['String']['input']>;
  userGroupIc?: InputMaybe<Scalars['String']['input']>;
  userGroupName?: InputMaybe<Scalars['String']['input']>;
  userGroupType?: InputMaybe<UserGroupKeyType>;
  zip?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserCheckStatusArgs = {
  conversationId: Scalars['ID']['input'];
  status?: InputMaybe<ConversationCheckStatus>;
};


export type MutationUpdateUserInsuranceStatusArgs = {
  conversationId: Scalars['ID']['input'];
  status?: InputMaybe<ConversationInsuranceStatus>;
};


export type MutationUpdateUserProfileSettingsArgs = {
  rentPlatformDashboardOrder?: InputMaybe<Scalars['String']['input']>;
  rentPlatformReminderText?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUploadBrokerPhotoArgs = {
  brokerId: Scalars['ID']['input'];
  fileData: Scalars['String']['input'];
};


export type MutationUploadDocumentArgs = {
  entityId: Scalars['ID']['input'];
  entityType: EntityType;
  fileData: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};


export type MutationUploadImageArgs = {
  entityId: Scalars['ID']['input'];
  entityType: EntityType;
  file: Scalars['ImageUpload']['input'];
};


export type MutationUploadProjectPhotoArgs = {
  fileData: Scalars['String']['input'];
  projectId: Scalars['ID']['input'];
};


export type MutationUploadTmpImageArgs = {
  file: Scalars['ImageUpload']['input'];
  filter: ImageFilter;
};


export type MutationUploadUserPhotoArgs = {
  file: Scalars['ImageUpload']['input'];
};


export type MutationUploadVideoArgs = {
  advertId: Scalars['ID']['input'];
  file: Scalars['VideoUpload']['input'];
};


export type MutationVerifyPhoneArgs = {
  code: Scalars['String']['input'];
  locale: Locale;
  phone: Scalars['String']['input'];
  phoneCountry: Scalars['String']['input'];
  phonePrefix: Scalars['Int']['input'];
};


export type MutationWalletWithdrawArgs = {
  action: WalletWithdrawAction;
  payment?: InputMaybe<Scalars['ID']['input']>;
};

export type Nemoreport = {
  __typename?: 'Nemoreport';
  advert?: Maybe<Advert>;
  id?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  resultUrl?: Maybe<Scalars['String']['output']>;
  status?: Maybe<NemoreportResultStatus>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
};

export type NemoreportBuilding = {
  __typename?: 'NemoreportBuilding';
  idStavby?: Maybe<Scalars['String']['output']>;
  lvId?: Maybe<Scalars['String']['output']>;
};

export type NemoreportHouseUnit = {
  __typename?: 'NemoreportHouseUnit';
  cislo?: Maybe<Scalars['String']['output']>;
  idJednotky?: Maybe<Scalars['String']['output']>;
  jednotka?: Maybe<Scalars['String']['output']>;
  lvId?: Maybe<Scalars['String']['output']>;
  stavba?: Maybe<NemoreportBuilding>;
  typJedKod?: Maybe<Scalars['String']['output']>;
  zpuVyKod?: Maybe<Scalars['String']['output']>;
};

export type NemoreportHouseUnitList = {
  __typename?: 'NemoreportHouseUnitList';
  list?: Maybe<Array<Maybe<NemoreportHouseUnit>>>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<NemoreportStatus>;
};

export enum NemoreportResultStatus {
  /** Canceled */
  Canceled = 'CANCELED',
  /** Finished */
  Finished = 'FINISHED',
  /**
   * Not payed
   * @deprecated To be removed
   */
  NotPayed = 'NOT_PAYED',
  /** Paid */
  Paid = 'PAID',
  /** Pending */
  Pending = 'PENDING'
}

export enum NemoreportStatus {
  /** Chyba */
  Error = 'ERROR',
  /** OK */
  Ok = 'OK'
}

export type NemoreportUpdateRequest = {
  __typename?: 'NemoreportUpdateRequest';
  advertId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type News = {
  __typename?: 'News';
  active?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  minutes?: Maybe<Scalars['Int']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  perex?: Maybe<Scalars['String']['output']>;
  subjectBig?: Maybe<Scalars['String']['output']>;
  timeActivated?: Maybe<Scalars['DateTime']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type NewsList = {
  __typename?: 'NewsList';
  list?: Maybe<Array<Maybe<News>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type Notification = {
  __typename?: 'Notification';
  content: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reference?: Maybe<NotificationReference>;
  timeCreated: Scalars['DateTime']['output'];
  timeRead?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  type: NotificationType;
};

export type NotificationList = {
  __typename?: 'NotificationList';
  list: Array<Notification>;
  totalCount: Scalars['Int']['output'];
};

/** Interface for types that can be used as notification reference */
export type NotificationReference = {
  id?: Maybe<Scalars['ID']['output']>;
};

export enum NotificationType {
  FinishInsurancePayment = 'FINISH_INSURANCE_PAYMENT',
  RealmanExportFinished = 'REALMAN_EXPORT_FINISHED',
  RealmanExportPackageUpsell = 'REALMAN_EXPORT_PACKAGE_UPSELL',
  RealmanExportUpsellFinished = 'REALMAN_EXPORT_UPSELL_FINISHED',
  RealmanExportUpsellLong = 'REALMAN_EXPORT_UPSELL_LONG',
  RealmanExportUpsellShort = 'REALMAN_EXPORT_UPSELL_SHORT'
}

export enum OfferType {
  /** Prodej */
  Prodej = 'PRODEJ',
  /** Pronájem */
  Pronajem = 'PRONAJEM',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export type OrderInput = {
  /** Amount */
  amount: Scalars['Int']['input'];
  /** Apple Pay token */
  applePayToken?: InputMaybe<Scalars['String']['input']>;
  /** Payment channel */
  channel: PaymentChannel;
  /** Currency */
  currency: Currency;
  /** Google Pay auth code */
  googlePayAuthCode?: InputMaybe<Scalars['String']['input']>;
  /** Payment method */
  method: PaymentMethod;
  /** Page where the order was created */
  origin?: InputMaybe<OrderOrigin>;
  /** Locale where the order was created */
  originLocale: Locale;
  /** Purchased packages */
  packages: Array<InputMaybe<SelectedPackageInput>>;
  /** PayU card token */
  payUCardToken?: InputMaybe<Scalars['String']['input']>;
  /** Voucher name */
  voucherName?: InputMaybe<Scalars['String']['input']>;
};

export enum OrderOrigin {
  /** Advert insert */
  Insert = 'INSERT'
}

export type OrderPackage = {
  __typename?: 'OrderPackage';
  currency?: Maybe<Currency>;
  ehubCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  locale?: Maybe<Locale>;
  package?: Maybe<Package>;
  paymentPrice?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  referenceId?: Maybe<Scalars['ID']['output']>;
  referenceType?: Maybe<OrderPackageReferenceType>;
  title?: Maybe<Scalars['String']['output']>;
};


export type OrderPackageTitleArgs = {
  locale: Locale;
};

export enum OrderPackageReferenceType {
  /** Inzerát */
  Advert = 'ADVERT',
  /** Projekt */
  Project = 'PROJECT',
  /** Žádný */
  Undefined = 'UNDEFINED',
  /** Uživatel */
  User = 'USER'
}

export type OrderResponse = {
  __typename?: 'OrderResponse';
  amount?: Maybe<Scalars['Int']['output']>;
  channel?: Maybe<PaymentChannel>;
  error?: Maybe<Scalars['String']['output']>;
  intention?: Maybe<PaymentIntention>;
  mallPay?: Maybe<MallPayResponse>;
  method?: Maybe<PaymentMethod>;
  packages?: Maybe<Array<Maybe<SelectedPackage>>>;
  paymentId?: Maybe<Scalars['ID']['output']>;
  payu?: Maybe<PayUResponse>;
  status?: Maybe<Scalars['Boolean']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  voucherDiscount?: Maybe<Scalars['Int']['output']>;
  voucherName?: Maybe<Scalars['String']['output']>;
};

export enum Ownership {
  /** Družstevní */
  Druzstevni = 'DRUZSTEVNI',
  /** Obecní */
  Obecni = 'OBECNI',
  /** Osobní */
  Osobni = 'OSOBNI',
  /** Ostatní */
  Ostatni = 'OSTATNI',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export enum Penb {
  /** A - mimořádně úsporná */
  A = 'A',
  /** B - velmi úsporná */
  B = 'B',
  /** C - úsporná */
  C = 'C',
  /** D - méně úsporná */
  D = 'D',
  /** E - nehospodárná */
  E = 'E',
  /** F - velmi nehospodárná */
  F = 'F',
  /** G - mimořádně nehospodárná */
  G = 'G',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export type Package = {
  __typename?: 'Package';
  /** Štítek balíčku - např. "Nejvýhodnější" */
  badge?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Currency>;
  /** Defaultně předvybraný balíček */
  default: Scalars['Boolean']['output'];
  /** Popis balíčku */
  description?: Maybe<Scalars['String']['output']>;
  /** @deprecated Will be removed, changed to originalPrice */
  discount: Scalars['Int']['output'];
  /** Délka platnosti ve dnech */
  duration?: Maybe<Scalars['Int']['output']>;
  ehubCode?: Maybe<Scalars['String']['output']>;
  /** Patička balíčku - např. odkaz "S garancí, že to klapne" */
  footer?: Maybe<Link>;
  /** Poznámka pod čarou (zobrazena nad CTA) */
  footnote?: Maybe<Scalars['String']['output']>;
  freeTopCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  includedPackages?: Maybe<Array<Package>>;
  intention?: Maybe<PaymentIntention>;
  keyFeatures?: Maybe<Array<Maybe<PackageKeyFeature>>>;
  locale?: Maybe<Locale>;
  /** Loga k zobrazení v ceníku - např. partneři pro export skrze Realmana */
  logos?: Maybe<Array<Link>>;
  /** Původní cena - zobrazena jako přeškrtnutá (nahrazuje slevu) */
  originalPrice?: Maybe<Scalars['Int']['output']>;
  /** Text vedle škrtnuté ceny */
  originalPriceNote?: Maybe<Scalars['String']['output']>;
  /** Text tooltipu (i) vedle škrtnuté ceny */
  originalPriceTooltip?: Maybe<Scalars['String']['output']>;
  parametersJson?: Maybe<Scalars['String']['output']>;
  /** Cena */
  price: Scalars['Int']['output'];
  /** Priorita pro řazení */
  priority: Scalars['Int']['output'];
  promokitAvailable?: Maybe<Scalars['Boolean']['output']>;
  promokitFreeDelivery?: Maybe<Scalars['Boolean']['output']>;
  referenceOptions?: Maybe<Array<Maybe<PackageReferenceOption>>>;
  /** Název balíčku */
  title: Scalars['String']['output'];
  /**
   * Krátký název balíčku
   * @deprecated Will be removed
   */
  titleShort?: Maybe<Scalars['String']['output']>;
  uri: Scalars['String']['output'];
};


export type PackageBadgeArgs = {
  locale: Locale;
};


export type PackageDescriptionArgs = {
  locale: Locale;
};


export type PackageFooterArgs = {
  locale: Locale;
};


export type PackageFootnoteArgs = {
  locale: Locale;
};


export type PackageKeyFeaturesArgs = {
  locale: Locale;
};


export type PackageLogosArgs = {
  locale: Locale;
};


export type PackageOriginalPriceNoteArgs = {
  locale: Locale;
};


export type PackageOriginalPriceTooltipArgs = {
  locale: Locale;
};


export type PackageReferenceOptionsArgs = {
  locale: Locale;
};


export type PackageTitleArgs = {
  locale: Locale;
};


export type PackageTitleShortArgs = {
  locale: Locale;
};

export enum PackageCategory {
  /** Account packages */
  Account = 'ACCOUNT',
  /** Advert packages */
  Advert = 'ADVERT',
  /** Advert extend rent packages */
  AdvertExtendRent = 'ADVERT_EXTEND_RENT',
  /** Advert extend sale packages */
  AdvertExtendSale = 'ADVERT_EXTEND_SALE',
  /** Advert rent packages */
  AdvertRent = 'ADVERT_RENT',
  /** Advert sale packages */
  AdvertSale = 'ADVERT_SALE',
  /** Advert top packages for given advert */
  AdvertTop = 'ADVERT_TOP',
  /** Advert top packages */
  AdvertTopAll = 'ADVERT_TOP_ALL',
  /** Briz visualizations */
  Briz = 'BRIZ',
  /** Contract rent packages */
  ContractRent = 'CONTRACT_RENT',
  /** Contract sale packages */
  ContractSale = 'CONTRACT_SALE',
  /** Project */
  Project = 'PROJECT',
  /** Project activation */
  ProjectActivation = 'PROJECT_ACTIVATION',
  /** Project in attractive listings */
  ProjectAttractive = 'PROJECT_ATTRACTIVE',
  /** Project top */
  ProjectTop = 'PROJECT_TOP',
  /** Promokit delivery */
  PromokitDelivery = 'PROMOKIT_DELIVERY',
  /** Promokit print */
  PromokitPrint = 'PROMOKIT_PRINT',
  /** Rent platform */
  RentPlatform = 'RENT_PLATFORM',
  /** Service center packages */
  ServiceCenter = 'SERVICE_CENTER'
}

export enum PackageFeatureIcon {
  /** Check icon */
  Check = 'CHECK',
  /** Cross icon */
  Cross = 'CROSS'
}

export type PackageKeyFeature = {
  __typename?: 'PackageKeyFeature';
  content?: Maybe<Scalars['String']['output']>;
  contentExpanded?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<PackageFeatureIcon>;
  key?: Maybe<Scalars['String']['output']>;
  tooltip?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type PackageReferenceOption = {
  __typename?: 'PackageReferenceOption';
  id?: Maybe<Scalars['ID']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Page = {
  __typename?: 'Page';
  header?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locale: Locale;
  metaDescription?: Maybe<Scalars['String']['output']>;
  /** @deprecated To be removed */
  metaKeywords?: Maybe<Scalars['String']['output']>;
  metaRobots?: Maybe<Scalars['String']['output']>;
  suburi?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  uri?: Maybe<Scalars['String']['output']>;
};

export enum PageInfoType {
  /** Privacy policy */
  PrivacyPolicy = 'PRIVACY_POLICY',
  /** Privacy policy - advert */
  PrivacyPolicyAdvert = 'PRIVACY_POLICY_ADVERT',
  /** Privacy policy - advert - Comfort */
  PrivacyPolicyAdvertComfort = 'PRIVACY_POLICY_ADVERT_COMFORT',
  /** Privacy policy - advert - Optimal/Complete */
  PrivacyPolicyAdvertOptimalComplete = 'PRIVACY_POLICY_ADVERT_OPTIMAL_COMPLETE',
  /** Privacy policy - cleanup */
  PrivacyPolicyCleanup = 'PRIVACY_POLICY_CLEANUP',
  /** Privacy policy - contract rent online */
  PrivacyPolicyContractRentOnline = 'PRIVACY_POLICY_CONTRACT_RENT_ONLINE',
  /** Privacy policy - insurance */
  PrivacyPolicyInsurance = 'PRIVACY_POLICY_INSURANCE',
  /** Privacy policy - photo */
  PrivacyPolicyPhoto = 'PRIVACY_POLICY_PHOTO',
  /** Privacy policy - registration */
  PrivacyPolicyRegistration = 'PRIVACY_POLICY_REGISTRATION',
  /** Privacy policy - paid registration */
  PrivacyPolicyRegistrationPaid = 'PRIVACY_POLICY_REGISTRATION_PAID',
  /** Privacy policy - service */
  PrivacyPolicyService = 'PRIVACY_POLICY_SERVICE',
  /** Terms and conditions */
  TermsAndConditions = 'TERMS_AND_CONDITIONS'
}

export type PageList = {
  __typename?: 'PageList';
  list?: Maybe<Array<Maybe<Page>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum PayUMethodStatus {
  /** ACTIVE */
  Active = 'ACTIVE',
  /** DISABLED */
  Disabled = 'DISABLED',
  /** ENABLED */
  Enabled = 'ENABLED',
  /** EXPIRED */
  Expired = 'EXPIRED',
  /** TEMPORARY_DISABLED */
  TemporaryDisabled = 'TEMPORARY_DISABLED'
}

export type PayUOrderResponse = {
  __typename?: 'PayUOrderResponse';
  orderId?: Maybe<Scalars['String']['output']>;
  redirectUri?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PayUOrderResponseStatus>;
};

export type PayUOrderResponseStatus = {
  __typename?: 'PayUOrderResponseStatus';
  statusCode?: Maybe<PayUOrderResponseStatusCode>;
};

export enum PayUOrderResponseStatusCode {
  /** ERROR */
  Error = 'ERROR',
  /** ERROR_CARD */
  ErrorCard = 'ERROR_CARD',
  /** ERROR_PBL */
  ErrorPbl = 'ERROR_PBL',
  /** SUCCESS_3DS */
  Success_3Ds = 'SUCCESS_3DS',
  /** SUCCESS_PBL */
  SuccessPbl = 'SUCCESS_PBL',
  /** WARNING_CONTINUE_3DS */
  WarningContinue_3Ds = 'WARNING_CONTINUE_3DS',
  /** WARNING_CONTINUE_CVV */
  WarningContinueCvv = 'WARNING_CONTINUE_CVV',
  /** WARNING_CONTINUE_PBL */
  WarningContinuePbl = 'WARNING_CONTINUE_PBL'
}

export type PayUResponse = {
  __typename?: 'PayUResponse';
  action?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['Int']['output']>;
  client_ip?: Maybe<Scalars['String']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  order_id?: Maybe<Scalars['String']['output']>;
  pay_type?: Maybe<Scalars['String']['output']>;
  payu_order_response?: Maybe<PayUOrderResponse>;
  pos_auth_key?: Maybe<Scalars['String']['output']>;
  pos_id?: Maybe<Scalars['String']['output']>;
  session_id?: Maybe<Scalars['Int']['output']>;
  sig?: Maybe<Scalars['String']['output']>;
  ts?: Maybe<Scalars['String']['output']>;
};

export type PayUResponseStatus = {
  __typename?: 'PayUResponseStatus';
  statusCode?: Maybe<PayUResponseStatusCode>;
  statusDesc?: Maybe<Scalars['String']['output']>;
};

export enum PayUResponseStatusCode {
  /** CANCELED */
  Canceled = 'CANCELED',
  /** COMPLETED */
  Completed = 'COMPLETED',
  /** NEW */
  New = 'NEW',
  /** PENDING */
  Pending = 'PENDING',
  /** REJECTED */
  Rejected = 'REJECTED',
  /** SUCCESS */
  Success = 'SUCCESS',
  /** WAITING_FOR_CONFIRMATION */
  WaitingForConfirmation = 'WAITING_FOR_CONFIRMATION'
}

export type Payment = {
  __typename?: 'Payment';
  advert?: Maybe<Advert>;
  channel?: Maybe<PaymentChannel>;
  currency?: Maybe<Currency>;
  dataLayerJson?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  intention?: Maybe<PaymentIntention>;
  intentionStr?: Maybe<Scalars['String']['output']>;
  isWalletWithdrawRequest?: Maybe<Scalars['Boolean']['output']>;
  mainPackage?: Maybe<SelectedPackage>;
  method?: Maybe<PaymentMethod>;
  pdfUrl?: Maybe<Scalars['String']['output']>;
  qrCode?: Maybe<Scalars['String']['output']>;
  signedAmount?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<PaymentStatus>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeProcessed?: Maybe<Scalars['DateTime']['output']>;
};


export type PaymentIntentionStrArgs = {
  locale: Locale;
};


export type PaymentQrCodeArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
};

export enum PaymentChannel {
  /** Google Pay */
  Ap = 'AP',
  /** PayU-bt (Bankovní převod) */
  Bt = 'BT',
  /** PayU-c (Platební karta) */
  C = 'C',
  /** PayU-cb (ČSOB) */
  Cb = 'CB',
  /** PayU-cbs (ČSOB) */
  Cbs = 'CBS',
  /** PayU-ceko (Československá obchodná banka) */
  Ceko = 'CEKO',
  /** PayU-cs (Platba 24) */
  Cs = 'CS',
  /** PayU-css (Platba 24) */
  Css = 'CSS',
  /** PayU-era (ERA) */
  Era = 'ERA',
  /** Apple Pay */
  Jp = 'JP',
  /** PayU-kb (Komerční banka) */
  Kb = 'KB',
  /** PayU-kbs (Komerční banka) */
  Kbs = 'KBS',
  /** Masterpass */
  Ma = 'MA',
  /** PayU-mo (Mobito) */
  Mo = 'MO',
  /** PayU-mons (Moneta Money Bank) */
  Mons = 'MONS',
  /** PayU-mb (mPeníze) */
  Mp = 'MP',
  /** PayU-pf (FIO banka) */
  Pf = 'PF',
  /** PayU-pfs (Fio banka) */
  Pfs = 'PFS',
  /** PayU-pg (Moneta Money Bank) */
  Pg = 'PG',
  /** PayU-posta (Poštová banka) */
  Posta = 'POSTA',
  /** PayU-pt (Složenka) */
  Pt = 'PT',
  /** PayU-pv (Sberbank) */
  Pv = 'PV',
  /** PayU-rbczs (Raiffeisenbank) */
  Rbczs = 'RBCZS',
  /** PayU-rf (ePlatba) */
  Rf = 'RF',
  /** PayU-sporo (Sporopay) */
  Sporo = 'SPORO',
  /** PayU-t (Testovací) */
  T = 'T',
  /** PayU-tatra (Tatrabank) */
  Tatra = 'TATRA',
  /** PayU-uc (Unicredi) */
  Uc = 'UC',
  /** Undefined */
  Undefined = 'UNDEFINED',
  /** PayU-uni (UniCredit) */
  Uni = 'UNI',
  /** PayU-viamo (Viamo) */
  Viamo = 'VIAMO',
  /** PayU-vub (ePlatby VÚB) */
  Vub = 'VUB'
}

export enum PaymentIntention {
  /** Pay for account */
  PayAccount = 'PAY_ACCOUNT',
  /** Activate or highlight an advert */
  PayAdvert = 'PAY_ADVERT',
  /** Pay for a Premium account */
  PayPremiumAccount = 'PAY_PREMIUM_ACCOUNT',
  /** Pay for a project */
  PayProject = 'PAY_PROJECT',
  /** Rent platform */
  PayRentPlatform = 'PAY_RENT_PLATFORM',
  /** Pay for a package from Service Center */
  PayService = 'PAY_SERVICE',
  /** Charge wallet */
  WalletCharge = 'WALLET_CHARGE',
  /** Withdraw wallet */
  WalletWithdraw = 'WALLET_WITHDRAW'
}

export type PaymentList = {
  __typename?: 'PaymentList';
  list?: Maybe<Array<Maybe<Payment>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum PaymentListType {
  /** Wallet type payments */
  Wallet = 'WALLET'
}

export enum PaymentMethod {
  Campaign = 'CAMPAIGN',
  Cash = 'CASH',
  Invoice = 'INVOICE',
  InvoiceProforma = 'INVOICE_PROFORMA',
  MallPay = 'MALL_PAY',
  Payu = 'PAYU',
  ProblemSolved = 'PROBLEM_SOLVED',
  Reclaim = 'RECLAIM',
  ReclaimBasicAccount = 'RECLAIM_BASIC_ACCOUNT',
  ReclaimPremiumAccount = 'RECLAIM_PREMIUM_ACCOUNT',
  ReclaimService = 'RECLAIM_SERVICE',
  Sms = 'SMS',
  Wallet = 'WALLET'
}

export type PaymentSettings = {
  __typename?: 'PaymentSettings';
  testPaymentAvailable?: Maybe<Scalars['Boolean']['output']>;
};

export enum PaymentStatus {
  Canceled = 'CANCELED',
  Error = 'ERROR',
  NotProcessed = 'NOT_PROCESSED',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
  Unknown = 'UNKNOWN'
}

export type PayuCardToken = {
  __typename?: 'PayuCardToken';
  brandImageUrl?: Maybe<Scalars['String']['output']>;
  cardExpirationMonth?: Maybe<Scalars['String']['output']>;
  cardExpirationYear?: Maybe<Scalars['String']['output']>;
  cardNumberMasked?: Maybe<Scalars['String']['output']>;
  cardScheme?: Maybe<Scalars['String']['output']>;
  preferred?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<PayUMethodStatus>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum PayuOrderType {
  /** Last user account order */
  Account = 'ACCOUNT',
  /** Advert activation order */
  Advert = 'ADVERT'
}

export type PayuPayByLink = {
  __typename?: 'PayuPayByLink';
  brandImageUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PayUMethodStatus>;
  value?: Maybe<PaymentChannel>;
};

export enum Position {
  /** V bloku */
  Block = 'BLOCK',
  /** Rohový */
  Corner = 'CORNER',
  /** Samostatný */
  Detached = 'DETACHED',
  /** Řadový */
  Terraced = 'TERRACED',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export enum ProfileScoreType {
  /** Valid additional info */
  ValidAdditional = 'VALID_ADDITIONAL',
  /** Valid basic info */
  ValidBasic = 'VALID_BASIC',
  /** Valid Credticheck */
  ValidCreditcheck = 'VALID_CREDITCHECK',
  /** Valid phone */
  ValidPhone = 'VALID_PHONE',
  /** Valid photo */
  ValidPhoto = 'VALID_PHOTO'
}

export type Project = {
  __typename?: 'Project';
  active?: Maybe<Scalars['Boolean']['output']>;
  adverts?: Maybe<AdvertList>;
  dataJson?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gps?: Maybe<GpsPoint>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  photoFilename?: Maybe<Scalars['String']['output']>;
  stats?: Maybe<ProjectStats>;
  timeActivated?: Maybe<Scalars['DateTime']['output']>;
  timeArchived?: Maybe<Scalars['DateTime']['output']>;
  timeAttractiveExpiration?: Maybe<Scalars['DateTime']['output']>;
  timeExpiration?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};


export type ProjectPhotoArgs = {
  filter: ImageFilter;
};

export enum ProjectAdvertAction {
  /** Deactivate */
  Deactivate = 'DEACTIVATE',
  /** Reactivate */
  Reactivate = 'REACTIVATE',
  /** Reserve */
  Reserve = 'RESERVE',
  /** Top */
  Top = 'TOP',
  /** Unreserve */
  Unreserve = 'UNRESERVE'
}

export type ProjectStats = {
  __typename?: 'ProjectStats';
  conversationCount?: Maybe<Scalars['Int']['output']>;
  cost?: Maybe<Scalars['Int']['output']>;
  statsDaily?: Maybe<Array<Maybe<AdvertStatsDaily>>>;
  topCount?: Maybe<Scalars['Float']['output']>;
  visitCount?: Maybe<Scalars['Int']['output']>;
};

export enum PromokitDeliveryType {
  /** No promokit */
  No = 'NO',
  /** Send by post */
  Post = 'POST',
  /** User will collect the promokit by himself */
  Visit = 'VISIT'
}

export enum PushNotificationType {
  Messages = 'MESSAGES',
  Test = 'TEST',
  Warnings = 'WARNINGS',
  Watchdogs = 'WATCHDOGS'
}

export type PushSubscription = {
  __typename?: 'PushSubscription';
  deviceName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  pushSubscriptionJSON?: Maybe<Scalars['String']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
};

export type Query = {
  __typename?: 'Query';
  advert?: Maybe<Advert>;
  advertEvent?: Maybe<AdvertEvent>;
  advertEventList?: Maybe<AdvertEventList>;
  advertFilterOptions?: Maybe<AdvertFilterOptions>;
  advertMarkers?: Maybe<Array<Maybe<AdvertMarker>>>;
  advertParameters?: Maybe<Array<Maybe<AdvertParameter>>>;
  advertPriceBidList?: Maybe<AdvertPriceBidList>;
  advertPriceRange?: Maybe<Array<Maybe<AdvertPriceRange>>>;
  advertUserstate?: Maybe<AdvertUserstate>;
  advertUserstateList?: Maybe<AdvertUserstateList>;
  /** Get authenticated user */
  authUser?: Maybe<User>;
  /** Get JSON of authenticated user */
  authUserJson?: Maybe<Scalars['String']['output']>;
  availablePaymentMethods?: Maybe<AvailablePaymentMethods>;
  blogArticle?: Maybe<BlogArticle>;
  blogArticleList?: Maybe<BlogArticleList>;
  blogAuthor?: Maybe<BlogAuthor>;
  blogAuthorList?: Maybe<BlogAuthorList>;
  blogNewsArticleList?: Maybe<BlogArticleList>;
  blogTag?: Maybe<BlogTag>;
  blogTagList?: Maybe<BlogTagList>;
  brizImageVariant?: Maybe<ImageVariant>;
  brokerList?: Maybe<BrokerList>;
  calendarEntry?: Maybe<CalendarEntry>;
  calendarEntryCustomer?: Maybe<CalendarEntryCustomer>;
  calendarEntryCustomerList?: Maybe<CalendarEntryCustomerList>;
  calendarEntryList?: Maybe<CalendarEntryList>;
  calendarEntryOwnerList?: Maybe<CalendarEntryCustomerList>;
  calendarEvent?: Maybe<CalendarEvent>;
  calendarEventList?: Maybe<CalendarEventList>;
  checkAdvertDescription?: Maybe<DescriptionCheckResult>;
  checkEmail?: Maybe<Scalars['Boolean']['output']>;
  contractGeneratorInfo?: Maybe<Scalars['String']['output']>;
  conversation?: Maybe<Conversation>;
  conversationList?: Maybe<ConversationList>;
  countryByGps?: Maybe<Country>;
  customerAdverts?: Maybe<Array<Maybe<Advert>>>;
  czechRegions?: Maybe<Array<Maybe<Region>>>;
  documentList?: Maybe<DocumentList>;
  email?: Maybe<Scalars['String']['output']>;
  /** Estimates adverts price */
  estimateAdvertPrice?: Maybe<AdvertPriceEstimation>;
  gallery?: Maybe<Gallery>;
  galleryList?: Maybe<Array<Maybe<Gallery>>>;
  /** Generate advert description */
  generateAdvertDescription?: Maybe<Scalars['String']['output']>;
  /** Generate image description */
  generateImageDescription?: Maybe<Scalars['String']['output']>;
  getLastSearchCount?: Maybe<Scalars['Int']['output']>;
  getUserIdByDevice?: Maybe<Scalars['Int']['output']>;
  infoBanner?: Maybe<Scalars['String']['output']>;
  insuranceContractList: Array<InsuranceContract>;
  insuranceDocumentUrls: Array<InsuranceDocument>;
  insurancePaymentUrl?: Maybe<Scalars['String']['output']>;
  insurancePrice?: Maybe<InsuranceUpsell>;
  isTSRegionFromGps?: Maybe<Scalars['Boolean']['output']>;
  layoutStats?: Maybe<LayoutStats>;
  listAdverts?: Maybe<AdvertList>;
  listHrefLangs?: Maybe<Array<Maybe<HrefLang>>>;
  listNews?: Maybe<NewsList>;
  listRegions?: Maybe<Array<Maybe<Region>>>;
  listServices?: Maybe<ServiceList>;
  listSimilarAdverts?: Maybe<AdvertList>;
  message?: Maybe<Message>;
  messageList?: Maybe<MessageList>;
  nemoreportHouseUnits?: Maybe<NemoreportHouseUnitList>;
  news?: Maybe<News>;
  notification?: Maybe<Notification>;
  notificationList?: Maybe<NotificationList>;
  package?: Maybe<Package>;
  packageList?: Maybe<Array<Package>>;
  pageInfo?: Maybe<Page>;
  pageList?: Maybe<PageList>;
  parameter?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<Payment>;
  paymentList?: Maybe<PaymentList>;
  paymentScheduledTotalPrice?: Maybe<Scalars['Int']['output']>;
  paymentSettings?: Maybe<PaymentSettings>;
  payuOrderStatus?: Maybe<PayUResponseStatus>;
  project?: Maybe<Project>;
  projectList?: Maybe<Array<Maybe<Project>>>;
  region?: Maybe<Region>;
  regionByUri?: Maybe<Region>;
  rentBankAccount?: Maybe<RentBankAccount>;
  rentBankAccountList?: Maybe<RentBankAccountList>;
  rentContract?: Maybe<RentContract>;
  rentCost?: Maybe<RentCost>;
  rentEquipment?: Maybe<RentEquipment>;
  rentNote?: Maybe<RentNote>;
  rentPayment?: Maybe<RentPayment>;
  rentPaymentList?: Maybe<RentPaymentList>;
  rentProperty?: Maybe<Advert>;
  rentPropertyList?: Maybe<RentPropertyList>;
  rentRepair?: Maybe<RentRepair>;
  rentService?: Maybe<RentService>;
  rentStats?: Maybe<RentStats>;
  rentTenant?: Maybe<RentTenant>;
  rentTenantList?: Maybe<RentTenantList>;
  /**
   * Returns server time (for time sync)
   *
   */
  serverTimestamp?: Maybe<Scalars['Int']['output']>;
  service?: Maybe<Service>;
  share?: Maybe<Share>;
  shortUrl?: Maybe<Scalars['String']['output']>;
  supportedBanks?: Maybe<Array<Maybe<SupportedBank>>>;
  /** Get supported phone prefixes */
  supportedPhonePrefixes: Array<Scalars['Int']['output']>;
  /** Translate advert description */
  translateAdvertDescription?: Maybe<Scalars['String']['output']>;
  upcLink?: Maybe<FormattedParameter>;
  user?: Maybe<User>;
  voucherDiscount?: Maybe<Scalars['Int']['output']>;
  watchdog?: Maybe<Watchdog>;
  watchdogAppResults?: Maybe<AdvertList>;
  watchdogList?: Maybe<WatchdogList>;
};


export type QueryAdvertArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdvertEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdvertEventListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  scheduled?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAdvertMarkersArgs = {
  activationAction?: InputMaybe<Array<InputMaybe<ActivationAction>>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  availableFrom?: InputMaybe<Scalars['DateTime']['input']>;
  balcony?: InputMaybe<Scalars['Boolean']['input']>;
  balconySurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  balconySurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  barrierFree?: InputMaybe<Scalars['Boolean']['input']>;
  boundary?: InputMaybe<Scalars['String']['input']>;
  boundaryPoints?: InputMaybe<Array<InputMaybe<GpsPointInput>>>;
  broker?: InputMaybe<Scalars['ID']['input']>;
  cellar?: InputMaybe<Scalars['Boolean']['input']>;
  cellarSurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  cellarSurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  charity?: InputMaybe<Scalars['Boolean']['input']>;
  condition?: InputMaybe<Array<InputMaybe<Condition>>>;
  construction?: InputMaybe<Array<InputMaybe<Construction>>>;
  currency?: InputMaybe<Currency>;
  developer?: InputMaybe<Scalars['Boolean']['input']>;
  discountedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  discountedOnlyByEstimate?: InputMaybe<Scalars['Boolean']['input']>;
  disposition?: InputMaybe<Array<InputMaybe<Disposition>>>;
  equipped?: InputMaybe<Array<InputMaybe<Equipped>>>;
  estateType?: InputMaybe<Array<InputMaybe<EstateType>>>;
  frontGardenSurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  frontGardenSurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  garage?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  importType?: InputMaybe<AdvertImportType>;
  includeImports?: InputMaybe<Scalars['Boolean']['input']>;
  includeShortTerm?: InputMaybe<Scalars['Boolean']['input']>;
  landType?: InputMaybe<Array<InputMaybe<LandType>>>;
  lift?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locationPoint?: InputMaybe<GpsPointInput>;
  locationRadius?: InputMaybe<Scalars['Int']['input']>;
  loggia?: InputMaybe<Scalars['Boolean']['input']>;
  loggiaSurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  loggiaSurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  lowEnergy?: InputMaybe<Scalars['Boolean']['input']>;
  newBuilding?: InputMaybe<Scalars['Boolean']['input']>;
  offerType?: InputMaybe<Array<InputMaybe<OfferType>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ResultOrder>;
  ownership?: InputMaybe<Array<InputMaybe<Ownership>>>;
  parking?: InputMaybe<Scalars['Boolean']['input']>;
  petFriendly?: InputMaybe<Scalars['Boolean']['input']>;
  polygonBuffer?: InputMaybe<Scalars['Int']['input']>;
  priceFrom?: InputMaybe<Scalars['Int']['input']>;
  priceTo?: InputMaybe<Scalars['Int']['input']>;
  project?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  regionId?: InputMaybe<Scalars['ID']['input']>;
  regionOsmId?: InputMaybe<Scalars['ID']['input']>;
  regionOsmIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  roommate?: InputMaybe<Scalars['Boolean']['input']>;
  searchPriceWithCharges?: InputMaybe<Scalars['Boolean']['input']>;
  surfaceFrom?: InputMaybe<Scalars['Int']['input']>;
  surfaceLandFrom?: InputMaybe<Scalars['Int']['input']>;
  surfaceLandTo?: InputMaybe<Scalars['Int']['input']>;
  surfaceTo?: InputMaybe<Scalars['Int']['input']>;
  terrace?: InputMaybe<Scalars['Boolean']['input']>;
  terraceSurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  terraceSurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
  withNemoreport?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAdvertParametersArgs = {
  locale: Locale;
  type: AdvertParameterName;
};


export type QueryAdvertPriceBidListArgs = {
  advertId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAdvertPriceRangeArgs = {
  locale: Locale;
};


export type QueryAdvertUserstateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdvertUserstateListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  state?: InputMaybe<AdvertUserstateState>;
  user: Scalars['ID']['input'];
};


export type QueryAuthUserJsonArgs = {
  type: UserJsonType;
};


export type QueryAvailablePaymentMethodsArgs = {
  advertId?: InputMaybe<Scalars['ID']['input']>;
  locale: Locale;
};


export type QueryBlogArticleArgs = {
  locale: Locale;
  slug: Scalars['String']['input'];
};


export type QueryBlogArticleListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: Locale;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<BlogArticleOrder>;
  tagSlugs?: InputMaybe<Array<Scalars['String']['input']>>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBlogAuthorArgs = {
  locale: Locale;
  slug: Scalars['String']['input'];
};


export type QueryBlogAuthorListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBlogNewsArticleListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: Locale;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBlogTagArgs = {
  locale: Locale;
  slug: Scalars['String']['input'];
};


export type QueryBlogTagListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale: Locale;
  offset?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<BlogTagType>;
};


export type QueryBrizImageVariantArgs = {
  batchId?: InputMaybe<Scalars['ID']['input']>;
  fillEmptyRooms: Scalars['Boolean']['input'];
  imageId: Scalars['ID']['input'];
  roomType: BrizRoomType;
  style: BrizStyle;
};


export type QueryCalendarEntryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCalendarEntryCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCalendarEntryCustomerListArgs = {
  advertId?: InputMaybe<Scalars['ID']['input']>;
  conversationId?: InputMaybe<Scalars['ID']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  upcoming?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCalendarEntryListArgs = {
  conversationId?: InputMaybe<Scalars['ID']['input']>;
  free?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  upcoming?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCalendarEntryOwnerListArgs = {
  free?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  upcoming?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryCalendarEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCalendarEventListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<CalendarEventRole>;
  timeFrom?: InputMaybe<Scalars['DateTime']['input']>;
  timeTo?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryCheckAdvertDescriptionArgs = {
  description: Scalars['String']['input'];
};


export type QueryCheckEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryConversationArgs = {
  advertId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryConversationListArgs = {
  advertId?: InputMaybe<Scalars['ID']['input']>;
  closed?: InputMaybe<Scalars['Boolean']['input']>;
  fromLastActivation?: InputMaybe<Scalars['Boolean']['input']>;
  fulltext?: InputMaybe<Scalars['String']['input']>;
  important?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  premium?: InputMaybe<Scalars['Boolean']['input']>;
  recentOnly?: InputMaybe<Scalars['Boolean']['input']>;
  unread?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
  userRole?: InputMaybe<ConversationRole>;
};


export type QueryCountryByGpsArgs = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};


export type QueryCzechRegionsArgs = {
  locale: Locale;
};


export type QueryDocumentListArgs = {
  entityId: Scalars['ID']['input'];
  entityType: EntityType;
};


export type QueryEmailArgs = {
  ulid: Scalars['String']['input'];
};


export type QueryEstimateAdvertPriceArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  balcony?: InputMaybe<Scalars['Boolean']['input']>;
  balconySurface?: InputMaybe<Scalars['Float']['input']>;
  cellarSurface?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  condition?: InputMaybe<Condition>;
  construction?: InputMaybe<Construction>;
  disposition?: InputMaybe<Disposition>;
  equipped?: InputMaybe<Equipped>;
  estateType: EstateType;
  etage?: InputMaybe<Scalars['Int']['input']>;
  gps?: InputMaybe<GpsPointInput>;
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  landType?: InputMaybe<LandType>;
  loggiaSurface?: InputMaybe<Scalars['Float']['input']>;
  lowEnergy?: InputMaybe<Scalars['Boolean']['input']>;
  newBuilding?: InputMaybe<Scalars['Boolean']['input']>;
  offerType: OfferType;
  ownership?: InputMaybe<Ownership>;
  penb?: InputMaybe<Penb>;
  roommate?: InputMaybe<Scalars['Boolean']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  surface?: InputMaybe<Scalars['Int']['input']>;
  surfaceLand?: InputMaybe<Scalars['Int']['input']>;
  terrace?: InputMaybe<Scalars['Boolean']['input']>;
  terraceSurface?: InputMaybe<Scalars['Float']['input']>;
  totalFloors?: InputMaybe<Scalars['Int']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGalleryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGalleryListArgs = {
  propertyId: Scalars['ID']['input'];
};


export type QueryGenerateAdvertDescriptionArgs = {
  parameters: AdvertDescriptionInput;
};


export type QueryGenerateImageDescriptionArgs = {
  imageId: Scalars['ID']['input'];
  locale: Locale;
  maxTokens?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetLastSearchCountArgs = {
  boundary?: InputMaybe<Scalars['String']['input']>;
  estateType?: InputMaybe<Array<InputMaybe<EstateType>>>;
  location?: InputMaybe<Scalars['String']['input']>;
  offerType?: InputMaybe<Array<InputMaybe<OfferType>>>;
  timeActivatedFrom?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetUserIdByDeviceArgs = {
  deviceId: Scalars['String']['input'];
};


export type QueryInfoBannerArgs = {
  locale: Locale;
};


export type QueryInsurancePaymentUrlArgs = {
  insuranceId: Scalars['ID']['input'];
};


export type QueryIsTsRegionFromGpsArgs = {
  gps?: InputMaybe<GpsPointInput>;
};


export type QueryListAdvertsArgs = {
  activationAction?: InputMaybe<Array<InputMaybe<ActivationAction>>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  availableFrom?: InputMaybe<Scalars['DateTime']['input']>;
  balcony?: InputMaybe<Scalars['Boolean']['input']>;
  balconySurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  balconySurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  barrierFree?: InputMaybe<Scalars['Boolean']['input']>;
  boundary?: InputMaybe<Scalars['String']['input']>;
  boundaryPoints?: InputMaybe<Array<InputMaybe<GpsPointInput>>>;
  broker?: InputMaybe<Scalars['ID']['input']>;
  cellar?: InputMaybe<Scalars['Boolean']['input']>;
  cellarSurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  cellarSurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  charity?: InputMaybe<Scalars['Boolean']['input']>;
  condition?: InputMaybe<Array<InputMaybe<Condition>>>;
  construction?: InputMaybe<Array<InputMaybe<Construction>>>;
  currency?: InputMaybe<Currency>;
  developer?: InputMaybe<Scalars['Boolean']['input']>;
  discountedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  discountedOnlyByEstimate?: InputMaybe<Scalars['Boolean']['input']>;
  disposition?: InputMaybe<Array<InputMaybe<Disposition>>>;
  equipped?: InputMaybe<Array<InputMaybe<Equipped>>>;
  estateType?: InputMaybe<Array<InputMaybe<EstateType>>>;
  estimationWatch?: InputMaybe<Array<InputMaybe<AdvertEstimationWatch>>>;
  frontGardenSurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  frontGardenSurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  garage?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  importType?: InputMaybe<AdvertImportType>;
  includeImports?: InputMaybe<Scalars['Boolean']['input']>;
  includeShortTerm?: InputMaybe<Scalars['Boolean']['input']>;
  landType?: InputMaybe<Array<InputMaybe<LandType>>>;
  lift?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Locale>;
  locationPoint?: InputMaybe<GpsPointInput>;
  locationRadius?: InputMaybe<Scalars['Int']['input']>;
  loggia?: InputMaybe<Scalars['Boolean']['input']>;
  loggiaSurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  loggiaSurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  lowEnergy?: InputMaybe<Scalars['Boolean']['input']>;
  newBuilding?: InputMaybe<Scalars['Boolean']['input']>;
  offerType?: InputMaybe<Array<InputMaybe<OfferType>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ResultOrder>;
  ownership?: InputMaybe<Array<InputMaybe<Ownership>>>;
  parking?: InputMaybe<Scalars['Boolean']['input']>;
  petFriendly?: InputMaybe<Scalars['Boolean']['input']>;
  polygonBuffer?: InputMaybe<Scalars['Int']['input']>;
  priceFrom?: InputMaybe<Scalars['Int']['input']>;
  priceTo?: InputMaybe<Scalars['Int']['input']>;
  project?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  regionId?: InputMaybe<Scalars['ID']['input']>;
  regionOsmId?: InputMaybe<Scalars['ID']['input']>;
  regionOsmIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  roommate?: InputMaybe<Scalars['Boolean']['input']>;
  searchPriceWithCharges?: InputMaybe<Scalars['Boolean']['input']>;
  surfaceFrom?: InputMaybe<Scalars['Int']['input']>;
  surfaceLandFrom?: InputMaybe<Scalars['Int']['input']>;
  surfaceLandTo?: InputMaybe<Scalars['Int']['input']>;
  surfaceTo?: InputMaybe<Scalars['Int']['input']>;
  terrace?: InputMaybe<Scalars['Boolean']['input']>;
  terraceSurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  terraceSurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  textSearch?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
  withImages?: InputMaybe<Scalars['Boolean']['input']>;
  withNemoreport?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryListHrefLangsArgs = {
  disposition?: InputMaybe<Disposition>;
  estateType: EstateType;
  offerType: OfferType;
  regionId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryListNewsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListRegionsArgs = {
  cached: Scalars['Boolean']['input'];
  locale: Locale;
};


export type QueryListServicesArgs = {
  category?: InputMaybe<PackageCategory>;
  includeFree?: InputMaybe<Scalars['Boolean']['input']>;
  packageUri?: InputMaybe<Scalars['String']['input']>;
};


export type QueryListSimilarAdvertsArgs = {
  activationAction?: InputMaybe<Array<InputMaybe<ActivationAction>>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  availableFrom?: InputMaybe<Scalars['DateTime']['input']>;
  balcony?: InputMaybe<Scalars['Boolean']['input']>;
  balconySurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  balconySurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  barrierFree?: InputMaybe<Scalars['Boolean']['input']>;
  boundary?: InputMaybe<Scalars['String']['input']>;
  boundaryPoints?: InputMaybe<Array<InputMaybe<GpsPointInput>>>;
  broker?: InputMaybe<Scalars['ID']['input']>;
  cellar?: InputMaybe<Scalars['Boolean']['input']>;
  cellarSurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  cellarSurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  charity?: InputMaybe<Scalars['Boolean']['input']>;
  condition?: InputMaybe<Array<InputMaybe<Condition>>>;
  construction?: InputMaybe<Array<InputMaybe<Construction>>>;
  currency?: InputMaybe<Currency>;
  developer?: InputMaybe<Scalars['Boolean']['input']>;
  discountedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  discountedOnlyByEstimate?: InputMaybe<Scalars['Boolean']['input']>;
  disposition?: InputMaybe<Array<InputMaybe<Disposition>>>;
  equipped?: InputMaybe<Array<InputMaybe<Equipped>>>;
  estateType?: InputMaybe<Array<InputMaybe<EstateType>>>;
  frontGardenSurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  frontGardenSurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  garage?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  importType?: InputMaybe<AdvertImportType>;
  includeImports?: InputMaybe<Scalars['Boolean']['input']>;
  includeShortTerm?: InputMaybe<Scalars['Boolean']['input']>;
  landType?: InputMaybe<Array<InputMaybe<LandType>>>;
  lift?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Locale>;
  locationPoint?: InputMaybe<GpsPointInput>;
  locationRadius?: InputMaybe<Scalars['Int']['input']>;
  loggia?: InputMaybe<Scalars['Boolean']['input']>;
  loggiaSurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  loggiaSurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  lowEnergy?: InputMaybe<Scalars['Boolean']['input']>;
  newBuilding?: InputMaybe<Scalars['Boolean']['input']>;
  offerType?: InputMaybe<Array<InputMaybe<OfferType>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ResultOrder>;
  ownership?: InputMaybe<Array<InputMaybe<Ownership>>>;
  parking?: InputMaybe<Scalars['Boolean']['input']>;
  petFriendly?: InputMaybe<Scalars['Boolean']['input']>;
  polygonBuffer?: InputMaybe<Scalars['Int']['input']>;
  priceFrom?: InputMaybe<Scalars['Int']['input']>;
  priceTo?: InputMaybe<Scalars['Int']['input']>;
  project?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  regionId?: InputMaybe<Scalars['ID']['input']>;
  regionOsmId?: InputMaybe<Scalars['ID']['input']>;
  regionOsmIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  roommate?: InputMaybe<Scalars['Boolean']['input']>;
  searchPriceWithCharges?: InputMaybe<Scalars['Boolean']['input']>;
  surfaceFrom?: InputMaybe<Scalars['Int']['input']>;
  surfaceLandFrom?: InputMaybe<Scalars['Int']['input']>;
  surfaceLandTo?: InputMaybe<Scalars['Int']['input']>;
  surfaceTo?: InputMaybe<Scalars['Int']['input']>;
  terrace?: InputMaybe<Scalars['Boolean']['input']>;
  terraceSurfaceFrom?: InputMaybe<Scalars['Float']['input']>;
  terraceSurfaceTo?: InputMaybe<Scalars['Float']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
  withImages?: InputMaybe<Scalars['Boolean']['input']>;
  withNemoreport?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryMessageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMessageListArgs = {
  conversation: Scalars['ID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNemoreportHouseUnitsArgs = {
  houseNumber: Scalars['String']['input'];
  ruianId: Scalars['String']['input'];
  street?: InputMaybe<Scalars['String']['input']>;
  town: Scalars['String']['input'];
  zip?: InputMaybe<Scalars['String']['input']>;
};


export type QueryNewsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNotificationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryNotificationListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  read?: InputMaybe<Scalars['Boolean']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPackageArgs = {
  uri: Scalars['String']['input'];
};


export type QueryPackageListArgs = {
  category?: InputMaybe<PackageCategory>;
  locale: Locale;
  recordId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPageInfoArgs = {
  locale: Locale;
  type?: InputMaybe<PageInfoType>;
};


export type QueryPageListArgs = {
  locale: Locale;
  suburi?: InputMaybe<Scalars['String']['input']>;
  uri?: InputMaybe<Scalars['String']['input']>;
};


export type QueryParameterArgs = {
  uri: Scalars['String']['input'];
};


export type QueryPaymentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPaymentListArgs = {
  type?: InputMaybe<PaymentListType>;
};


export type QueryPaymentScheduledTotalPriceArgs = {
  user: Scalars['ID']['input'];
};


export type QueryPayuOrderStatusArgs = {
  recordId?: InputMaybe<Scalars['ID']['input']>;
  type: PayuOrderType;
};


export type QueryProjectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProjectListArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  authUser?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryRegionArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Locale>;
};


export type QueryRegionByUriArgs = {
  locale?: InputMaybe<Locale>;
  uri: Scalars['String']['input'];
};


export type QueryRentBankAccountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRentContractArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRentCostArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRentEquipmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRentNoteArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRentPaymentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRentPaymentListArgs = {
  advertId?: InputMaybe<Scalars['ID']['input']>;
  overdue?: InputMaybe<Scalars['Boolean']['input']>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  tenantId?: InputMaybe<Scalars['ID']['input']>;
  timeFrom?: InputMaybe<Scalars['DateTime']['input']>;
  timeTo?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryRentPropertyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRentPropertyListArgs = {
  hasContract?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryRentRepairArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRentServiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRentStatsArgs = {
  advertId?: InputMaybe<Scalars['ID']['input']>;
  year: Scalars['Int']['input'];
};


export type QueryRentTenantArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRentTenantListArgs = {
  advert?: InputMaybe<Scalars['ID']['input']>;
  contractStatus?: InputMaybe<RentPlatformContractStatus>;
  hasContract?: InputMaybe<Scalars['Boolean']['input']>;
  tenantStatus?: InputMaybe<RentPlatformTenantStatus>;
};


export type QueryServiceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryShareArgs = {
  hash: Scalars['String']['input'];
};


export type QueryShortUrlArgs = {
  longUrl: Scalars['String']['input'];
};


export type QueryTranslateAdvertDescriptionArgs = {
  description: Scalars['String']['input'];
  locale: Locale;
};


export type QueryUpcLinkArgs = {
  advertId: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVoucherDiscountArgs = {
  packages: Array<InputMaybe<SelectedPackageInput>>;
  voucherName: Scalars['String']['input'];
};


export type QueryWatchdogArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWatchdogAppResultsArgs = {
  id: Scalars['ID']['input'];
  interval?: InputMaybe<DateIntervalEnum>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  polygonFormatted?: InputMaybe<GpsPolygonInput>;
};


export type QueryWatchdogListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export enum Reconstruction {
  /** vše */
  All = 'ALL',
  /** jádro */
  Core = 'CORE',
  /** exteriér */
  External = 'EXTERNAL',
  /** interiér */
  Internal = 'INTERNAL',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export type Region = {
  __typename?: 'Region';
  boundary?: Maybe<Scalars['String']['output']>;
  boundaryGeoJson?: Maybe<Scalars['String']['output']>;
  children?: Maybe<Array<Maybe<Region>>>;
  googleName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idPath?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  locative?: Maybe<Scalars['String']['output']>;
  lvl?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  osmId?: Maybe<Scalars['Int']['output']>;
  parent?: Maybe<Region>;
  simplifiedMapBoundary?: Maybe<Scalars['String']['output']>;
  subType?: Maybe<RegionSubtype>;
  type?: Maybe<RegionType>;
  uri?: Maybe<Scalars['String']['output']>;
};

export enum RegionSubtype {
  City = 'CITY',
  CityDistrict = 'CITY_DISTRICT',
  Country = 'COUNTRY',
  County = 'COUNTY',
  Municipality = 'MUNICIPALITY',
  Region = 'REGION',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export enum RegionType {
  Administrative = 'ADMINISTRATIVE',
  CityDistrict = 'CITY_DISTRICT',
  Geographic = 'GEOGRAPHIC',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export type RegistrationInput = {
  /** Broker checkbox */
  broker: Scalars['Boolean']['input'];
  /** City name */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Company DIC */
  companyDic?: InputMaybe<Scalars['String']['input']>;
  /** Company IC */
  companyIc?: InputMaybe<Scalars['Int']['input']>;
  /** Company name */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** Company type */
  companyType?: InputMaybe<UserGroupKeyType>;
  /** Company URL */
  companyUrl?: InputMaybe<Scalars['String']['input']>;
  /** Conditions checkbox */
  conditions: Scalars['Boolean']['input'];
  /** Ehub code */
  ehub?: InputMaybe<Scalars['String']['input']>;
  /** Email address */
  email: Scalars['String']['input'];
  /** First name */
  firstname: Scalars['String']['input'];
  /** Google ID token */
  googleIdToken?: InputMaybe<Scalars['String']['input']>;
  /** House number */
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  /** User is owner */
  isOwner?: InputMaybe<Scalars['Boolean']['input']>;
  /** Last name */
  lastname: Scalars['String']['input'];
  /** Locale */
  locale: Locale;
  /** Newsletter checkbox */
  newsletter?: InputMaybe<Scalars['Boolean']['input']>;
  /** User password */
  password?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phone: Scalars['String']['input'];
  /** Phone verification code */
  phoneVerificationCode?: InputMaybe<Scalars['String']['input']>;
  /** Phone verification record ID */
  phoneVerificationId?: InputMaybe<Scalars['Int']['input']>;
  /** Street name */
  street?: InputMaybe<Scalars['String']['input']>;
  /** Type */
  type: RegistrationType;
  /** Zip code */
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum RegistrationType {
  /** Právnická osoba */
  Legal = 'LEGAL',
  /** Fyzická osoba */
  Private = 'PRIVATE'
}

export type RelatedAdvertParameters = {
  __typename?: 'RelatedAdvertParameters';
  advertType?: Maybe<AdvertType>;
  disposition?: Maybe<Array<Maybe<Disposition>>>;
  estateType?: Maybe<EstateType>;
  offerType?: Maybe<OfferType>;
  polygon?: Maybe<Scalars['String']['output']>;
  priceMax?: Maybe<Scalars['Int']['output']>;
  priceMin?: Maybe<Scalars['Int']['output']>;
};

export type RentBankAccount = {
  __typename?: 'RentBankAccount';
  bankName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  payments?: Maybe<Array<Maybe<RentPayment>>>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
};

export type RentBankAccountList = {
  __typename?: 'RentBankAccountList';
  list?: Maybe<Array<Maybe<RentBankAccount>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type RentContract = {
  __typename?: 'RentContract';
  advert?: Maybe<Advert>;
  cotenants?: Maybe<Array<Maybe<RentCotenant>>>;
  currency?: Maybe<Currency>;
  dayPaymentDue?: Maybe<Scalars['Int']['output']>;
  deposit?: Maybe<Scalars['Float']['output']>;
  documents?: Maybe<Array<Maybe<RentContractDocument>>>;
  id?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  priceServices?: Maybe<Scalars['Float']['output']>;
  property?: Maybe<Advert>;
  rentPayments?: Maybe<Array<Maybe<RentPayment>>>;
  tenant?: Maybe<RentTenant>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeEnd?: Maybe<Scalars['DateTime']['output']>;
  timeSigned?: Maybe<Scalars['DateTime']['output']>;
  timeStart?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
};

export type RentContractDocument = {
  __typename?: 'RentContractDocument';
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  reference?: Maybe<RentContract>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
};

export type RentCost = {
  __typename?: 'RentCost';
  advert?: Maybe<Advert>;
  currency?: Maybe<Currency>;
  frequency?: Maybe<RentPlatformCostFrequency>;
  id?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeDue?: Maybe<Scalars['DateTime']['output']>;
  timeFirstPayment?: Maybe<Scalars['DateTime']['output']>;
  timePaid?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RentCotenant = {
  __typename?: 'RentCotenant';
  dateBirth?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type RentCotenantInput = {
  dateBirth: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
};

export type RentDocument = {
  __typename?: 'RentDocument';
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  reference?: Maybe<Advert>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
};

export type RentEquipment = DocumentReference & GalleryReference & {
  __typename?: 'RentEquipment';
  advert?: Maybe<Advert>;
  currency?: Maybe<Currency>;
  discarded?: Maybe<Scalars['Boolean']['output']>;
  documents?: Maybe<Array<Maybe<RentEquipmentDocument>>>;
  galleries?: Maybe<Array<Maybe<Gallery>>>;
  id?: Maybe<Scalars['ID']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeDiscarded?: Maybe<Scalars['DateTime']['output']>;
  timePurchased?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RentEquipmentDocument = {
  __typename?: 'RentEquipmentDocument';
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  reference?: Maybe<RentEquipment>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
};

export type RentNote = {
  __typename?: 'RentNote';
  advert?: Maybe<Advert>;
  id?: Maybe<Scalars['ID']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RentPayment = {
  __typename?: 'RentPayment';
  amount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Currency>;
  id?: Maybe<Scalars['ID']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  method?: Maybe<RentPlatformPaymentMethod>;
  rentBankAccount?: Maybe<RentBankAccount>;
  rentContract?: Maybe<RentContract>;
  rentPaymentReminders?: Maybe<Array<Maybe<RentPaymentReminder>>>;
  status?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeDue?: Maybe<Scalars['DateTime']['output']>;
  timePaid?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<RentPlatformPaymentType>;
};

export type RentPaymentList = {
  __typename?: 'RentPaymentList';
  list?: Maybe<Array<Maybe<RentPayment>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type RentPaymentReminder = {
  __typename?: 'RentPaymentReminder';
  id?: Maybe<Scalars['ID']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  rentPayment?: Maybe<RentPayment>;
  text?: Maybe<Scalars['String']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
};

export enum RentPlatformAdvertStatus {
  /** Nepronajata */
  NotRented = 'NOT_RENTED',
  /** V přípravě */
  Preparation = 'PREPARATION',
  /** Připravena */
  Ready = 'READY',
  /** Pronajata */
  Rented = 'RENTED'
}

export enum RentPlatformContractStatus {
  /** Aktivní */
  Active = 'ACTIVE',
  /** Ukončena */
  Expired = 'EXPIRED'
}

export enum RentPlatformCostFrequency {
  /** Pololetně */
  HalfYear = 'HALF_YEAR',
  /** Měsíčně */
  Month = 'MONTH',
  /** Jednorázově */
  Once = 'ONCE',
  /** Čtvrtletně */
  QuarterYear = 'QUARTER_YEAR',
  /** Týdně */
  Week = 'WEEK',
  /** Ročně */
  Year = 'YEAR'
}

export enum RentPlatformPaymentMethod {
  /** Předem na účet */
  Bt = 'BT',
  /** Hotově */
  Cash = 'CASH'
}

export enum RentPlatformPaymentType {
  /** Záloha */
  Advance = 'ADVANCE',
  /** Kauce */
  Deposit = 'DEPOSIT',
  /** Jiné */
  Other = 'OTHER',
  /** Nájem */
  Rent = 'RENT',
  /** Za pokažení */
  Repair = 'REPAIR'
}

export enum RentPlatformRepairPayer {
  /** Majitel */
  Owner = 'OWNER',
  /** Nájemce */
  Tenant = 'TENANT'
}

export enum RentPlatformServicePayer {
  /** Majitel */
  Owner = 'OWNER',
  /** Nájemce */
  Tenant = 'TENANT'
}

export enum RentPlatformServiceType {
  /** Režie */
  Administration = 'ADMINISTRATION',
  /** Úklid budovy */
  Cleanup = 'CLEANUP',
  /** Elektřina */
  Electricity = 'ELECTRICITY',
  /** Odvoz odpadu */
  Garbage = 'GARBAGE',
  /** Plyn */
  Gas = 'GAS',
  /** Výtah */
  Lift = 'LIFT',
  /** Správa domu */
  Management = 'MANAGEMENT',
  /** Jiné */
  Other = 'OTHER',
  /** Vode */
  Water = 'WATER'
}

export enum RentPlatformTenantStatus {
  /** Platí se zpožděním */
  Delayed = 'DELAYED',
  /** Platí včas */
  OnTime = 'ON_TIME',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export type RentPropertyList = {
  __typename?: 'RentPropertyList';
  list?: Maybe<Array<Maybe<Advert>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type RentRepair = DocumentReference & GalleryReference & {
  __typename?: 'RentRepair';
  advert?: Maybe<Advert>;
  currency?: Maybe<Currency>;
  documents?: Maybe<Array<Maybe<RentRepairDocument>>>;
  galleries?: Maybe<Array<Maybe<Gallery>>>;
  id?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  payer?: Maybe<RentPlatformRepairPayer>;
  price?: Maybe<Scalars['Float']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timePaid?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type RentRepairDocument = {
  __typename?: 'RentRepairDocument';
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  reference?: Maybe<RentRepair>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
};

export type RentService = {
  __typename?: 'RentService';
  advert?: Maybe<Advert>;
  id?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  payer?: Maybe<RentPlatformServicePayer>;
  status?: Maybe<Scalars['Float']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeSubscribed?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<RentPlatformServiceType>;
};

export type RentStats = {
  __typename?: 'RentStats';
  loss?: Maybe<RentStatsLoss>;
  profit?: Maybe<RentStatsProfit>;
};

export type RentStatsLoss = {
  __typename?: 'RentStatsLoss';
  cost?: Maybe<Scalars['Int']['output']>;
  equipment?: Maybe<Scalars['Int']['output']>;
  repair?: Maybe<Scalars['Int']['output']>;
};

export type RentStatsProfit = {
  __typename?: 'RentStatsProfit';
  rent?: Maybe<Scalars['Int']['output']>;
};

export type RentTenant = {
  __typename?: 'RentTenant';
  birthNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  currentRentContract?: Maybe<RentContract>;
  dateBirth?: Maybe<Scalars['DateTime']['output']>;
  documents?: Maybe<Array<Maybe<RentTenantDocument>>>;
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  fullname?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  paymentIdentifier?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  rentContracts?: Maybe<Array<Maybe<RentContract>>>;
  rentRepairs?: Maybe<Array<Maybe<RentRepair>>>;
  rentServices?: Maybe<Array<Maybe<RentService>>>;
  status?: Maybe<RentPlatformTenantStatus>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  tenantCount?: Maybe<Scalars['Int']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type RentTenantDocument = {
  __typename?: 'RentTenantDocument';
  filename?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  reference?: Maybe<RentTenant>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
};

export type RentTenantList = {
  __typename?: 'RentTenantList';
  list?: Maybe<Array<Maybe<RentTenant>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum ResultOrder {
  /** Dle výše slevy */
  DiscountDesc = 'DISCOUNT_DESC',
  /** Nejlevnější */
  PriceAsc = 'PRICE_ASC',
  /** Nejdražší */
  PriceDesc = 'PRICE_DESC',
  /** Nejmenší */
  SurfaceAsc = 'SURFACE_ASC',
  /** Největší */
  SurfaceDesc = 'SURFACE_DESC',
  /** Nejnovější */
  TimeorderDesc = 'TIMEORDER_DESC'
}

export type SaveAdvertInput = {
  /**
   * Additional action to perform after saving changes
   *
   */
  action?: InputMaybe<ActivationAction>;
  /**
   * User defined address
   *
   */
  address?: InputMaybe<Scalars['String']['input']>;
  /** ID adresního bodu - deprecated */
  addressPointId?: InputMaybe<Scalars['String']['input']>;
  /**
   * Age
   *
   */
  age?: InputMaybe<Age>;
  /**
   * Time of possible move in
   *
   */
  availableFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /**
   * Balcony
   *
   */
  balcony?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Balcony surface
   *
   */
  balconySurface?: InputMaybe<Scalars['Float']['input']>;
  /** Barrier free (wheelchair access) */
  barrierFree?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Location boundary in JSON
   *
   */
  boundary?: InputMaybe<Scalars['String']['input']>;
  /**
   * ID of broker
   *
   */
  broker?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Cellar
   *
   */
  cellar?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Cellar surface
   *
   */
  cellarSurface?: InputMaybe<Scalars['Float']['input']>;
  /** Monthly charges (deprecated - use serviceCharges and utilityCharges instead) */
  charges?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Charity - Help Ukraine
   *
   */
  charity?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * City name
   *
   */
  city?: InputMaybe<Scalars['String']['input']>;
  /**
   * Developers code
   *
   */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Condition */
  condition?: InputMaybe<Condition>;
  /**
   * Construction type
   *
   */
  construction?: InputMaybe<Construction>;
  /** Currency */
  currency?: InputMaybe<Currency>;
  /** Bail */
  deposit?: InputMaybe<Scalars['Int']['input']>;
  /** Advert description */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Advert description in english */
  descriptionEnglish?: InputMaybe<Scalars['String']['input']>;
  /**
   * Disposition
   *
   */
  disposition?: InputMaybe<Disposition>;
  /**
   * State of equipment
   *
   */
  equipped?: InputMaybe<Equipped>;
  /** Type of estate */
  estateType?: InputMaybe<EstateType>;
  /** Estimation watch */
  estimationWatch?: InputMaybe<AdvertEstimationWatch>;
  /**
   * Floor
   *
   */
  etage?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Execution
   *
   */
  execution?: InputMaybe<Execution>;
  /** Fee */
  fee?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Floor
   *
   */
  floor?: InputMaybe<Floor>;
  /** Front garden */
  frontGarden?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Garage
   *
   */
  garage?: InputMaybe<Scalars['Boolean']['input']>;
  /** GPS location */
  gps?: InputMaybe<GpsPointInput>;
  /**
   * Heating
   *
   */
  heating?: InputMaybe<Heating>;
  /**
   * House number
   *
   */
  houseNumber?: InputMaybe<Scalars['String']['input']>;
  /** ID bytové jednotky */
  houseUnit?: InputMaybe<Scalars['String']['input']>;
  /** Advert ID (optional - can be used to insert new advert) */
  id?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Advert images containing array of uploaded images
   *
   */
  images?: InputMaybe<Array<InputMaybe<UploadedTmpImageInput>>>;
  /**
   * Land type
   *
   */
  landType?: InputMaybe<LandType>;
  /**
   * Lift
   *
   */
  lift?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Loggia
   *
   */
  loggia?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Loggia surface
   *
   */
  loggiaSurface?: InputMaybe<Scalars['Float']['input']>;
  /** Low energy */
  lowEnergy?: InputMaybe<Scalars['Boolean']['input']>;
  /** New building - to be removed */
  newBuilding?: InputMaybe<Scalars['Boolean']['input']>;
  /** Offer type */
  offerType?: InputMaybe<OfferType>;
  /**
   * Ownership
   *
   */
  ownership?: InputMaybe<Ownership>;
  /**
   * Parking
   *
   */
  parking?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * PENB class
   *
   */
  penb?: InputMaybe<Penb>;
  /** Pet friendly */
  petFriendly?: InputMaybe<Scalars['Boolean']['input']>;
  /** Position */
  position?: InputMaybe<Position>;
  /** Price */
  price?: InputMaybe<Scalars['Int']['input']>;
  /**
   * ID of project for assignment
   *
   */
  project?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Reconstruction
   *
   */
  reconstruction?: InputMaybe<Reconstruction>;
  /** Creditcheck is required */
  requireCreditcheck?: InputMaybe<Scalars['Boolean']['input']>;
  /** Insurance is required */
  requireInsurance?: InputMaybe<Scalars['Boolean']['input']>;
  /** Roommate */
  roommate?: InputMaybe<Scalars['Boolean']['input']>;
  /** RUIAN ID */
  ruianId?: InputMaybe<Scalars['String']['input']>;
  /** Monthly service charges */
  serviceCharges?: InputMaybe<Scalars['Int']['input']>;
  /** Information about service charges */
  serviceChargesNote?: InputMaybe<Scalars['String']['input']>;
  /**
   * Sewage
   *
   */
  sewage?: InputMaybe<Sewage>;
  /** Situation */
  situation?: InputMaybe<Situation>;
  /**
   * Street name
   *
   */
  street?: InputMaybe<Scalars['String']['input']>;
  /** Surface */
  surface?: InputMaybe<Scalars['Int']['input']>;
  /** Land surface */
  surfaceLand?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Terrace
   *
   */
  terrace?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Terrace surface
   *
   */
  terraceSurface?: InputMaybe<Scalars['Float']['input']>;
  /** Advert title */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Advert title in english */
  titleEnglish?: InputMaybe<Scalars['String']['input']>;
  /**
   * Total number of floors
   *
   */
  totalFloors?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Monthly utility charges
   *
   */
  utilityCharges?: InputMaybe<Scalars['Int']['input']>;
  /** Information about utility charges */
  utilityChargesNote?: InputMaybe<Scalars['String']['input']>;
  /**
   * Water
   *
   */
  water?: InputMaybe<Water>;
  /**
   * Zip code
   *
   */
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type SaveWatchdogInput = {
  /**
   * Desired advert type
   *
   */
  advertType: AdvertType;
  /**
   * Requires balcony
   *
   */
  balcony?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Maximal surface of balcony
   *
   */
  balconySurfaceMax?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Minimal surface of balcony
   *
   */
  balconySurfaceMin?: InputMaybe<Scalars['Float']['input']>;
  /** Barrier free (wheelchair access) */
  barrierFree?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Requires cellar
   *
   */
  cellar?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Maximal surface of cellar
   *
   */
  cellarSurfaceMax?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Minimal surface of cellar
   *
   */
  cellarSurfaceMin?: InputMaybe<Scalars['Float']['input']>;
  /** Condition */
  condition?: InputMaybe<Array<InputMaybe<Condition>>>;
  /**
   * Construction type
   *
   */
  construction?: InputMaybe<Array<InputMaybe<Construction>>>;
  /**
   * Price currency
   *
   */
  currency?: InputMaybe<Currency>;
  /**
   * Phrase to search in description
   *
   */
  description?: InputMaybe<Scalars['String']['input']>;
  /**
   * Possible dispositions
   *
   */
  disposition?: InputMaybe<Array<InputMaybe<Disposition>>>;
  /**
   * Emailing frequency
   *
   */
  emailFrequency: WatchdogFrequency;
  /**
   * Equipped state
   *
   */
  equipped?: InputMaybe<Array<InputMaybe<Equipped>>>;
  /**
   * Possible estate types
   *
   */
  estateType?: InputMaybe<Array<InputMaybe<EstateType>>>;
  /** Maximal front garden */
  frontGardenSurfaceMax?: InputMaybe<Scalars['Float']['input']>;
  /** Minimal front garden */
  frontGardenSurfaceMin?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Requires garage
   *
   */
  garage?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * GeoJSON encoded geometry
   *
   */
  geometryGeoJson?: InputMaybe<Scalars['String']['input']>;
  /**
   * Watchdog is specified using a map or a place-picker
   *
   */
  isSelectedOnMap?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Land typef
   *
   */
  landType?: InputMaybe<Array<InputMaybe<LandType>>>;
  /**
   * Requires lift
   *
   */
  lift?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Requires loggia
   *
   */
  loggia?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Maximal surface of loggia
   *
   */
  loggiaSurfaceMax?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Minimal surface of loggia
   *
   */
  loggiaSurfaceMin?: InputMaybe<Scalars['Float']['input']>;
  /** Low energy */
  lowEnergy?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Custom name
   *
   */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Requires new building - To be removed */
  newBuilding?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Desired offer type
   *
   */
  offerType: OfferType;
  /**
   * Ownership
   *
   */
  ownership?: InputMaybe<Array<InputMaybe<Ownership>>>;
  /**
   * Requires parking
   *
   */
  parking?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Pet friendly
   *
   */
  petFriendly?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Polygon buffer size [m]
   *
   */
  polygonBuffer?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Formatted polygon - mobile app
   *
   */
  polygonFormatted?: InputMaybe<GpsPolygonInput>;
  /**
   * JSON encoded polygons
   *
   */
  polygons?: InputMaybe<Scalars['GPSMultipolygon']['input']>;
  /** Position */
  position?: InputMaybe<Array<InputMaybe<Position>>>;
  /**
   * Maximal price
   *
   */
  priceMax?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Minimal price
   *
   */
  priceMin?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Desired region
   *
   */
  region?: InputMaybe<Scalars['ID']['input']>;
  /** Filters by multiple region OSM IDs */
  regionOsmIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** Roommate */
  roommate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Search price with charges */
  searchPriceWithCharges?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * User-selected address (mobile app)
   *
   */
  selectedAddress?: InputMaybe<Scalars['String']['input']>;
  /** Maximal land surface */
  surfaceLandMax?: InputMaybe<Scalars['Int']['input']>;
  /** Minimal land surface */
  surfaceLandMin?: InputMaybe<Scalars['Int']['input']>;
  /** Maximal surface */
  surfaceMax?: InputMaybe<Scalars['Int']['input']>;
  /** Minimal surface */
  surfaceMin?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Requires terrace
   *
   */
  terrace?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Maximal surface of terrace
   *
   */
  terraceSurfaceMax?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Minimal surface of terrace
   *
   */
  terraceSurfaceMin?: InputMaybe<Scalars['Float']['input']>;
  /**
   * Watchdog ID
   *
   */
  watchdogId?: InputMaybe<Scalars['ID']['input']>;
};

export type SelectedPackage = {
  __typename?: 'SelectedPackage';
  currency: Currency;
  ehubCode?: Maybe<Scalars['String']['output']>;
  form?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Locale>;
  price: Scalars['Int']['output'];
  promokit?: Maybe<SelectedPackagePromokit>;
  referenceId: Scalars['ID']['output'];
  referenceType: OrderPackageReferenceType;
  /**
   * Formatted date: unix timestamp
   *
   */
  timeDue?: Maybe<Scalars['Int']['output']>;
  /**
   * Time expiration
   *
   */
  timeExpiration?: Maybe<Scalars['DateTime']['output']>;
  timeNow?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Formatted top dates: unix timestamp
   *
   */
  topTimes?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  uri?: Maybe<Scalars['String']['output']>;
};

export type SelectedPackageInput = {
  currency: Currency;
  ehubCode?: InputMaybe<Scalars['String']['input']>;
  form?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Locale>;
  price: Scalars['Int']['input'];
  promokit?: InputMaybe<SelectedPackagePromokitInput>;
  referenceId: Scalars['ID']['input'];
  referenceType: OrderPackageReferenceType;
  /**
   * Formatted date: unix timestamp
   *
   */
  timeDue?: InputMaybe<Scalars['Int']['input']>;
  /**
   * Time expiration
   *
   */
  timeExpiration?: InputMaybe<Scalars['DateTime']['input']>;
  timeNow?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Formatted top dates: unix timestamp
   *
   */
  topTimes?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  uri?: InputMaybe<Scalars['String']['input']>;
};

export type SelectedPackagePromokit = {
  __typename?: 'SelectedPackagePromokit';
  address?: Maybe<Scalars['String']['output']>;
  delivery?: Maybe<PromokitDeliveryType>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SelectedPackagePromokitInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  delivery?: InputMaybe<PromokitDeliveryType>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Service = {
  __typename?: 'Service';
  creditcheck?: Maybe<Creditcheck>;
  id?: Maybe<Scalars['ID']['output']>;
  locale?: Maybe<Locale>;
  timeProcessed?: Maybe<Scalars['DateTime']['output']>;
  timeUserProcessed?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleShort?: Maybe<Scalars['String']['output']>;
  uri?: Maybe<Scalars['String']['output']>;
};

export type ServiceLead = {
  __typename?: 'ServiceLead';
  /** Email */
  email?: Maybe<Scalars['String']['output']>;
  /** Name */
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  /** Name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Message */
  message?: Maybe<Scalars['String']['output']>;
  /** Phone */
  phone?: Maybe<Scalars['String']['output']>;
  /** Service type */
  service?: Maybe<ServiceType>;
};

export type ServiceLeadInput = {
  /** JSON encoded additional parameters */
  data?: InputMaybe<Scalars['String']['input']>;
  /** Email */
  email: Scalars['String']['input'];
  /** Name */
  firstName: Scalars['String']['input'];
  /** Name */
  lastName: Scalars['String']['input'];
  /** Marketing consent */
  marketingConsent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Message */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Phone */
  phone: Scalars['String']['input'];
  /** Recaptcha token */
  recaptcha: Scalars['String']['input'];
  /** Advert ID */
  service: ServiceType;
};

export type ServiceList = {
  __typename?: 'ServiceList';
  list?: Maybe<Array<Maybe<Service>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum ServiceType {
  /** Uživatelské účty */
  Accounts = 'ACCOUNTS',
  /** Bezrealitky nájem */
  BzrRent = 'BZR_RENT',
  /** Komfort */
  Comfort = 'COMFORT',
  /** Nájemní smlouva */
  ContractRent = 'CONTRACT_RENT',
  /** Kupní smlouva */
  ContractSale = 'CONTRACT_SALE',
  /** Ověření uživatele */
  Creditcheck = 'CREDITCHECK',
  /** Odhad ceny */
  Estimation = 'ESTIMATION',
  /** Expres */
  Express = 'EXPRESS',
  /** Můj první byt */
  FirstFlat = 'FIRST_FLAT',
  /** Průvodce */
  Guide = 'GUIDE',
  /** Hypotéka */
  Mortgage = 'MORTGAGE',
  /** Hypotéka Ownest */
  MortgageOwnest = 'MORTGAGE_OWNEST',
  /** NEMO report */
  Nemoreport = 'NEMOREPORT',
  /** Okay nábytek */
  Okay = 'OKAY',
  /** PENB */
  Penb = 'PENB',
  /** Fotografie */
  Photo = 'PHOTO',
  /** Blesková rekonstrukce */
  Roomstory = 'ROOMSTORY',
  /** Virtuální prohlídka */
  Tour_360 = 'TOUR_360',
  /** Tvůj správce */
  TvujSpravce = 'TVUJ_SPRAVCE',
  /** TvůjSprávce LP */
  TvujSpravceLp = 'TVUJ_SPRAVCE_LP'
}

export enum Sewage {
  /** ČOV */
  Cov = 'COV',
  /** veřejná kanalizace */
  Public = 'PUBLIC',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export type Share = {
  __typename?: 'Share';
  favoriteAdverts?: Maybe<AdvertList>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeExpiration?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<ShareType>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum ShareType {
  /** Oblíbené */
  Favorite = 'FAVORITE'
}

export type SimpleImage = {
  __typename?: 'SimpleImage';
  size: Size;
  text?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export enum Situation {
  /** Rušná část */
  BusyPart = 'BUSY_PART',
  /** Centrum */
  Center = 'CENTER',
  /** Okraj */
  Edge = 'EDGE',
  /** Sídliště */
  HousingEstate = 'HOUSING_ESTATE',
  /** Samota */
  Isolated = 'ISOLATED',
  /** Klidná část */
  QuietPart = 'QUIET_PART',
  /** Polosamota */
  SemiDetached = 'SEMI_DETACHED',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export type Size = {
  __typename?: 'Size';
  height?: Maybe<Scalars['Int']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type SupportedBank = {
  __typename?: 'SupportedBank';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type TeleSale = {
  __typename?: 'TeleSale';
  advert?: Maybe<Advert>;
  id?: Maybe<Scalars['ID']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TeleSaleStatus>;
  subStatus?: Maybe<TeleSaleSubStatus>;
};

export enum TeleSaleStatus {
  /** Volat později */
  CallLater = 'CALL_LATER',
  /** Nedovoláno */
  Ignored = 'IGNORED',
  /** Nemá zájem */
  NotInterested = 'NOT_INTERESTED',
  /** Ostatní */
  Other = 'OTHER',
  /** K navolání */
  ToCall = 'TO_CALL',
  /** K předání kontaktu */
  ToContact = 'TO_CONTACT'
}

export enum TeleSaleSubStatus {
  /** Uzavřeno */
  Closed = 'CLOSED'
}

export type UpdateAdvertPriceBidInput = {
  /** Advert price bid ID */
  bidId: Scalars['ID']['input'];
  /** Offered price */
  offeredPrice: Scalars['Int']['input'];
};

export type UploadTmpImageResponse = {
  __typename?: 'UploadTmpImageResponse';
  error?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  originalUrl: Scalars['String']['output'];
  status?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type UploadedImageInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  main?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UploadedTmpImageInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  /** External image ID, e.g. from Briz */
  externalImageId?: InputMaybe<Scalars['ID']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  main?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  /** Original bzr image ID */
  originalImageId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['Boolean']['input']>;
  /** Uploaded image type/origin - null for normal photos, BRIZ for AI generated etc. */
  type?: InputMaybe<ImageType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type User = NotificationReference & {
  __typename?: 'User';
  /**
   * Check if an order for account activation has been created in the last 10 minutes.
   *
   */
  accountActivationPending?: Maybe<Scalars['Boolean']['output']>;
  accountType?: Maybe<AccountType>;
  address?: Maybe<Scalars['String']['output']>;
  advertToExpire?: Maybe<Advert>;
  adverts?: Maybe<AdvertList>;
  brizCount: Scalars['Int']['output'];
  brizCountTotal: Scalars['Int']['output'];
  /** List of Brokers */
  brokers?: Maybe<Array<Maybe<Broker>>>;
  calendarToken?: Maybe<Scalars['String']['output']>;
  calendarUrl?: Maybe<Scalars['String']['output']>;
  canAddWatchdog?: Maybe<Scalars['Boolean']['output']>;
  canSetAdvertFee?: Maybe<Scalars['Boolean']['output']>;
  canShowProfile?: Maybe<Scalars['Boolean']['output']>;
  checkStatus?: Maybe<CreditcheckCheckStatus>;
  city?: Maybe<Scalars['String']['output']>;
  creditcheck?: Maybe<Creditcheck>;
  education?: Maybe<Education>;
  ehub?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  enabledPushNotificationTypes?: Maybe<Array<Maybe<PushNotificationType>>>;
  externalInsuranceContractNumber?: Maybe<Scalars['String']['output']>;
  familyMembers?: Maybe<Scalars['Int']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  formattedParameters?: Maybe<Array<Maybe<FormattedParameter>>>;
  formattedParametersPublic?: Maybe<Array<Maybe<FormattedParameter>>>;
  google?: Maybe<Scalars['String']['output']>;
  hasAdditionalInfo?: Maybe<Scalars['Boolean']['output']>;
  hasBasicInfo?: Maybe<Scalars['Boolean']['output']>;
  hasFullAddress?: Maybe<Scalars['Boolean']['output']>;
  hasFullProfile?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  importType?: Maybe<AdvertImportType>;
  insuranceStatus?: Maybe<InsuranceStatus>;
  interest?: Maybe<UserInterest>;
  /** Is Broker admin */
  isBrokerAdmin?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated To be removed. Use checkStatus instead */
  isChecked?: Maybe<Scalars['Boolean']['output']>;
  isDeveloper?: Maybe<Scalars['Boolean']['output']>;
  isLegalEntity?: Maybe<Scalars['Boolean']['output']>;
  isOnline?: Maybe<Scalars['Boolean']['output']>;
  isOwner?: Maybe<Scalars['Boolean']['output']>;
  lastAdvertVisits?: Maybe<Array<Maybe<AdvertUserVisit>>>;
  lastname?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Locale>;
  manualInvoices?: Maybe<Scalars['Boolean']['output']>;
  marketingConsentStatus?: Maybe<MarketingConsentStatus>;
  menuCounts?: Maybe<MenuCounts>;
  nemoreports?: Maybe<Array<Maybe<Nemoreport>>>;
  newsletter?: Maybe<Scalars['Boolean']['output']>;
  /** User note (medailonek), max 500 characters */
  note?: Maybe<Scalars['String']['output']>;
  noteIsFilled?: Maybe<Scalars['Boolean']['output']>;
  pets?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneCountry?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberInternationalFormatted?: Maybe<Scalars['String']['output']>;
  phonePrefix?: Maybe<Scalars['Int']['output']>;
  phoneValid?: Maybe<Scalars['Boolean']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  photoFilename?: Maybe<Scalars['String']['output']>;
  photoFilenameBig?: Maybe<Scalars['String']['output']>;
  photoFilenameSmall?: Maybe<Scalars['String']['output']>;
  premium?: Maybe<Scalars['Boolean']['output']>;
  profileScore?: Maybe<Array<Maybe<UserProfileScore>>>;
  profileScoreSum?: Maybe<Scalars['Int']['output']>;
  profileSettings?: Maybe<UserProfileSettings>;
  projects?: Maybe<Array<Maybe<Project>>>;
  propertyWorth: UserPropertyWorth;
  pushSubscriptions?: Maybe<Array<Maybe<PushSubscription>>>;
  rank: Scalars['Int']['output'];
  removed?: Maybe<Scalars['Boolean']['output']>;
  showAd?: Maybe<Scalars['Boolean']['output']>;
  smoker?: Maybe<Scalars['Boolean']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  timeAccountExpiration?: Maybe<Scalars['DateTime']['output']>;
  timeAccountUpgraded?: Maybe<Scalars['DateTime']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timePasswordUpdated?: Maybe<Scalars['DateTime']['output']>;
  tipInfo?: Maybe<UserTipInfo>;
  type?: Maybe<UserGroupType>;
  unreadMessagesCount?: Maybe<Scalars['Int']['output']>;
  unreadWatchdogAdvertsCount?: Maybe<Scalars['Int']['output']>;
  userGroup?: Maybe<UserGroup>;
  voucherRewardReceived?: Maybe<Scalars['Boolean']['output']>;
  walletBalance?: Maybe<Scalars['Int']['output']>;
  walletCurrency?: Maybe<Currency>;
  watchdogCount?: Maybe<Scalars['String']['output']>;
  /** @deprecated To be removed */
  watchdogFrequency?: Maybe<WatchdogFrequency>;
  workplace?: Maybe<Scalars['String']['output']>;
  yearBorn?: Maybe<Scalars['Int']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};


export type UserLastAdvertVisitsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  unique?: InputMaybe<Scalars['Boolean']['input']>;
};


export type UserPhotoArgs = {
  filter: ImageFilter;
};

export type UserAresInfo = {
  __typename?: 'UserAresInfo';
  city?: Maybe<Scalars['String']['output']>;
  dic?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  ic?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Boolean']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  streetNumber?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  active?: Maybe<Scalars['Boolean']['output']>;
  address?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  dic?: Maybe<Scalars['String']['output']>;
  ic?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  image?: Maybe<Scalars['String']['output']>;
  isPSN?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameShow?: Maybe<Scalars['String']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeLastUpdate?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<UserGroupKeyType>;
  uri?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  verified?: Maybe<Scalars['Boolean']['output']>;
};

export enum UserGroupKeyType {
  /** Bytové družstvo */
  BytoveDruzstvo = 'BYTOVE_DRUZSTVO',
  /** Církev */
  Cirkev = 'CIRKEV',
  /** Developer */
  Developer = 'DEVELOPER',
  /** Nezisková organizace */
  NeziskovaOrganizace = 'NEZISKOVA_ORGANIZACE',
  /** Obce a města */
  ObceAMesta = 'OBCE_A_MESTA',
  /** Ostatní */
  Ostatni = 'OSTATNI',
  /** Společenství vlastníků jednotek */
  SpolecenstviClastnikuJednotek = 'SPOLECENSTVI_CLASTNIKU_JEDNOTEK',
  /** Správa nemovitostí */
  SpravaNemovitosti = 'SPRAVA_NEMOVITOSTI',
  /** Stavební společnost */
  StavebniSpolecnost = 'STAVEBNI_SPOLECNOST',
  /** Nevyplněno */
  Undefined = 'UNDEFINED'
}

export enum UserGroupType {
  /** Legal */
  Legal = 'LEGAL',
  /** Private */
  Private = 'PRIVATE'
}

export enum UserInterest {
  /** Pronájem */
  Rent = 'RENT',
  /** Prodej */
  Sale = 'SALE'
}

export enum UserJsonType {
  AdvertInsert = 'ADVERT_INSERT',
  PriceEstimate = 'PRICE_ESTIMATE'
}

export type UserProfileScore = {
  __typename?: 'UserProfileScore';
  id?: Maybe<Scalars['ID']['output']>;
  percent?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ProfileScoreType>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type UserProfileSettings = {
  __typename?: 'UserProfileSettings';
  rentPlatformDashboardOrder?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  rentPlatformReminderText?: Maybe<Scalars['String']['output']>;
};

export type UserPropertyWorth = {
  __typename?: 'UserPropertyWorth';
  rent: Scalars['Int']['output'];
  sale: Scalars['Int']['output'];
};

export enum UserReportType {
  /** Realitní makléř */
  Broker = 'BROKER',
  /** Špatně umístěný inzerát */
  Locality = 'LOCALITY',
  /** Jiná chyba */
  Other = 'OTHER'
}

export type UserTipInfo = {
  __typename?: 'UserTipInfo';
  hasActiveAdvertComfort?: Maybe<Scalars['Boolean']['output']>;
  hasActiveAdvertComfortWithReactions?: Maybe<Scalars['Boolean']['output']>;
  hasActiveAdvertRent?: Maybe<Scalars['Boolean']['output']>;
  hasActiveAdvertSale?: Maybe<Scalars['Boolean']['output']>;
  hasAdvert?: Maybe<Scalars['Boolean']['output']>;
  hasAdvertService?: Maybe<Scalars['Boolean']['output']>;
  hasAnyAdvertComfort?: Maybe<Scalars['Boolean']['output']>;
  hasPragueRentAdvert?: Maybe<Scalars['Boolean']['output']>;
  hasPragueSaleAdvert?: Maybe<Scalars['Boolean']['output']>;
  hasRentPlatformAdvert?: Maybe<Scalars['Boolean']['output']>;
  hasReservedAdvert?: Maybe<Scalars['Boolean']['output']>;
  mortgageRate?: Maybe<Scalars['Float']['output']>;
  respondedToRent?: Maybe<Scalars['Boolean']['output']>;
  respondedToSale?: Maybe<Scalars['Boolean']['output']>;
  shouldBuyRentContract?: Maybe<Scalars['Boolean']['output']>;
  shouldBuySaleContract?: Maybe<Scalars['Boolean']['output']>;
  shouldShowTvujSpravce?: Maybe<Scalars['Boolean']['output']>;
  shouldShowTvujSpravce21days?: Maybe<Scalars['Boolean']['output']>;
  shouldShowTvujSpravceInactive?: Maybe<Scalars['Boolean']['output']>;
  shouldShowTvujSpravceWithReactions?: Maybe<Scalars['Boolean']['output']>;
};

export enum WalletWithdrawAction {
  /** Zrušení žádosti o vrácení */
  Cancel = 'CANCEL',
  /** Žádost o vrácení */
  Withdraw = 'WITHDRAW'
}

export type Watchdog = {
  __typename?: 'Watchdog';
  adverts?: Maybe<AdvertList>;
  criteria?: Maybe<WatchdogCriteria>;
  emailFrequency?: Maybe<WatchdogFrequency>;
  geometryExtendedGeoJson?: Maybe<Scalars['String']['output']>;
  geometryGeoJson?: Maybe<Scalars['String']['output']>;
  hasGeometry?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  timeChecked?: Maybe<Scalars['DateTime']['output']>;
  timeCreated?: Maybe<Scalars['DateTime']['output']>;
  timeUpdated?: Maybe<Scalars['DateTime']['output']>;
  unreadCount?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
};


export type WatchdogAdvertsArgs = {
  interval?: InputMaybe<DateIntervalEnum>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ResultOrder>;
};

/** Watchdog criteria interface used for advert search */
export type WatchdogCriteria = {
  /** Type of advert */
  advertType?: Maybe<AdvertType>;
  /** Identifies if balcony is required */
  balcony?: Maybe<Scalars['Boolean']['output']>;
  /** Max balcony surface of advert */
  balconySurfaceMax?: Maybe<Scalars['Float']['output']>;
  /** Min balcony surface of advert */
  balconySurfaceMin?: Maybe<Scalars['Float']['output']>;
  barrierFree?: Maybe<Scalars['Boolean']['output']>;
  /** Identifies if cellar is required */
  cellar?: Maybe<Scalars['Boolean']['output']>;
  /** Max cellar surface of advert */
  cellarSurfaceMax?: Maybe<Scalars['Float']['output']>;
  /** Min cellar surface of advert */
  cellarSurfaceMin?: Maybe<Scalars['Float']['output']>;
  /** Array of conditions to be searched for */
  condition?: Maybe<Array<Maybe<Condition>>>;
  /** Construction type */
  construction?: Maybe<Array<Maybe<Construction>>>;
  /** Price currency */
  currency?: Maybe<Currency>;
  /** Part of description that must be contained in search */
  description?: Maybe<Scalars['String']['output']>;
  /** Array of dispositions to be searched for */
  disposition?: Maybe<Array<Maybe<Disposition>>>;
  /** State of equipment */
  equipped?: Maybe<Array<Maybe<Equipped>>>;
  /** Array of estate types used for search */
  estateType?: Maybe<Array<Maybe<EstateType>>>;
  frontGardenSurfaceMax?: Maybe<Scalars['Float']['output']>;
  frontGardenSurfaceMin?: Maybe<Scalars['Float']['output']>;
  /** Identifies if garage is required */
  garage?: Maybe<Scalars['Boolean']['output']>;
  includeImports?: Maybe<Scalars['Boolean']['output']>;
  includeShortTerm?: Maybe<Scalars['Boolean']['output']>;
  isSelectedOnMap?: Maybe<Scalars['Boolean']['output']>;
  /** Land type */
  landType?: Maybe<Array<Maybe<LandType>>>;
  /** Identifies if lift is required */
  lift?: Maybe<Scalars['Boolean']['output']>;
  /** Identifies if loggia is required */
  loggia?: Maybe<Scalars['Boolean']['output']>;
  /** Max loggia surface of advert */
  loggiaSurfaceMax?: Maybe<Scalars['Float']['output']>;
  /** Min loggia surface of advert */
  loggiaSurfaceMin?: Maybe<Scalars['Float']['output']>;
  lowEnergy?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Identifies if newBuilding is required
   * @deprecated To be removed
   */
  newBuilding?: Maybe<Scalars['Boolean']['output']>;
  /** Offer type */
  offerType?: Maybe<OfferType>;
  /** Type of ownership */
  ownership?: Maybe<Array<Maybe<Ownership>>>;
  /** Identifies if parking is required */
  parking?: Maybe<Scalars['Boolean']['output']>;
  petFriendly?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Single polygon used in geometry search
   * @deprecated Unified field processing
   */
  polygon?: Maybe<Scalars['String']['output']>;
  /** Polygon buffer size [m] */
  polygonBuffer?: Maybe<Scalars['Int']['output']>;
  /** Formatted polygon for mobile app */
  polygonFormatted?: Maybe<GpsPolygon>;
  /** Multipolygon used in geometry search (used if region is not set) */
  polygons?: Maybe<Scalars['GPSMultipolygon']['output']>;
  /** Max price of advert */
  priceMax?: Maybe<Scalars['Int']['output']>;
  /** Min price of advert */
  priceMin?: Maybe<Scalars['Int']['output']>;
  /** Region used for search */
  region?: Maybe<Region>;
  /** Filters by multiple region OSM IDs */
  regionOsmIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  roommate?: Maybe<Scalars['Boolean']['output']>;
  /** Search price with charges */
  searchPriceWithCharges?: Maybe<Scalars['Boolean']['output']>;
  selectedAddress?: Maybe<Scalars['String']['output']>;
  /**
   * Square object to be used in geometry search
   * @deprecated Unified field processing
   */
  square?: Maybe<Scalars['String']['output']>;
  /** Max land surface of advert */
  surfaceLandMax?: Maybe<Scalars['Int']['output']>;
  /** Min land surface of advert */
  surfaceLandMin?: Maybe<Scalars['Int']['output']>;
  /** Max surface of advert */
  surfaceMax?: Maybe<Scalars['Int']['output']>;
  /** Min surface of advert */
  surfaceMin?: Maybe<Scalars['Int']['output']>;
  /** Identifies if terrace is required */
  terrace?: Maybe<Scalars['Boolean']['output']>;
  /** Max terrace surface of advert */
  terraceSurfaceMax?: Maybe<Scalars['Float']['output']>;
  /** Min terrace surface of advert */
  terraceSurfaceMin?: Maybe<Scalars['Float']['output']>;
};

export type WatchdogCriteriaOffer = WatchdogCriteria & {
  __typename?: 'WatchdogCriteriaOffer';
  advertType?: Maybe<AdvertType>;
  balcony?: Maybe<Scalars['Boolean']['output']>;
  balconySurfaceMax?: Maybe<Scalars['Float']['output']>;
  balconySurfaceMin?: Maybe<Scalars['Float']['output']>;
  barrierFree?: Maybe<Scalars['Boolean']['output']>;
  cellar?: Maybe<Scalars['Boolean']['output']>;
  cellarSurfaceMax?: Maybe<Scalars['Float']['output']>;
  cellarSurfaceMin?: Maybe<Scalars['Float']['output']>;
  condition?: Maybe<Array<Maybe<Condition>>>;
  construction?: Maybe<Array<Maybe<Construction>>>;
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']['output']>;
  disposition?: Maybe<Array<Maybe<Disposition>>>;
  equipped?: Maybe<Array<Maybe<Equipped>>>;
  estateType?: Maybe<Array<Maybe<EstateType>>>;
  frontGardenSurfaceMax?: Maybe<Scalars['Float']['output']>;
  frontGardenSurfaceMin?: Maybe<Scalars['Float']['output']>;
  garage?: Maybe<Scalars['Boolean']['output']>;
  includeImports?: Maybe<Scalars['Boolean']['output']>;
  includeShortTerm?: Maybe<Scalars['Boolean']['output']>;
  isSelectedOnMap?: Maybe<Scalars['Boolean']['output']>;
  landType?: Maybe<Array<Maybe<LandType>>>;
  lift?: Maybe<Scalars['Boolean']['output']>;
  loggia?: Maybe<Scalars['Boolean']['output']>;
  loggiaSurfaceMax?: Maybe<Scalars['Float']['output']>;
  loggiaSurfaceMin?: Maybe<Scalars['Float']['output']>;
  lowEnergy?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated To be removed */
  newBuilding?: Maybe<Scalars['Boolean']['output']>;
  offerType?: Maybe<OfferType>;
  ownership?: Maybe<Array<Maybe<Ownership>>>;
  parking?: Maybe<Scalars['Boolean']['output']>;
  petFriendly?: Maybe<Scalars['Boolean']['output']>;
  polygon?: Maybe<Scalars['String']['output']>;
  polygonBuffer?: Maybe<Scalars['Int']['output']>;
  polygonFormatted?: Maybe<GpsPolygon>;
  polygons?: Maybe<Scalars['GPSMultipolygon']['output']>;
  position?: Maybe<Array<Maybe<Position>>>;
  priceMax?: Maybe<Scalars['Int']['output']>;
  priceMin?: Maybe<Scalars['Int']['output']>;
  region?: Maybe<Region>;
  regionOsmIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
  roommate?: Maybe<Scalars['Boolean']['output']>;
  searchPriceWithCharges?: Maybe<Scalars['Boolean']['output']>;
  selectedAddress?: Maybe<Scalars['String']['output']>;
  square?: Maybe<Scalars['String']['output']>;
  surfaceLandMax?: Maybe<Scalars['Int']['output']>;
  surfaceLandMin?: Maybe<Scalars['Int']['output']>;
  surfaceMax?: Maybe<Scalars['Int']['output']>;
  surfaceMin?: Maybe<Scalars['Int']['output']>;
  terrace?: Maybe<Scalars['Boolean']['output']>;
  terraceSurfaceMax?: Maybe<Scalars['Float']['output']>;
  terraceSurfaceMin?: Maybe<Scalars['Float']['output']>;
};

export enum WatchdogFrequency {
  /** Denně */
  Daily = 'DAILY',
  /** Ihned (jen premium uživatel) */
  Hourly = 'HOURLY',
  /** Neposílat */
  None = 'NONE',
  /** Týdně */
  Weekly = 'WEEKLY'
}

export type WatchdogList = {
  __typename?: 'WatchdogList';
  list?: Maybe<Array<Maybe<Watchdog>>>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum Water {
  /** veřejná síť */
  Public = 'PUBLIC',
  /** Nevyplněno */
  Undefined = 'UNDEFINED',
  /** studna */
  Well = 'WELL'
}
