import React, {FC} from 'react';
import {Icon, IconProps} from '.';

export const IconNewspaper: FC<React.PropsWithChildren<IconProps>> = ({...props}) => {
    return (
        <Icon {...props} id="newspaper">
            <path
                d="M12 10.068h2.898"
                stroke="#323232"
                stroke-width="0.6206896551724138"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
            />
            <path
                d="M12 7.171h2.898"
                stroke="#323232"
                stroke-width="0.6206896551724138"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
            />
            <path
                x="14.9941"
                y="17.3284"
                width="7.00292"
                height="7.00292"
                rx="0.5"
                stroke="#323232"
                stroke-width="0.6206896551724138"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6.411 7.17H8.895A0.207 0.207 0 0 1 9.102 7.377V9.861A0.207 0.207 0 0 1 8.895 10.068H6.411A0.207 0.207 0 0 1 6.204 9.861V7.377A0.207 0.207 0 0 1 6.411 7.17z"
                fill="none"
            />
            <path
                d="M6.204 13.449h8.693"
                stroke="#323232"
                stroke-width="0.6206896551724138"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
            />
            <path
                d="M14.898 16.83H6.204"
                stroke="#323232"
                stroke-width="0.6206896551724138"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
            />
            <path
                d="M17.796 8.136h0.966a1.932 1.932 0 0 1 1.932 1.932v8.694a1.932 1.932 0 0 1 -1.932 1.932H5.239A1.932 1.932 0 0 1 3.306 18.761V5.239A1.932 1.932 0 0 1 5.239 3.306h10.625a1.932 1.932 0 0 1 1.932 1.932v15.455"
                stroke="#323232"
                stroke-width="0.6206896551724138"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
            />
        </Icon>
    );
};
